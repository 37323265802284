import axios from "axios";
import baseURL from "../config/api";

export const post_Payment = async (data: any) => {
    const response = await axios({
        url: `${baseURL}payment`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    });
    return response.data;
}