import React from "react";

interface CvvValidateProps {
  value: string;
  setValue: (value: string) => void;
  className?: any;
}

const CvvValidate = ({ value, setValue, className }: CvvValidateProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
    const isValid = /^\d{3,4}$/gm.test(value);
    e.target.setCustomValidity(
      isValid ? "" : "Please enetr a valid cvv number"
    );
  };

  return (
    <>
    <label className="text-sm mb-1">CVV</label>
    <input
      name="cvv"
      type="text"
      maxLength={4}
      className="bg-transparent w-full text-sm rounded-md border border-secondary px-2 py-3 outline-none placeholder:text-[#bbb]"
      placeholder="CVV"
      required
      value={value}
      onChange={handleChange}
    />
    </>
  );
};

export default CvvValidate;