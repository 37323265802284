import axios from "axios";
import baseURL from "../config/api";

export const post_Subscription = async (data: any) => {
    const response = await axios({
        url: `${baseURL}subscription`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    });
    return response.data;
}

export const getSubscriptionByGroupId = (groupId: string) => {
    return getFetch(`subscription/GetLatestSubscriptionByGroupId,${groupId}`)
}

const getFetch = async (url: string, params = {}) => {
    const response = await axios({
        url: baseURL + url,
        method: "GET",
        params: params
    });
    return response.data;
}