import { Link } from "react-router-dom"

const TryMyCdir = () => {

    return (
        <section className="py-7 px-2 md:mx-10 md:px-12 bg-custom-blue -mt-10">
            <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-9/12 p-4 md:p-1 justify-center items-center">
                    <h1 className="text-white text-2xl md:text-3xl font-normal text-center md:text-left">
                        Try <span className="font-bold">My Church Directory </span>
                        for 30 days FREE.
                    </h1>
                </div>

                <div className="w-full md:w-1/2 flex justify-center items-center">
                    <Link
                        to={"/free-trial"}
                        className="bg-white hover:bg-slate-700 hover:text-white text-custom-blue font-semibold py-2 px-4 md:py-2 md:px-5 rounded uppercase"
                        onClick={() => "/free-trial"}
                    >
                        Start Your FREE Trial
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default TryMyCdir