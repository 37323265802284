import { BiSolidLockAlt } from "react-icons/bi";
import { HiOutlineDesktopComputer } from 'react-icons/hi';
import { HiMiniDevicePhoneMobile } from 'react-icons/hi2';
import { BsFiletypePdf } from 'react-icons/bs';
import { MdPrivacyTip } from 'react-icons/md';
import { AiFillDatabase } from 'react-icons/ai';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { LiaFileDownloadSolid } from 'react-icons/lia';
import StartYourFreeTrial from "../../components/StartYourFreeTrial";


const Security = () => {
  return (
    <div>
      <section className="max-w-screen-lg mx-auto px-4 py-8">
        <img src="../images/data-security.png" alt="" className="object-cover w-full h-64 md:h-96" />
        <h1 className="text-[#035292] text-center text-2xl md:text-5xl my-8 pt-5"><strong>Church Directory Security and Privacy</strong></h1>

        <h1 className=" text-[#035292] text-2xl md:text-4xl my-8 pt-10">You're worried about security and privacy. <strong> So are we.</strong></h1>
        <p className='text-justify leading-7'>Security and privacy are paramount, and we're fully aligned with your concerns. Your <em>My Church Directory</em> account is protected by an email and password that only you as the administrator should know. What's more, the password-protected segment containing directory data is beyond the reach of search engines. We uphold an unwavering commitment to preserving your privacy and that of your cherished church members.</p>
      </section>

      <section className="py-14 bg-[#f8f8f8]">
        <div className="max-w-screen-lg mx-auto my-8">
          <div className="md:flex md:items-center">

            <div className="text-center md:w-1/4">
              <BiSolidLockAlt
                className="text-blue-500 text-7xl md:text-7xl md:w-1/2 mx-auto md:mx-0" // Center on small screens
              />
              <div></div>
            </div>


            <div className="md:w-3/4 mt-4 md:mt-0">
              <h2 className="text-blue-500 text-xl mb-3"><strong>General Security</strong></h2>
              <p className="md:text-left text-justify">
                The <em>My Church Directory</em> website and databases are fortified with the latest security patches, ensuring robust protection against present and future threats. To ensure your membership directory is safe, only individuals entered in the directory have access. Moreover, should you need to remove a member from your directory, our system ensures their directory is promptly "wiped" from their devices. Your peace of mind is our constant pursuit.
              </p>
            </div>
          </div>

          <div className="md:flex md:items-center mt-8">

            <div className="text-center md:w-1/4">
              <HiOutlineDesktopComputer
                className="text-blue-500 text-7xl md:text-7xl md:w-1/2 mx-auto md:mx-0"
              />
              <div></div>
            </div>


            <div className="md:w-3/4 mt-4 md:mt-0">
              <h2 className="text-blue-500 text-xl mb-3"><strong>Online Member Directory Security</strong></h2>
              <p className="md:text-left text-justify">
                Only active members who are vetted and entered in the directory by an administrator can log in to the members' website once their email has been verified. Once a member is removed from a directory, they no longer have access to the directory. You can also make members inactive if they do not want their information to be shown on the online directory or app. Available Two-factor authentication provides additional security measures. We always recommend enabling this feature for a more robust level of security and access verification.
              </p>
            </div>
          </div>

          <div className="md:flex md:items-center mt-8">

            <div className="text-center md:w-1/4">
              <HiMiniDevicePhoneMobile
                className="text-blue-500 text-7xl md:text-7xl md:w-1/2 mx-auto md:mx-0"
              />
              <div></div>
            </div>
            <div className="md:w-3/4 mt-4 md:mt-0">
              <h2 className="text-blue-500 text-xl mb-3"><strong>Mobile App Security</strong></h2>
              <p className="md:text-left text-justify">
                Only active members an administrator has entered into a directory can gain access once their email address has been verified. We recommend any and all access requests by members be vetted or verified by an administrator. Access can be revoked by inactivating any member or removing their email address. If you prefer your directory is not available via the app, simply disable the feature. Available Two-factor authentication adds an extra layer of security for your members. We always recommend enabling this feature for a greater level of security and access to your church directory.
              </p>
            </div>
          </div>

          <div className="md:flex md:items-center mt-8">

            <div className="text-center md:w-1/4">
              <BsFiletypePdf
                className="text-blue-500 text-7xl md:text-7xl md:w-1/2 mx-auto md:mx-0"
              />
              <div></div>
            </div>
            <div className="md:w-3/4 mt-4 md:mt-0">
              <h2 className="text-blue-500 text-xl mb-3"><strong>PDF Security</strong></h2>
              <p className="md:text-left text-justify">One output of My Church Directory is an optional PDF file. We recommend that this PDF always have a strong, unique password. Only individuals who have the PDF password can access the directory PDF. Please do not post your PDF password in any public location or with the PDF. <br />

                Sharing your PDF responsibly is crucial in today's digital age. With the personal information available within a directory, an unprotected PDF can be used by cybercriminals to access the personal data of your members with ill intent. We only post the PDF files to the account administrator. The account administrator has control over whether it is made public and available for search. For instance, a directory PDF can be made public by posting a link to it on your church’s website (but always with recommended password protection). You can remove the ability to share or stop sharing your PDF at any time.
              </p>
            </div>
          </div>

          <div className="md:flex md:items-center mt-8">

            <div className="text-center md:w-1/4">
              <MdPrivacyTip
                className="text-blue-500 text-7xl md:text-7xl md:w-1/2 mx-auto md:mx-0"
              />
              <div></div>
            </div>
            <div className="md:w-3/4 mt-4 md:mt-0">
              <h2 className="text-blue-500 text-xl mb-3"><strong>Member Information Security</strong></h2>
              <p className="md:text-left text-justify">Even though your data is stored with us, we at My Church Directory do not access your members' personal data except for specific troubleshooting purposes within the directory. All data provided by members is held securely and we do not use member information such as phone numbers or emails for marketing purposes either internally or by selling the information to other organizations.</p>
            </div>
          </div>

          <div className="md:flex md:items-center mt-8">

            <div className="text-center md:w-1/4">
              <AiFillDatabase
                className="text-blue-500 text-7xl md:text-7xl md:w-1/2 mx-auto md:mx-0"
              />
              <div></div>
            </div>
            <div className="md:w-3/4 mt-4 md:mt-0">
              <h2 className="text-blue-500 text-xl mb-3"><strong>Data Storage and Security</strong></h2>
              <p className="md:text-left text-justify">Directory data is stored using a private cloud environment in a private data center — meaning it does not operate in a "shared environment" with others. We use industry best practices for securing and backing up our servers and data, including proactive 24-7 monitoring of our services for possible intrusions.</p>
            </div>
          </div>

          <div className="md:flex md:items-center mt-8">
            <div className="text-center md:w-1/4">
              <AiOutlineSafetyCertificate
                className="text-blue-500 text-7xl md:text-7xl md:w-1/2 mx-auto md:mx-0"
              />
              <div></div>
            </div>
            <div className="md:w-3/4 mt-4 md:mt-0">
              <h2 className="text-blue-500 text-xl mb-3"><strong>
                Optional Two-Factor Authentication</strong></h2>
              <p className="md:text-left text-justify">With <em>My Church Directory</em>, you have the option to enable Two-Factor Authentication (2FA). 2FA is a security process that requires users to provide two different types of identification — or authentication factors — in order to access a system, account, or application. <br />

                Two-factor authentication helps to ensure that only authorized users can access sensitive information or perform certain actions. When enabled, your members use the mobile app or member website to register the device(s) they will use to access the directory. They will be asked to register their devices via email and will be prompted with this authentication step any time they access the directory on a new device, such as a new phone or a new location on a computer. <br />

                By enabling two-factor authentication, you will be turning this on for every active member in your directory who wishes to create and use a login for viewing the directory in the mobile app or online member website.</p>
            </div>
          </div>
        </div>

      </section>

      <section className="py-8 relative">
        <div className="bg-opacity-10 bg-white absolute top-0 left-0 w-full h-full"></div>


        <div
          className="bg-fixed bg-center bg-cover h-64 md:h-96"
          style={{ backgroundImage: 'url("../images/security-page-bg.png")' }}
        >

          <div className="flex items-center justify-center h-full">

            <div className="max-w-screen-sm mx-auto bg-white bg-opacity-70 p-4 md:p-8 text-center flex flex-col items-center">
              <LiaFileDownloadSolid
                className="text-blue-500 text-7xl md:text-7xl mb-4"
              />
              <p >
                Read our security help document and share this information with your church. Please let us know if you have any other concerns. We want you to feel completely comfortable and at ease using My Church Directory to create, generate, and store your church's directory.
              </p>
            </div>
          </div>
        </div>
      </section>

      <StartYourFreeTrial />

    </div>
  )
}

export default Security