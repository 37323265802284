import React, { useEffect, useRef, useState } from 'react'
import { FileDrop } from '../../components/FileDrop';
import { onChangePhoneNumber } from '../../utils/phoneNumberRegex';
import Cookies from 'js-cookie';
import EmailAlreadyExistChecking from '../../components/EmailAlreadyExistChecking';
import { getFamilyMemberEmailChecking } from '../../services/EmailChecking';
import PasswordValidation from '../../components/PasswordValidation';
import UsernameValidatedComponent from '../../components/UsernameValidatedComponent';
import { getFamilyMemberUsername } from '../../services/UsernameChecking';
import Select from "react-select";
import baseURL from '../../config/api';

enum CurrentPage {
  ADD_FAMILY,
  ADD,
  EDIT
};

type Props = {
  setCurrentPage: (value: CurrentPage) => void;
  familyMemberValue: any;
  familyMember: any[];
  setFamilyMember: (value: any[]) => void;
  selectedRowIndex: number;
  groupId: number;
}

const EditAdminFamilyMember = ({ setCurrentPage, familyMemberValue, familyMember, setFamilyMember, selectedRowIndex, groupId}: Props) => {

  const birthdayRef = useRef<HTMLInputElement>(null);
  const [subGroupData, setSubGroupData] = useState<any[]>([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const role = Cookies.get("role-my-cdir") || "";
  const [userData, setUserData] = useState<any>({
    id: 0,
    groupId: 0,
    subGroupId: 0,
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    emailAddress: "",
    phoneNumber: "",
    birthday: "",
    filePath: "",
    memberId: 0,
    relationToMember: "",
    imageSize: 0,
    createdAt: "",
    createdBy: "",
    uploadedAt: "",
    uploadedBy: ""
  });
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const [emailTouched, setEmailTouched] = useState<boolean>(false);
  const [usernameTouched, setUsernameTouched] = useState<boolean>(false);
  const [passwordTouched, setPasswordTouched] = useState<boolean>(false);
  const [subGroups, setSubGroups] = useState<any>(null);
  const [subGroupDataIsLoaded, setSubGroupDataIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (role?.toString()?.toLowerCase().replaceAll(" ", "") === "admin") {
      setIsSuperAdmin(false);
    } else if (role?.toString()?.toLowerCase().replaceAll(" ", "") === "superadmin") {
      setIsSuperAdmin(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (groupId > 0) {
      setSubGroupDataIsLoaded(false);
      fetch(`${baseURL}subGroup/GetSubGroupsByGroupId/${groupId}`)
        .then((res) => res.json())
        .then((data) => {
          setSubGroupData(data.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
              groupId: item.groupId
            }
          }));

          setSubGroupDataIsLoaded(true);
        })
        .catch(error => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId])

  const closeModal = () => {
    setCurrentPage(CurrentPage.ADD_FAMILY);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const updatedData = {
      ...userData,
      groupId: groupId > 0 ? groupId : 0,
      subGroupId: !Array.isArray(subGroups) ? "" : subGroups.map((item: any) => item.value).join(",")
    }
    if (!isProcessing) {
      setIsProcessing(true);
      if (familyMember.length > 0) {
        setFamilyMember(
          familyMember.map((item, ind) => {
            if (ind === selectedRowIndex) {
              return updatedData
            }
            else {
              return item
            }
          })
        )

      }
      else {
        setFamilyMember([updatedData]);
      }
      setCurrentPage(CurrentPage.ADD_FAMILY)
      setIsProcessing(false);

    }
  }

  useEffect(() => {
    if (familyMemberValue && subGroupDataIsLoaded) {   
          setUserData(familyMemberValue);
          const subGroupArray = familyMemberValue?.subGroupId?.split(",");
                   
          setSubGroups(
            subGroupData.filter((item: any) => subGroupArray?.some((data: string) => item.value === parseInt(data)))
              .map((item: any) => {
                return {
                  value: item.value,
                  label: item.label
                }
              }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyMemberValue, subGroupDataIsLoaded])


  return (
    <div className="w-full">
      <div className="my-2">
        <div className="py-8 px-6 relative bg-white">
          <h2 className="text-xl text-blue-500 font-bold">Edit Family Member</h2>
          <form onSubmit={handleSubmit} className="mt-8 grid sm:grid-cols-2 gap-6 text-blue-500">
            <div>
              <label className="font-semibold text-sm">First Name</label>
              <input type='text' placeholder='Enter First Name'
                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                value={userData.firstName}
                onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Last Name</label>
              <input type='text' placeholder='Enter Last Name'
                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                value={userData.lastName}
                onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                required
              />
            </div>
            <UsernameValidatedComponent
              userName={userData.userName}
              setUserName={(value) => setUserData({ ...userData, userName: value })}
              getUsername={getFamilyMemberUsername}
              className={{
                parentDiv: "",
                label: "font-semibold text-sm",
                input: "w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]",
              }}
              touched={usernameTouched}
              setTouched={setUsernameTouched}
            />
            <div>
              <PasswordValidation
                password={userData.password}
                setPassword={(password) => setUserData({ ...userData, password })}
                isValid={isValidPassword}
                setIsValid={setIsValidPassword}
                className={{
                  label: "font-semibold text-sm",
                  div: "w-full text-sm relative",
                  input: "w-full rounded py-2.5 px-4 mt-2.5 border-2 text-sm text-black outline-[#007bff]"
                }}
                touched={passwordTouched}
                setTouched={setPasswordTouched}
              />
            </div>
            <EmailAlreadyExistChecking
              text={userData.emailAddress}
              setText={(value) => setUserData({ ...userData, emailAddress: value })}
              getEmailChecking={getFamilyMemberEmailChecking}
              className={{
                parentDiv: "",
                label: "font-semibold text-sm",
                input: "w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]",
              }}
              touched={emailTouched}
              setTouched={setEmailTouched}
            />
            <div>
              <label className="font-semibold text-sm">Phone Number</label>
              <input type='text' placeholder='Phone No.'
                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                pattern="[\(][0-9]{3}[\)][\s]{1}[0-9]{3}[\-]{1}[0-9]{4}"
                value={userData.phoneNumber}
                onChange={(e) => setUserData({ ...userData, phoneNumber: onChangePhoneNumber(e.target.value) })}
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Birthday</label>
              <input ref={birthdayRef} type='date'
                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                value={userData.birthday ? new Date(userData.birthday).toISOString().split('T')[0] : userData.birthday}
                onChange={(e) => setUserData({ ...userData, birthday: e.target.value })}
                onClick={() => birthdayRef?.current?.showPicker()}
                required
              />
            </div>
           
            <div>
              <label className="font-semibold text-sm">Sub Group</label>
              <Select
                isMulti
                className="basic-single mt-3"
                classNamePrefix="select"
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={false}
                name="subGroups"
                value={subGroups}
                onChange={(value) => setSubGroups(value)}
                options={subGroupData}
              />
            </div>
            {!isSuperAdmin && <div />}
            <div >
              <FileDrop
                value={userData.filePath}
                setValue={(value) => setUserData({ ...userData, filePath: value })}
              />
            </div>
            <div />

            <div className="flex max-sm:flex-col gap-4 !mt-4">
              <button
                type="button"
                className="px-6 py-2 min-w-[150px] rounded text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-6 py-2 min-w-[150px] rounded text-white text-sm font-semibold border-none outline-none bg-blue-500 hover:bg-blue-700"
              >
                {isProcessing ?
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" fill="#fff" className="mr-2 inline animate-spin"
                      viewBox="0 0 26.349 26.35">
                      <circle cx="13.792" cy="3.082" r="3.082" data-original="#000000" />
                      <circle cx="13.792" cy="24.501" r="1.849" data-original="#000000" />
                      <circle cx="6.219" cy="6.218" r="2.774" data-original="#000000" />
                      <circle cx="21.365" cy="21.363" r="1.541" data-original="#000000" />
                      <circle cx="3.082" cy="13.792" r="2.465" data-original="#000000" />
                      <circle cx="24.501" cy="13.791" r="1.232" data-original="#000000" />
                      <path
                        d="M4.694 19.84a2.155 2.155 0 0 0 0 3.05 2.155 2.155 0 0 0 3.05 0 2.155 2.155 0 0 0 0-3.05 2.146 2.146 0 0 0-3.05 0z"
                        data-original="#000000" />
                      <circle cx="21.364" cy="6.218" r=".924" data-original="#000000" />
                    </svg>
                    Processing...
                  </>
                  :
                  "Save"
                }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditAdminFamilyMember