import { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import YourFreeTrialOffer from '../components/YourFreeTrialOffer';

type MembershipData = {
  Standard: {
    monthly: number;
    yearly: number;
  };
  Premium: {
    monthly: number;
    yearly: number;
  };
};

const FreeTrial = () => {
  const [showBillingOptions, setShowBillingOptions] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState('');
  // const [selectedBillingPlan, setSelectedBillingPlan] = useState('');

  const membershipData: MembershipData = {
    Standard: {
      monthly: 9.99,
      yearly: 99.80,
    },
    Premium: {
      monthly: 14.99,
      yearly: 149.99,
    },
  };

  const handleMembershipClick = (membership: keyof MembershipData, billingPlan: string) => {
    setShowBillingOptions(true);
    setSelectedMembership(membership);
    // setSelectedBillingPlan(billingPlan);
  };

  const handleBackButtonClick = () => {
    setShowBillingOptions(false);
    setSelectedMembership('');
    // setSelectedBillingPlan('');
  };

  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col sm:flex-row justify-center">
      <div className="w-4/6 px-20 py-10">
        <h1 className="text-[#2B3998] font-semibold text-3xl mb-6 flex items-center">
          <span>
            <button onClick={handleBackButtonClick} className="bg-[#2B3998] text-white p-1.5 px-3 text-lg mr-2">
              <FaArrowRight />
            </button>{selectedMembership === '' ? 'Choose your Membership' : `${selectedMembership} Membership`}
          </span>
          {selectedMembership && (
            <button onClick={handleBackButtonClick} className="bg-[#2B3998] text-white p-2 text-sm ml-auto">
              <FaArrowLeft />
            </button>
          )}
        </h1>

        {!showBillingOptions ? (
          <div className="flex flex-col sm:flex-row space-x-4 sm:space-x-4">
            <div className="w-full sm:w-1/2 border border-grey-200 text-center">
              <h2 className="text-xl mb-4 p-4 bg-gray-200">Standard Membership</h2>
              <div className='px-5'>
                <ul className="list-disc ml-4 text-left mb-2">
                  <li className="mb-3">
                    Simple creation and management of member directories.
                  </li>
                  <li className="mb-3">
                    Directory viewing via dedicated mobile apps.
                  </li>
                  <li className="mb-3">
                    Secure access limited to authorized members.
                  </li>
                </ul>
                <button onClick={() => handleMembershipClick('Standard', 'monthly')} className="bg-[#2B3998] text-white px-4 py-2 mb-5 rounded">
                  Choose Standard
                </button>
              </div>
            </div>

            <div className="w-full sm:w-1/2 border border-grey-200 text-center">
              <h2 className="text-xl mb-4 p-4 bg-gray-200">Premium Membership</h2>
              <div className='px-5'>
                <ul className="list-disc ml-4 text-left mb-2">
                  <li className="mb-3">
                    Simple creation and management of member directories.
                  </li>
                  <li className="mb-3">
                    Directory viewing via dedicated mobile apps.
                  </li>
                  <li className="mb-3">
                    Secure access limited to authorized members.
                  </li>
                </ul>
                <button onClick={() => handleMembershipClick('Premium', 'monthly')} className="bg-[#2B3998] text-white text-center px-4 py-2 rounded">
                  Choose Premium
                </button>
              </div>
            </div>
          </div>

        ) : (
          <div className="mb-4">
            <h1 className="text-[#2B3998] font-semibold text-3xl pt-10 mb-6">
              <button onClick={handleBackButtonClick} className="bg-[#2B3998] text-white p-2 px-3 text-xl mr-2">
                <FaArrowRight />
              </button>
              Choose Your Billing Plan
            </h1>
            <div className="flex space-x-4">
              <div className="w-3/6 border border-grey-200 text-center">
                <h2 className="text-xl mb-4 p-4 bg-gray-200">Monthly Billing</h2>
                <p className='px-4 mb-4 text-left'>My Church Directory Online Free 30-day Trial Offer!
                  <br /><br />
                  Your membership will then continue for ${membershipData[selectedMembership as keyof MembershipData]?.monthly} a month, if you choose to continue.</p>
                <button onClick={() => navigate("/checkout")} className="bg-[#2B3998] text-white px-4 py-2 mb-5 rounded">
                  Choose Monthly Billing
                </button>
              </div>

              <div className="w-3/6 border border-grey-200 text-center">
                <h2 className="text-xl mb-4 p-4 bg-gray-200">Yearly Billing</h2>
                <p className='px-4 mb-4 text-left'>My Church Directory Online Free 30-day Trial Offer!
                  <br /><br />
                  Your membership will then continue for ${membershipData[selectedMembership as keyof MembershipData]?.yearly} a year, if you choose to continue.</p>
                <button onClick={() => navigate("/checkout")} className="bg-[#2B3998] text-white px-4 py-2 rounded">
                  Choose Yearly Billing
                </button>
              </div>
            </div>
          </div>
        )}

        {/* {showBillingOptions && selectedMembership && selectedBillingPlan && showCheckout && (
            <Checkout
              price={((membershipData as any)[selectedMembership as keyof MembershipData] as any)[selectedBillingPlan]}
            />
        )}  */}
      </div>

      <YourFreeTrialOffer />
    </div>
  );
};

export default FreeTrial;