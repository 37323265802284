import React from 'react';
import { DragEvent, useState } from 'react';
import { upload_CommonFile } from '../services/commonFileUpload';
import baseURL from '../config/api';

type Porps = {
    value: string;
    setValue: (image: string) => void;
}

export function FileDrop({ value, setValue }: Porps) {
    const [isOver, setIsOver] = useState(false);
    const [pleaseWait, setPleaseWait] = useState<boolean>(false);

    const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files as FileList;

        if (selectedFiles.length !== 0) {
            saveImageFile(selectedFiles[0])
        }
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsOver(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsOver(false);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        const droppedFiles = Array.from(event.dataTransfer.files);
        if (isOver) {
            saveImageFile(droppedFiles[0]);
        }
    };

    const saveImageFile = (imageFile: File) => {
        const formData = new FormData();
        formData.append("ImageFile", imageFile);
        setPleaseWait(true);

        upload_CommonFile(formData)
            .then(res => {
                setIsOver(false);
                setPleaseWait(false);
                setValue(res);
            })
            .catch(error => {
                console.error(error)
            })
    }

    return (
        <>
            {value ? (
                <div>
                    <span className="font-semibold text-sm flex flex-1 justify-between items-center">Image
                        <span className="text-red-600 hover:text-red-700 active:text-red-600 cursor-pointer"
                            onClick={() => setValue("")}>Delete</span>
                    </span>
                    <img
                        src={`${baseURL}/commonFile/Get?name=${value}`}
                        alt="preview"
                        className="w-full rounded  h-60 border-2 mt-2 text-sm text-black outline-[#007bff]" />
                </div>
            )
                : (
                    <div onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        className="w-full h-fit relative"
                        style={{
                            backgroundColor: isOver ? 'lightgray' : 'white',
                        }}
                    >
                        <label
                            htmlFor="image-choose"
                            className="
                    bg-white text-black text-base rounded h-52 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 border-dashed
                    ">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 mb-2 fill-black" viewBox="0 0 32 32">
                                <path
                                    d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                                    data-original="#000000" />
                                <path
                                    d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                                    data-original="#000000" />
                            </svg>
                            Upload file
                            <input
                                type="file"
                                accept="image/png, image/jpeg"
                                name="image-choose"
                                id='image-choose'
                                className="hidden"
                                onChangeCapture={onFileChangeCapture} />
                            <p className="text-xs text-gray-400 mt-2">PNG, JPG and JPEG are Allowed.</p>
                        </label>

                        {pleaseWait &&
                            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-10 animate-spin fill-blue-500 block mx-auto"
                                    viewBox="0 0 24 24">
                                    <path
                                        d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"
                                        data-original="#000000" />
                                </svg>
                            </div>
                        }
                    </div>
                )}
        </>
    );
}
