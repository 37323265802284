import { Outlet } from "react-router-dom";

const Member = () => {

  return (
    <div className="flex flex-col w-full">
      <section className="flex flex-row justify-center w-full min-h-[calc(100vh-32px)] py-8 md:py-10 px-4 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <Outlet />
      </section>
    </div>
  )
}

export default Member;