import axios from "axios";
import baseURL from "../config/api";

export const delete_AchInfo = async (primaryKey: number, userId: string) => {
    const response = await axios({
        url: `${baseURL}achnfo`,
        method: "DELETE",
        params: { id: primaryKey, userId: userId }
    });
    return response.data;
}

export const getACHInfoWithGroupId = (groupId: string, page: number, size: number) => {
    return getFetch(`achnfo/GetACHInfosPagingByGroupId/${groupId},${page},${size}`)
}

const getFetch = async (url: string, params = {}) => {
    const response = await axios({
        url: baseURL + url,
        method: "GET",
        params: params
    });
    return response.data;
}