import { Link } from 'react-router-dom'
import FreeTrialTag from '../../components/FreeTrialTag';
import useActiveNavbar from '../../hook/useActiveNavbar';
import { FaBirthdayCake } from 'react-icons/fa';
import { FaBookOpen, FaCalendarDays, FaChurch, FaFileCsv, FaUsers } from 'react-icons/fa6';
import { IoDiamondOutline } from 'react-icons/io5'
import { IoIosSend } from 'react-icons/io';
import { BsFileEarmarkTextFill } from "react-icons/bs";
import { AiTwotoneMail } from "react-icons/ai";
import { ImMobile } from "react-icons/im";

const directoryContainer = [
  {
    title: "Traditional, Printed Directory",
    desc: "My Church Directory offers not just a digital version but also a printable one. With templates for 8 ½ x 11 or booklet-size formats, you can choose full color or black and white. Customize fonts and layouts, especially useful for elderly members who benefit from larger print.",
    link: "/church-directory-features"
  },
  {
    title: "Free Mobile Apps",
    desc: "Instantly synchronize your directory to church members' mobile devices. Our free mobile apps let users find members and send emails, calls, or texts, even offline. Any changes you make sync across all users. Apps are available on Apple App and Google Play stores.",
    link: "/member-mobile-app"
  },
  {
    title: "Online Members' Website",
    desc: "The online members’ website enhances connectivity even further for members without smartphones or those who spend substantial time on computers. It’s an ideal avenue for online directory access. The online members' website works just like the mobile app but is viewable in any modern browser and device at our secure membership site.",
    link: "/online-member-directory"
  },
  {
    title: "Safe and Secure",
    desc: "Access information with peace of mind because your members' data is safe and secure. We take your privacy and your congregation's privacy very seriously and never use or sell your church data.",
    link: "/security"
  },
  {
    title: "Email and Text Messaging",
    desc: "Communicate with your entire membership — or selected group(s) within your directory — without having to look up a single email address. Simply enter a subject and a message, select members, and send.",
    link: "/text-and-email-messaging"
  },
  {
    title: "Pricing Made Simple",
    desc: "Our pricing structure is simple: one low monthly price—no matter your organization size or how often you update the directory—making My Church Directory one of the most budget-friendly directory services available.",
    link: "/pricing"
  }
];

const settingsContainer = [
  {
    title: "Easy Create & Share",
    desc: "Our step-by-step guidance makes it easy to input individual members' details, including photos, addresses, emails, phone numbers, and more. You can also add personalized information such as notes or additional details, plus update family labels to suit your organization. Changes are automatically reflected on the mobile app and online members' website. Sharing your directory is effortless: Print it using our PDF option or let members access it online via our mobile app or membership website.",
    img: "../images/broadcast-email.jpg",
    link: "/demos"
  },
  {
    title: "Stress-Free Updates",
    desc: "As a church administrator, membership management of information is simplified with My Church Directory. You can easily make changes and updates to ensure directory accuracy while maintaining secure access. Members can submit changes to their information via the Instant Church Directory app or online members’ website. These submissions are sent to the directory administrator and editors for approval. Once approved, those changes are Myly synced to members' devices, guaranteeing the most current information is always available.",
    img: "../images/church-dir-features.jpg",
    link: ""
  },
  {
    title: "Bring Your Directory to Life",
    desc: "Creating your membership directory is straightforward. Recruit volunteers with digital cameras to capture \"headshots\" of families and individuals, or invite members to upload their photos for their member profiles. Our platform guides you through the process, allowing you to design a captivating cover, staff and activity pages, and even add custom sections. Distributing your finished membership directory is a breeze, with multiple options to cater to your users' preferences.",
    img: "../images/bring-dir-to-life.png",
    link: ""
  }
];

const reviewContainer = [
  {
    title: "Add Additional Editors",
    desc: "Employ volunteers to help by adding up to three editors to your account at no additional cost. This is a perfect way to engage extra help for building and maintaining your directory.",
    img: "",
    linK: ""
  },
  {
    title: "Members Can Submit Changes",
    desc: "Fellow members can submit an update for their address, photo, email address or phone number for church approval from the online directory and mobile apps!",
    img: "",
    linK: ""
  },
  {
    title: "Manage Church Groups",
    desc: "Add individuals or families to a group, and then print a member directory just for that group! You can create as many groups and sub-directories as you want. Groups are viewable on the mobile apps and the online members' website.",
    img: "",
    linK: ""
  }
  , {
    title: "Insert Custom Pages",
    desc: "Create a custom page in any desktop software, export it as a PDF and upload it into your directory PDF. It's especially helpful if you would like to sell ads to sponsor your directory. Use a custom page to add your own touch, such as a striking directory cover design.",
    img: "",
    linK: ""
  }
  , {
    title: "Keep Track of Members' Birthdays",
    desc: "Quickly add a birthday to any member's information in the directory. The birthday page will display your members' birthdays by month.",
    img: "",
    linK: ""
  },
  {
    title: "Commemorate Anniversaries",
    desc: "Include anniversary dates in a family's details so these special days won't be overlooked. Anniversaries are listed just like birthdays in the mobile app.",
    img: "",
    linK: ""
  },
  {
    title: "Export Data for Mailing Labels",
    desc: "Export your family data anytime. You can use the exported CSV file in other applications that can read a CSV file. For example, use it to create envelopes or mailing labels using Microsoft Word Mail Merge.",
    img: "",
    linK: ""
  },
  {
    title: "Available in Booklet-Sized Format",
    desc: "With just a few clicks, you can change your printed directory listing (with or without photos) from an 8.5\" x 11\" size to a booklet size of 5.5\" x 8.5\" and print as many copies as you need in black-and-white or full-color format.",
    img: "",
    linK: ""
  },
  {
    title: "Send Emails to Your Church Members",
    desc: "Send unlimited messages to your church members directly from your directory account using our quick email service. Enter a subject and message, select your recipients and send. Our email messages are ad-free. It's easy to schedule messages for immediate delivery or schedule emails to be sent at a later date. Because we send them for you, your emails are always in CAN-SPAM compliance.",
    img: "",
    linK: "text-and-email-messaging"
  },
  {
    title: "Add on Text Messaging",
    desc: "Our optional text messaging service can provide a faster way to reach members than emails. Purchase text messaging credits as you need them to deliver important or urgent messages directly to your church members. Messages show the unique Alert Name that you create so members can easily identify the text sender.",
    img: "",
    linK: "text-and-email-messaging"
  }
];

const customerFeedBack = [
  {
    name: "John",
    message: "Your program was so user friendly and the result was a very professional product.",
  },
  {
    name: "Mark",
    message: "This is the best online tool I have ever used. The tool itself is so easy to use. I have found it to be flexible, it prints nicely and the support you provide is super! The price is more than worth it!",
  },
  {
    name: "Lee",
    message: "Simply the best church directory app available! Super easy to set up and use. Adding members and information is a breeze. Registering each device is fast with personalized password emailed to member instantly. Members love that after they login the first time, they don't have to login again. Love the ability to click on phone number to make a call, click on email address which opens your email app to instantly send an email, and the ability to click on street address and GPS app opens instantly and shows you how to get there.",
  },
  {
    name: "Sandra",
    message: "My Church Directory Online is amazing! Easiest, by far, software I have ever used! It took me 10 minutes to create a sample directory of about 8 pages long. Thank you!!!",
  },
  {
    name: "Gayle",
    message: "Wonderful! I have used this app as both a member & as the secretary of my congregation. I found it very easy to work with in the office & love to have my church family's info at my fingertips! I have just retired so don't have the access I did when I worked, but no problem, I just open My Church Directory & phone, text or email with no problems. All churches should use this app!",
  },
  {
    name: "Krisss",
    message: "Incredibly valuable to our class. We have a large Sunday school class … 150 people, and this app has proved to be such a valuable tool for us. The ability to update quickly and easily, add birthdays and anniversaries, provide care groups and photos is just incredible. With every update comes new and exciting changes and improvements. The staff here does an excellent job. I highly recommend it for any church or Sunday school class.",
  }
];

const icons = [
  FaUsers,
  IoIosSend,
  FaChurch,
  BsFileEarmarkTextFill,
  FaBirthdayCake,
  FaCalendarDays,
  FaFileCsv,
  FaBookOpen,
  AiTwotoneMail,
  ImMobile
];


const Home = () => {
  const { setSelected }: any = useActiveNavbar();

  return (
    <div>
      <section className="hero__container relative flex flex-col justify-center w-full h-96 px-8 bg-gradient-to-r from-cyan-300 to-blue-300">
        <h1 className="text-center text-5xl"><b>Produce a professional<br />church directory.</b></h1>
        <p className="text-center font-[cursive] text-3xl">Anytime. Anywhere.</p>
        <div className="text-center mt-8">
          <Link to="/free-trial" className="bg-blue-700 text-white py-2 px-4 rounded-sm font-bold">Start Your FREE Trial</Link>
        </div>
      </section>

      <section className="body__container__1 w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24 flex flex-col">
        <h1 className="text-start text-[#2B3998] font-semibold text-4xl mb-8">A simple and easy way to create your church directory.</h1>
        <div className="flex flex-col md:flex-row flex-1 gap-4 md:gap-8 justify-between">
          <div className="body__1__text">
            <p className="text-start font-[cursive] text-2xl mb-4">Dear Colleague in Ministry,</p>
            <p className="text-start  mb-2">Is your church's photo directory perpetually outdated? We understand the frustration of keeping a printed directory current &ndash; new members join, people move, kids grow up, and lives change. Before you know it, that once-beautiful photo directory no longer accurately represents your congregation.</p>
            <p className="text-start  mb-2">Welcome to a new era of church directories &ndash; My Church Directory™, the ultimate online membership directory program. Say goodbye to the days of printing and distributing directories that become obsolete as soon as they're printed. With My Church Directory, you can craft a professional church photo directory yourself within a few hours.</p>
            <p className="text-start mb-2">Don't delay any longer! Embrace the power of an updated directory today by kickstarting your 30-day FREE TRIAL. We're so confident you'll be delighted that we offer a full refund if you're not satisfied.</p>
            <p className="text-start mb-4">Faithfully,</p>
            <p className="text-start font-[cursive] text-xl">-The My Church Directory Team</p>
          </div>

          <img src="../images/artificial-intelligence.png" alt='artificial-intelligence' className="md:w-[25rem] lg:w-[35rem] h-96" />
        </div>

      </section>

      <section className="body__container__2 relative w-full px-4 py-8 bg-slate-100 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24 flex flex-col">
        <h1 className="text-start text-[#2B3998] font-semibold text-4xl mb-8">My Church Directory is a full-feature church directory program.</h1>
        <div className="flex flex-col md:flex-row gap-16 justify-between items-center">
          <img src="../images/book-directory.jpg" width={300} height={140} alt='business-consulting-hero' />
          {/* Img src: https://unsplash.com/photos/shallow-focus-photography-of-books-lUaaKCUANVI */}
          <p className="text-start mb-4">From the start, our mission has been to develop an affordable online church directory program that's truly instantaneous. We recognize that staying connected to your members is a vital aspect of your ministry, and fostering connectivity within your church community is equally essential. However, in this fast-paced world, achieving these goals can be challenging. Enter My Church Directory &ndash; the online directory solution that empowers you to maintain up-to-date member contact information from anywhere, at any time. Whether you're at home or in your church office, simply log in from any computer and experience the benefits of a professional church photo directory at a fraction of the cost.</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-x-8 md:gap-y-4 my-8">
          {directoryContainer.map((item, index) => (
            <div key={index} className="flex flex-col">
              <p className="text-xl text-[#2B3998]">{item.title}</p>
              <p>{item.desc}{" "}
                <span className="hover:underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
                  <Link to={item.link} onClick={() => setSelected(item.link)}>Learn more</Link>
                </span>
                .
              </p>
            </div>
          ))}
        </div>

        <div className="md:absolute left-0 right-0 -bottom-8 w-full md:px-12 lg:px-16 xl:px-20 2xl:px-24">
          <FreeTrialTag />
        </div>
      </section>

      <section className="body__container__3 w-full px-4 py-8 mt-8 md:mt-16 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24 flex flex-col">
        <h1 className="text-start text-[#2B3998] font-semibold text-4xl mb-4">Setting Up Your Directory – Fast and Easy</h1>
        <div className="flex flex-col md:flex-row gap-4 md:gap-16 justify-between items-center">
          <p className="w-full md:w-1/2 text-start mb-4">"My" isn't just a buzzword; it's our promise. Unlike traditional photo directory companies that take weeks or months, we believe that within an hour, you can create a sample online membership directory to present to a committee, to a board of your organization, or to other leaders for approval. With a trial account, you can simply enter family details, photos, cover images, and other pertinent information. Want to effortlessly bring your member data on board? Our import tool has you covered! Seamlessly upload the bulk of your directory information for both Families and Individuals in a breeze. We allow for the import of a CSV file to streamline the process. Additional time may be required based on your church size, but completion is swift and stress-free.</p>
          <img src="../images/setting-directory.jpg" alt='business-consulting-hero-3' className="w-full md:w-1/2 h-80" />
        {/* Img src: https://unsplash.com/photos/macbook-pro-near-white-open-book-FHnnjk1Yj7Y */}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 md:gap-x-8 md:gap-y-4 my-16">
          {settingsContainer.map((item, index) => (
            <div key={index} className="flex flex-col">
              <p className="text-xl text-[#2B3998]">{item.title}</p>
              <img src={item.img} alt={item.img.split("/")[item.img.split("/").length-1]} className="w-full h-52 my-4" />
              <p>{item.desc}
                {item.link &&
                  <>
                    {" "}
                    <span className="hover:underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
                      <Link to={item.link} onClick={() => setSelected(item.link)}>You can view a sample directory here</Link>
                    </span>
                    .
                  </>
                }
              </p>
            </div>
          ))}
        </div>

      </section>

      <section className="body__container__4 relative w-full px-4 py-8 bg-slate-100 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24 flex flex-col">
        <h1 className="text-start text-[#2B3998] font-semibold text-4xl mb-8">Review our best online church directory features.</h1>
        <p className="text-start mb-4">With My Church Directory, we provide the tools to make creating and maintaining a church photo directory simple and fast. Access all the features below with one, low monthly membership price of $9.99.</p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 my-8">
          {reviewContainer.map((item, index) => {
            const Icon = icons[index];
            return (
              <div key={index} className="flex flex-row gap-8 items-center">
                <Icon className="text-[#2B3998] w-12 h-12" />

                <div className="flex flex-1 flex-col text-start">
                  <p className="text-xl text-start text-[#2B3998]">{item.title}</p>
                  <p>{item.desc}
                    {item.linK &&
                      <>
                        {" "}
                        <span className="hover:underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
                          <Link to={item.linK} onClick={() => setSelected(item.linK)}>Learn more</Link>
                        </span>
                        .
                      </>
                    }
                  </p>
                </div>
              </div>
            )
          })}
        </div>

        <div className="flex flex-row gap-8 items-center mb-8">
          <IoDiamondOutline className=" text-[#2B3998] w-12 h-12" />
          <div className="flex flex-1 flex-col text-start">
            <p className="text-xl text-start text-[#2B3998]">Elevate with Premium Features</p>
            <p>In addition to enjoying all the essential features that come with our standard subscription, Premium unlocks a range of customizable options to match your branding and membership preferences that will truly resonate with your users. This added layer of personalization creates a more engaging and unique experience for everyone involved. Curious to know more? Dive deeper into what My Church Directory Premium can offer your community by reading more
              {" "}
              <span className="hover:underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
                <Link to="/premium-service" onClick={() => setSelected("/premium-service")}>here</Link>
              </span>
              .
            </p>
          </div>
        </div>

        <div className="md:absolute left-0 right-0 -bottom-8 w-full md:px-12 lg:px-16 xl:px-20 2xl:px-24">
          <FreeTrialTag />
        </div>
      </section>

      <section
        style={{ backgroundImage: `url('../images/home-bottom-bg.png')` }}
        className="body__container__5 flex flex-col justify-center w-full h-96 px-8 bg-no-repeat bg-fixed bg-cover bg-center md:bg-origin-border mt-16 md:mt-24">
        <p className="text-end font-[cursive] text-white text-3xl md:mr-24">We've learned an important lesson:<br />The church is people. Not a building!</p>
      </section>

      <section className="body__container__6 relative w-full px-4 py-8 bg-slate-100 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24 flex flex-col">
        <h1 className="text-start text-[#2B3998] font-semibold text-4xl mb-8">What customers say about My Church Directory.</h1>
        <p className="text-start mb-4">Read what others love about our online church directory program.</p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 my-8">
          {customerFeedBack.map((item, index) => (
            <div key={index} className="flex flex-1 flex-col text-start">
              <p className="text-xl text-center text-[#2B3998]">{item.name}</p>
              <p>{item.message}</p>
            </div>
          ))}
        </div>

      </section>

      <section className="body__container__7 w-full px-4 py-8 mt-8 md:mt-16 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24 flex flex-col">
        <h1 className="text-start text-[#2B3998] font-semibold text-4xl mb-4">Say Goodbye to Outdated Directories & Gain Instant Access for 30 days, FREE!</h1>
        <div className="flex flex-col md:flex-row gap-4 md:gap-16 justify-between">
          <div className="body__1__text w-full md:w-1/2">
            <p className=" text-start my-4">Choose a modern approach to church directories &ndash; choose <span className="font-[cursive]">My Church Directory</span>.</p>
            <p className=" text-start mb-4">For the online member directory experience, start your free trial today. Don't let outdated directories hold your church back any longer. With <span className="font-[cursive]">My Church Directory</span>, you're in control.</p>
            <p className=" text-start mb-4">Pricing is Simple. No plans. No contracts.</p>
            <p className=" text-start mb-4">If you like <span className="font-[cursive]">My Church Directory</span>, do nothing and your membership will continue automatically after your free trial period is over. All your information will be saved, so you won't have to start over.</p>
            <p className=" text-start mb-4">There's no complicated tier-pricing based on the number of members, and no annual contracts are required. Furthermore, your members won't have to pay for mobile apps or online access or even feel pressured to buy photos.</p>
          </div>
          <img src="../images/free-trial-mycdir.png" alt='free-trial' className="w-full md:w-1/2 h-80" />
          {/* Img src: From Canva */}
        </div>
      </section>

      <section className="body__container__8 w-full px-4 py-8  bg-slate-100 mt-8 md:mt-16 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24 flex flex-col md:flex-row items-center gap-8 md:gap-16">
        <div className="flex flex-col w-full md:w-3/4">
          <h1 className="text-start text-[#2B3998] font-semibold text-5xl mb-4">Start today! Cancel at any time.</h1>
          <p className=" text-start">Flexibility is at the heart of our service. If you need to discontinue or adjust your membership, it's easy to do so. Opting for either a yearly or monthly plan provides you with the flexibility to fit your budget. Plus, you can cancel whenever necessary, all at the simple click of a button.</p>
        </div>

        <span className="text-center w-full md:w-1/4">
          <Link to="/free-trial" className="bg-blue-700 text-white py-2 px-4 rounded-sm font-bold">Start FREE Trial</Link>
        </span>
      </section>

    </div>
  )
}

export default Home