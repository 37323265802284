import { FaFacebookF, FaLinkedinIn, FaXTwitter } from "react-icons/fa6"
import { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import useActiveNavbar from '../hook/useActiveNavbar';

const firstColumn = [
    {
        name: "Home",
        link: "/"
    },
    {
        name: "Pricing",
        link: "/pricing"
    },
    {
        name: "Features",
        link: "/features"
    },
    {
        name: "Security",
        link: "/security"
    },
    {
        name: "Text and Email Messaging",
        link: "/text-and-email-messaging"
    },
    {
        name: "Demos",
        link: "/demos"
    },
    {
        name: "Blog",
        link: "/blog"
    }
];

const secondColumn = [
    {
        name: "Terms of Service",
        link: "/terms-of-service"
    },
    {
        name: "Privacy Policy",
        link: "/privacy-policy"
    },
    {
        name: "Help",
        link: "/help"
    },
    {
        name: "Contact Us",
        link: "/contact-us"
    }
];

const Footer = () => {
    const { setSelected }: any = useActiveNavbar();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setSelected(location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const hideHeaderForAdmin = "admin";
    // const hideHeaderForMember = "member";
    const hideHeaderForPageNotFound = "not-found";
    const hideHeaderForUnAuthorised = "un-authorized";
    if (location.pathname.replace("/", "").startsWith(hideHeaderForAdmin)
        // || location.pathname.replace("/", "").startsWith(hideHeaderForMember)
        || location.pathname.replace("/", "").includes(hideHeaderForPageNotFound)
        || location.pathname.replace("/", "").includes(hideHeaderForUnAuthorised)
    ) {
        return <></>;
    }

    return (
        <footer className="bg-gray-700 w-full h-50 p-8 pt-10 pb-4 text-center px-4 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
            <div className="flex flex-1 flex-col md:flex-row gap-4 items-center md:items-start">
                <div className="flex flex-col justify-center items-center w-3/4 md:w-1/6">
                    <img className="logo-img h-24" src="../images/logo-no-background.png" alt="logo" />
                    <button
                        className="w-full bg-blue-700 px-3 md:px-0.5 lg:px-3 py-1 rounded-md text-white text-lg md:text-base lg:text-lg font-bold mt-4"
                        onClick={() => navigate("/free-trial")}
                    >
                        Start Free Trial
                    </button>
                    <div className="flex flex-row gap-1 mt-4">
                        <Link to="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-slate-400 text-sm p-2 bg-gray-500 w-8 h-8 rounded-full"><FaFacebookF /></Link>
                        <Link to="https://twitter.com/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-slate-400 text-sm p-2 bg-gray-500 w-8 h-8 rounded-full"><FaXTwitter /></Link>
                        <Link to="https://linkedin.com/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-slate-400 text-sm p-2 bg-gray-500 w-8 h-8 rounded-full"><FaLinkedinIn /></Link>
                    </div>
                </div>

                <ul className="flex flex-1 flex-col justify-center items-center md:items-start w-full md:ml-6 lg:ml-8" >
                    <span className="text-white text-lg font-bold text-center md:text-start">Site Navigation</span>
                    {firstColumn.map((item, index) => (
                        <li key={index} className="group w-fit md:text-sm lg:text-base font-semibold md:font-normal lg:font-semibold text-white">
                            <Link to={item.link} onClick={() => setSelected(item.link)}>{item.name}</Link>
                            <div className="transform duration-1000 ease-in-out h-0.5 w-0 group-hover:w-full group-hover:bg-white group-hover:opacity-80" />
                        </li>
                    ))}
                </ul>

                <ul className="flex flex-1 flex-col justify-center items-center md:items-start w-full">
                    <span className="text-white text-lg font-bold text-center md:text-start">Important Links</span>
                    {secondColumn.map((item, index) => (
                        <li key={index} className="group w-fit md:text-sm lg:text-base font-semibold md:font-normal lg:font-semibold text-white">
                            <Link to={item.link} onClick={() => setSelected(item.link)}>{item.name}</Link>
                            <div className="transform duration-1000 ease-in-out h-0.5 w-0 group-hover:w-full group-hover:bg-white group-hover:opacity-80" />
                        </li>
                    ))}
                </ul>

                <div className="flex flex-1 flex-row">
                    <div className="w-full flex flex-col justify-center items-center">
                        <span className="text-white text-lg font-bold">Download Our Free Apps</span>
                        <div className="flex flex-col lg:flex-row justify-center gap-2">
                            <Link to="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer"><img className="logo-img h-12" width={140} src="../images/apple-store-badge.png" alt="apple-store-logo" /></Link>
                            <Link to="https://play.google.com/store/" target="_blank" rel="noopener noreferrer"><img className="logo-img h-12" width={140} src="../images/google-play-badge.png" alt="google-play-store-logo" /></Link>
                        </div>
                    </div>
                </div>

            </div>

            <hr className="bg-white w-full h-0 5 mt-16 mb-4" />
            <span className="w-full text-white">&copy; {new Date().getFullYear()} My Church Directory. All Rights Reserved. </span>
        </footer>
    )
}

export default Footer;