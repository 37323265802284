import { useEffect, useState } from "react";
import baseURL from "../../../config/api";
import DeleteCreditCard from "./DeleteCreditCard";
import { RxCross1 } from "react-icons/rx";
import { BsSearch } from "react-icons/bs";
import { RiArrowDownSFill } from "react-icons/ri";
import Pagination from "../../../components/Pagination";

export const AllCreditCards = () => {
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [particularCreditDetails, setParticularCreditDetails] = useState<any>();
    const [editPopup, setEditPopup] = useState<boolean>(false);

    const [searchTableData, setSearchTableData] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [displayFilter, setDisplayFilter] = useState<boolean>(false);
    const [creditCardFilterValue, setCreditCardFilterValue] = useState(0);
    const [paginationModel, setPaginationModel] = useState<{ page: number, size: number }>({
        page: 0,
        size: 5,
    });
    const [totalLength, setTotalLength] = useState<number>(0);
    const [selectedFilter, setSelectedFilter] = useState<string>("CardHolder Name");
    const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
    const [showEntries, setShowEntries] = useState<number>(5);


    useEffect(() => {
        if (isSaved && !isFirstTime) {
            const newPaginationModel = {
                page: 0,
                size: 5,
            };
            setPaginationModel(newPaginationModel);
            setIsSaved(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSaved])


    useEffect(() => {
        if (searchValue === "" && !isFirstTime) {
            const newPaginationModel = {
                page: 0,
                size: paginationModel.size,
            };

            setPaginationModel(newPaginationModel);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (searchValue === "") {
            setIsFirstTime(false)
            apiCall(paginationModel);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel])

    const apiCall = (newPaginationModel: { page: number, size: number }) => {
        setIsDataLoaded(true);
        fetch(`${baseURL}creditCard/GetCreditCardReportPaging/${newPaginationModel.page},${newPaginationModel.size}`)
            .then((res) => res.json())
            .then((data) => {
                const result = data.creditCardReport.map((item: any) => {
                    return {
                        ...item,
                        paymentGateway: item.paymentGateway ? item.paymentGateway : "",
                    };
                })

                setSearchTableData(result);
                setTotalLength(data.count);
                setIsDataLoaded(false);

            }).catch((error) => {
                console.log(error);
                setIsDataLoaded(false);
                setSearchTableData([]);
                setTotalLength(0);
            });
    }


    useEffect(() => {
        let timeOutId: NodeJS.Timeout;

        if (searchValue !== "") {
            timeOutId = setTimeout(() => {
                const newPaginationModel = {
                    page: 0,
                    size: paginationModel.size,
                };

                setPaginationModel(newPaginationModel);

            }, 1000);
        }

        return () => clearTimeout(timeOutId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (searchValue !== "") {
            filteApiCall(paginationModel);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel])

    const filteApiCall = (newPaginationModel: { page: number, size: number }) => {
        let filterData: any[] = [];
        setIsDataLoaded(true);
        switch (creditCardFilterValue) {
            case 0:
                fetch(
                    `${baseURL}creditCard/GetCreditCardReportSearchWithCardholderName/${searchValue},${newPaginationModel.page},${newPaginationModel.size}`
                )
                    .then((res) => res.json())
                    .then((data) => {
                        filterData = data.creditCardReport.map((item: any) => {
                            return {
                                ...item,
                                paymentGateway: item.paymentGateway ? item.paymentGateway : "",
                            };
                        })

                        setSearchTableData(filterData);
                        setTotalLength(data.count);
                        setIsDataLoaded(false);

                    })
                    .catch((error) => {
                        console.log(error);
                        setIsDataLoaded(false);
                        setSearchTableData([]);
                        setTotalLength(0);
                    });

                break;
            case 1:
                fetch(
                    `${baseURL}creditCard/GetCreditCardReportSearchWithLast4Digits/${searchValue},${newPaginationModel.page},${newPaginationModel.size}`
                )
                    .then((res) => res.json())
                    .then((data) => {
                        filterData = data.creditCardReport.map((item: any) => {
                            return {
                                ...item,
                                paymentGateway: item.paymentGateway ? item.paymentGateway : "",
                            };
                        })
                        setSearchTableData(filterData);
                        setTotalLength(data.count);
                        setIsDataLoaded(false);

                    })
                    .catch((error) => {
                        console.log(error);
                        setIsDataLoaded(false);
                        setSearchTableData([]);
                        setTotalLength(0);
                    });

                break;
        }
    }

    const applyFilter = () => {
        setSelectedFilter(() =>
            creditCardFilterValue === 0 ? "CardHolder Name" : "Last 4 Digits"
        );
        setSearchValue("");
        setDisplayFilter(false);
    };

    const clearFilter = () => {
        setSearchValue("");
        setDisplayFilter(false);
    };


    return (
        <>
            <div className="w-full">
                <div className="flex space-x-3 justify-between items-center border border-black p-1 md:p-2 mt-4">
                    <div className="relative flex space-x-3 justify-between items-center">
                        <div className='relative'>
                            <button
                                className="bg-blue-500 text-white px-3 py-1 rounded-md flex items-center hover:bg-blue-700 focus:outline-none"
                                onClick={() => setDisplayFilter(!displayFilter)}
                            >
                                {selectedFilter}
                                <RiArrowDownSFill className="ml-1" />
                            </button>

                            <div
                                className={`${displayFilter ? "block" : "hidden"
                                    } absolute w-40 ml-1 p-2 bg-white border border-black top-0 left-full`}
                            >
                                <div className="flex justify-between">
                                    <label className="text-xs font-bold" htmlFor="fields">
                                        Fields
                                        <br />
                                    </label>
                                    <div
                                        onClick={() => setDisplayFilter(false)}
                                        className="w-4 h-4 cursor-pointer text-sm text-center items-center"
                                    >
                                        <RxCross1 />
                                    </div>
                                </div>
                                <select
                                    className="w-full text-xs py-1 border mt-1"
                                    name="fields"
                                    value={creditCardFilterValue}
                                    onChange={(e) =>
                                        setCreditCardFilterValue(parseInt(e.target.value))
                                    }
                                >
                                    <option value="0">CardHolder Name</option>
                                    <option value="1">Last 4 Digits</option>

                                </select>

                                <div className='flex mt-2 justify-between whitespace-nowrap'>
                                    <button
                                        className="text-xs bg-blue-500 text-white py-1 px-2 rounded-md mt-3 hover:bg-blue-600 focus:outline-none"
                                        onClick={() => applyFilter()}
                                    >
                                        Apply Filter
                                    </button>

                                    <button
                                        className="text-xs bg-blue-500 text-white py-1 px-2 rounded-md mt-3 hover:bg-blue-600 focus:outline-none"
                                        onClick={() => clearFilter()}
                                    >
                                        Clear
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div>
                            <div className="flex items-center gap-2">
                                <div className="h-4 w-4 text-gray-900">
                                    <BsSearch />
                                </div>

                                <input
                                    type="text"
                                    placeholder="Search…"
                                    className="py-1 text-lg pl-2 outline-none"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 overflow-x-auto">
                    {!isDataLoaded ? (
                        <div className="border-none text-xs break-words overflow-visible">

                            <div>
                                <table className="min-w-full bg-white text-center">
                                    <thead className="bg-gray-200">
                                        <tr>
                                            <th className="px-6 py-3 text-sm font-semibold text-black text-center">#</th>
                                            <th className="px-6 py-3 text-sm font-semibold text-black text-center">Payment Gateway</th>
                                            <th className="px-6 py-3 text-sm font-semibold text-black text-center">Cardholder's Name</th>
                                            <th className="px-6 py-3 text-sm font-semibold text-black text-center">Brand</th>
                                            <th className="px-6 py-3 text-sm font-semibold text-black text-center">Expiration Date</th>
                                            <th className="px-6 py-3 text-sm font-semibold text-black text-center">Last 4 Digits</th>
                                        </tr>
                                    </thead>
                                    <tbody className="whitespace-nowrap">
                                        {searchTableData.length > 0 ? (
                                            searchTableData.slice(0, showEntries).map((row, index) => {
                                                return (
                                                    <tr key={row.id} className={index % 2 === 0 ? "bg-blue-50" : "bg-blue-100"}>
                                                        <td className="px-6 py-4 text-sm">
                                                            <p
                                                                className="text-blue-500 underline cursor-pointer"
                                                                onClick={() => {
                                                                    setEditPopup(true);
                                                                    setParticularCreditDetails(row);
                                                                }}
                                                            >
                                                                {row.id}
                                                            </p>
                                                        </td>
                                                        <td className="px-6 py-4 text-sm">{row.paymentGateway}</td>
                                                        <td className="px-6 py-4 text-sm">{row.cardholderName}</td>
                                                        <td className="px-6 py-4 text-sm">{row.brand}</td>
                                                        <td className="px-6 py-4 text-sm">{row.expirationDate}</td>
                                                        <td className="px-6 py-4 text-sm">{row.creditCardNumber}</td>
                                                    </tr>
                                                )
                                            })
                                        )

                                            :
                                            (
                                                <tr>
                                                    <td colSpan={7} className="border border-gray-300 px-2 py-1 text-sm font-medium">No data available</td>
                                                </tr>
                                            )

                                        }
                                    </tbody>
                                </table>
                            </div>
                            {
                                totalLength > 0 && (
                                    <Pagination
                                        pageOption={[5, 10, 15, 20, 25, 50, 75, 100]}
                                        totalLength={totalLength}
                                        paginationModel={paginationModel}
                                        setPaginationModel={setPaginationModel}
                                        showEntries={showEntries}
                                        setShowEntries={setShowEntries}
                                    />
                                )
                            }
                        </div>
                    ) :

                        (<div id="loading-colors" className="min-w-full h-[480px] flex flex-col flex-1 justify-center items-center">
                            <div className="flex flex-col items-center">
                                <div
                                    className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite] text-blue-500"
                                    role="status"></div>
                                <span className="text-black">Loading...</span>
                            </div>
                        </div>)
                    }
                </div>
            </div>


            <div>
                <DeleteCreditCard
                    editPopup={editPopup}
                    hidePopup={() => setEditPopup(false)}
                    deleteCreditCard={() => {
                        setEditPopup(false);
                        setIsSaved(true);
                    }}
                    particularCreditDetails={particularCreditDetails}
                />
            </div>
        </>
    );
};
