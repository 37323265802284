import StartYourFreeTrial from "../../components/StartYourFreeTrial";
import { BsQuestionLg } from "react-icons/bs";
import TryMyCdir from "../../components/TryMyCdir";

const membership = [
  {
    name: "Rest easy with fully secured and private access!",
    description: "You're worried about security and privacy, and so are we. That's why we built a fully secure system for all our mobile apps. Only your members can download your church's directory.Members must have a valid email listed in the directory in order to create a secure login. Only members you authorize can gain access, and directories are automatically wiped from all their devices if you revoke access. If you would prefer that your directory is not available via the app, simply disable the feature.We at MyChurch Directory do not access your members' data, except for specific troubleshooting purposes within the directory. While we store the data, we do not access it, nor do we use it for marketing purposes (either internally or by selling the information to other organizations). This is included in our Privacy Policy.",
  },
  {
    name: "Search, call or text using the mobile app.",
    description: "This app is mighty! Search for members and then see all their contact information. Click the address to launch the maps app on your device. Click a phone number to call or text. See birth dates, anniversaries and more!Church staff contact information is at your fingertips and you can view everyone in a specific church group.",
  },
  {
    name: "Members can submit updates.",
    description: "Users can conveniently propose edits to their own member profiles through the app or the dedicated online member website. Then you or designated editors review these updates for approval. Once confirmed, the modifications instantly synchronize across all member devices, ensuring everyone has access to the most up-to-date information.Periodically remind members to check their contact information in the app and submit any updates. The Admin and Editor volunteers are alerted and can see what was updated and approve it. As soon as that is done, the updated information syncs to everyone!",
  },
];

const MemberMobileApp = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url('../images/my-Church-Directory-mobile-apps-banner-photo.jpg')`,
          backgroundSize: "100% 80vh",
        }}
        className="hero--container flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border md:h-[60vh] pt-[65px]"
      ></section>

      <section className="w-full items-center justify-center">
        <div className="max-w-screen-lg mx-auto py-8">
          <h1 className="text-custom-blue text-3xl md:text-4xl font-[cursive] pt-2 mb-4 text-center">
            My Church Directory Mobile App for Members Free
          </h1>

          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 p-6">
              <h1 className="text-custom-blue text-3xl md:text-6xl font-normal mb-5 text-center md:text-center">
                FREE<br />Mobile Apps
              </h1>
              <h1 className="text-custom-blue text-3xl md:text-3xl font-normal mb-5 text-center md:text-center">
                Access your church directory <br />instantly from your mobile devices.
              </h1>
            </div>

            <div className="w-full md:w-1/2 flex justify-center items-center">
              <img src="../images/tell-your-members.jpg" alt={`tell-your-members`} />
            </div>
          </div>

          <div className="flex flex-col md:flex-row">
            <div className="px-8 md:px-1 md:pr-2 py-6">
              <p className="font-normal text-center md:text-left">
                Effortlessly manage directory members through MyChurch Directory.
                Easily link your membership directory with the mobile devices of
                your church community. Utilizing the MyChurch Directory free mobile apps,
                members swiftly locate families or individuals and initiate emails,
                calls, or text messages directly from their devices, even in offline mode.
                <br /><br />
                Updates to the directory by the administrator instantly sync with
                members' devices. Get the app from Apple App and Google Play stores
                - your church's active membership with MyChurch Directory is all that's necessary.
                <br /><br />
                The MyChurch Directory mobile app empowers any member to search
                for a family or individual and communicate via email or call —
                directly from their mobile device. The online membership directory
                is downloadable to their device and functions offline when the internet isn't available.
                <br /><br />
                For members without a mobile device, the Online Members' Directory website is always accessible.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 md:py-24 px-5 md:px-12 bg-gray-200">
        <div className="flex flex-col md:flex-row ">
          <div className="w-full md:w-3/5">
            <h2 className="text-custom-blue text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-semibold text-left px-1 mb-8 pt-1 md:pt-1">
              Commence using the member apps.
            </h2>

            {membership.map((item, index) => (
              <div
                key={index}
                className="body--container md:mr-4"
              >
                <div className="flex flex-col items-left mb-1 pt-2 md:pt-1">
                  <h3 className="text-custom-blue text-lg md:text-xl mb-2 p-1">
                    {item.name}
                  </h3>
                  <p className="text-left p-0.5">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="w-full md:w-2/5 justify-center items-center">
            <h3 className="text-custom-blue text-lg md:text-xl mb-2 p-1">
              Where to download the app.
            </h3>
            <p className="text-left p-0.5">
              To download the app, click the appropriate image for your device:
            </p>
            <img className="logo-img h-11 my-4" src="../images/apple-store-badge.png" alt="apple-store-badge" />
            <img className="logo-img h-11" src="../images/google-play-badge.png" alt="google-play-badge" />
          </div>
        </div>
      </section>
      <TryMyCdir />

      <section className="py-10 md:py-18 px-2 md:px-12">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-2/5 p-6">
            <img src="../images/member-mobile.jpg" alt="member-mobile" />
            <p className="text-left py-6 md:text-left">
              "For 5 months, our church has utilized this and we're exceedingly content.
              Continual improvements make it even better. Couldn't be happier!" —Cruce
              <br /><br />
              "Absolutely adore this app. It's a great tool for newcomers in our
              community to connect and get acquainted with one another effortlessly." —Mickel
            </p>
          </div>

          <div className="w-full md:w-3/5 justify-center items-center px-5">
            <h1 className="text-custom-blue text-3xl md:text-4xl font-semibold mb-5 text-left md:text-left">
              Feedback from Church Members on the My Church Directory Mobile App.
            </h1>
            <p className="text-left pb-2 md:text-left">
              Praised with a remarkable 4.5-star rating, our app has enamored hundreds of
              thousands of users! Here's a peek into what members are expressing.
              <br /><br />
              A game-changer for our church community! Seamless and user-friendly." — Michael
              <br /><br />
              "Fantastic app! Simplifies our church connections effortlessly." — Emily
              <br /><br />
              "A blessing to our church! Simple to use, and keeps us well-connected." — Sarah
              <br /><br />
              "Incredible! Our church members find it indispensable and easy to navigate." — Daniel
              <br /><br />
              "Absolutely love this app! It's made communication within our church a breeze." — John
              <br /><br />
              "So easy and practical! Our church members are loving the accessibility." — Rachel
            </p>
          </div>
        </div>
      </section>

      <section className="py-8 px-2 md:px-12 bg-gray-200">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 p-6">
            <h1 className="text-custom-blue text-3xl md:text-4xl font-semibold mb-5 text-left md:text-left">
              Inform your members.
            </h1>
            <p className="text-left pb-2 md:text-left">
              Activate the mobile apps and spread the word among your members!
              Ensure regular communication about the mobile apps through various
              channels such as your website, newsletters, bulletins, and emails.
              We suggest displaying a flyer in your lobby or fellowship area.
              Explore our range of resources aimed at assisting you in promoting
              your new online church directory.
            </p>
          </div>

          <div className="w-full md:w-1/2 flex justify-center items-center">
            <img src="../images/tell-your-members.jpg" alt="tell-your-members-2" className="w-96" />
          </div>
        </div>
      </section>

      <section
        style={{
          backgroundImage: `url('../images/my-Church-Directory-more-info-image.jpg')`,
          backgroundSize: "100% 100vh",
        }}
        className="body--container relative flex flex-col justify-center w-full h-80vh bg-no-repeat bg-fixed md:bg-origin-border md:h-80vh py-24 px-2 md:px-12"
      >
        <div className="flex flex-col md:flex-row">
          <div className="md:w-3/5 p-10 bg-white bg-opacity-70 flex items-center justify-center md:justify-start">
            <BsQuestionLg className="text-custom-blue flex-shrink-0 text-5xl md:text-8xl" />
            <p className="text-lg font-[cursive] text-left pb-2 md:text-left text-custom-blue">
              For further insights into My Church Directory, explore our guidance documents available at MyCDir.com.
            </p>
          </div>
        </div>
      </section>

      <StartYourFreeTrial />
    </>
  );
};

export default MemberMobileApp;
