import { useEffect, useState } from 'react';
import useDelete from '../../hook/useDelete';
import { delete_AchInfo, getACHInfoWithGroupId } from '../../services/ACHInfo';
import Cookies from 'js-cookie';
import Pagination from '../../components/Pagination';

const heading = ["#", "Account Number", "Account Type", "Name", "Routing Number", "Phone Number"];

type ACHDetailsProps = {
    isRefresh: boolean;
    setIsRefresh: (value: boolean) => void;
}

const ACHDetails = ({ isRefresh, setIsRefresh }: ACHDetailsProps) => {
    const userId = Cookies.get("userId-my-cdir");
    const groupId = Cookies.get("groupId-my-cdir");
    const { setDeletePopup, setData: setDeleteData }: any = useDelete();
    const [achData, setAChData] = useState<any[]>([]);
    const [paginationModel, setPaginationModel] = useState<{ page: number, size: number }>({
        page: 0,
        size: 5
    });
    const [totalLength, setTotalLength] = useState<number>(0);
    const [showEntries, setShowEntries] = useState<number>(5);

    useEffect(() => {
        if (groupId && isRefresh) {
            setIsRefresh(false);
            getACHInfoWithGroupId(groupId, paginationModel.page, paginationModel.size)
                .then(res => {
                    setTotalLength(res?.count);
                    setAChData(res?.achInfo);
                })
                .catch(error => {
                    console.error(error);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, isRefresh])

    const deleteACH = (primaryKey: number) => {
        setDeleteData({
            id: primaryKey,
            tilte: `Delete`,
            summary: `Are you sure you want to delete this ACH Info?`,
            isDelete: (callback: any) => {
                if (userId)
                delete_AchInfo(primaryKey, userId?.toString())
                    .then(() => {
                        setIsRefresh(true);
                        callback();
                    })
                    .catch(error => console.error(error))
            }
        });

        setDeletePopup(true);
    }

    return (
        <div className="w-full border border-secondary rounded-md bg-primary">
            <h4 className="text-primary font-medium bg-secondary px-4 py-2 w-full">ACH Info</h4>

            <div className="flex flex-col p-4 gap-y-3">

                <table>
                    <thead className="border-b-2 border-secondary">
                        <tr className="text-sm text-start">
                            {heading.map((item, index) => (
                                <th key={index} className="border-r border-secondary text-start px-0.5">
                                    {item}
                                </th>
                            ))}
                            <th className="text-start px-0.5 !pl-1.5 text-blue-500 font-bold select-none"></th>
                        </tr>
                    </thead>

                    <tbody className="border-b border-secondary">
                        {achData?.map((item: any, index: number) => (
                            <tr
                                key={index}
                                className="text-sm text-start"
                            >
                                <td className="border-r border-b border-secondary text-start px-0.5 py-1.5">{item?.id}</td>
                                <td className="border-r border-b border-secondary text-start px-0.5 py-1.5">{item?.accountNumber}</td>
                                <td className="border-r border-b border-secondary text-start px-0.5 py-1.5">{item?.accountType}</td>
                                <td className="border-r border-b border-secondary text-start px-0.5 py-1.5">{item?.bankName}</td>
                                <td className="border-r border-b border-secondary text-start px-0.5 py-1.5">{item?.routingNumber}</td>
                                <td className="border-r border-b border-secondary text-start px-0.5 py-1.5">{item?.bankPhone}</td>
                                <td
                                    className="text-start border-b border-secondary px-0.5 !pl-1.5 py-1.5 hover:text-blue-500 active:text-blue-700 font-bold cursor-pointer select-none"
                                    onClick={() => deleteACH(item?.id)}
                                >
                                    {"X"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Pagination
                    totalLength={totalLength}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    setIsRefresh={setIsRefresh}
                    showEntries={showEntries} 
                    setShowEntries={setShowEntries}
                />

            </div>

        </div>
    )
}

export default ACHDetails
