import React, { useEffect, useState } from 'react'
import LeftArrow from '../icons/LeftArrow'
import RightArrow from '../icons/RightArrow'

type PageModelType = { page: number, size: number };

type PaginationProps = {
    pageOption?: number[];
    showEntries: number;
    setShowEntries: (value: number) => void;
    totalLength: number;
    paginationModel: PageModelType;
    setPaginationModel: (value: PageModelType) => void;
    setIsRefresh?: (value: boolean) => void;   
}

const Pagination = ({ pageOption = [5, 10, 20, 50, 100], showEntries, setShowEntries, totalLength, paginationModel, setPaginationModel, setIsRefresh }: PaginationProps) => {
    const [paginationOption, setPaginationOption] = useState<number[]>([1]);

    useEffect(() => {
        if (totalLength > 0) {
            const array: number[] = [];

            for (
                let i = 1;
                i <= Math.ceil(totalLength / paginationModel?.size);
                i++
            ) {
                array.push(i);
            }

            setPaginationOption(array);
        }
    }, [totalLength, paginationModel]);

    const handleShowEntriesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setPaginationModel({
            page: 0,
            size: parseInt(e.target.value),
        });
        setShowEntries(parseInt(e.target.value));
        setIsRefresh && setIsRefresh(true);
    };

    const previousPagnation = () => {
        if (paginationModel.page > 0) {
            setPaginationModel({
                ...paginationModel,
                page: paginationModel.page - 1,
            });

            setIsRefresh && setIsRefresh(true);
        }
    };

    const nextPagination = () => {
        if (paginationModel.page < paginationOption[paginationOption.length - 2]) {
            setPaginationModel({
                ...paginationModel,
                page: paginationModel.page + 1,
            });

            setIsRefresh && setIsRefresh(true);
        }
    };

    return (
        <div className="w-full text-end flex items-center pt-3 md:px-4">
            <p className="text-sm text-gray-700 flex-1">{` ${(paginationModel.page * showEntries )+ 1} to ${(showEntries * (paginationModel.page + 1)) >= totalLength ? totalLength : showEntries * (paginationModel.page + 1)} of ${totalLength} entries`}</p>
            <div className="flex items-center max-md:mt-4">
                <select className="text-sm text-gray-700 border border-gray-400 rounded h-7 mx-4 outline-none"
                    value={showEntries}
                    onChange={handleShowEntriesChange}
                >
                    {pageOption?.map((item: number) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
                <ul className="flex space-x-1 ml-2">
                    <li
                        className={`flex items-center justify-center w-7 h-7 rounded ${paginationModel.page <= 0 ? "bg-secondary/30 cursor-default" : "bg-secondary cursor-pointer"}`}
                        onClick={previousPagnation}
                    >
                        <LeftArrow className="!fill-primary" />
                    </li>

                    <li className="flex items-center justify-center text-sm w-fit h-7 rounded">
                        {`${paginationModel.page + 1} - ${paginationOption[paginationOption.length - 1]
                            } of ${paginationOption[paginationOption.length - 1]} page`}
                    </li>

                    <li
                        className={`flex items-center justify-center w-7 h-7 rounded ${totalLength <= paginationModel.size || paginationModel.page >= paginationOption[paginationOption.length - 2] ? "bg-secondary/30 cursor-default" : "bg-secondary cursor-pointer"}`}
                        onClick={nextPagination}
                    >
                        <RightArrow className="!fill-primary" />
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Pagination;