import { useState } from 'react';
import Cross from '../../icons/Cross'
import { BsFillCreditCard2FrontFill } from 'react-icons/bs';
import { post_CreditCard } from '../../services/creditCard';
import Loading from '../../icons/Loading';
import Cookies from 'js-cookie';
import CardNumberValidate from '../../components/CardNumberValidate';
import ExpiryDateValidator from '../../components/ExpiryDateValidator';
import CvvValidate from '../../components/CvvValidate';

type CreditCardProps = {
    showPopupAddNewCreditCard: boolean;
    setShowPopupAddNewCreditCard: (value: boolean) => void;
    setIsRefresh: (value: boolean) => void;
}

const AddCreditCard = ({ showPopupAddNewCreditCard, setShowPopupAddNewCreditCard, setIsRefresh }: CreditCardProps) => {
    const userId = Cookies.get("userId-my-cdir");
    const groupId = Cookies.get("groupId-my-cdir");
    const [creditCardDetails, setCreditCardDetails] = useState<any>({
        cardholderName: "",
        creditCardNumber: "",
        expirationDate: "",
        ccvCode: ""
    });

    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isProcessing) {
            setIsProcessing(true);
            post_CreditCard({
                ...creditCardDetails,
                isActive: false,
                userId: userId,
                groupId: groupId
            })
                .then(() => {
                    setIsRefresh(true);
                    setIsProcessing(false);
                    setShowPopupAddNewCreditCard(false);
                    setCreditCardDetails({
                        cardholderName: "",
                        creditCardNumber: "",
                        expirationDate: "",
                        ccvCode: ""
                    });
                })
                .catch(error => {
                    console.log(error);
                    setIsProcessing(false);
                })
        }

    }

    const closeModal = () => setShowPopupAddNewCreditCard(false);

    return (
            <div className={`fixed inset-0 p-4 mt-14 ${showPopupAddNewCreditCard ? "flex" : "hidden"} flex-wrap justify-center items-center w-full h-fit z-[1000] before:fixed before:inset-0 before:w-full before:min-h-[calc(100vh-72px)] before:bg-[rgba(0,0,0,0.5)] overflow-auto`}>
                <div className="w-full max-w-lg bg-white shadow-lg rounded-md px-5 py-4 relative">
                    <Cross closeModal={closeModal} />

                    <div className="w-full mt-5">
                        <form className="mt-1 space-y-4" onSubmit={handleSubmit}>

                            <div className="flex flex-col">
                                <label className="text-sm mb-1">Cardholder Name</label>
                                <input
                                    name="cardholderName"
                                    type="text"
                                    className="bg-transparent w-full text-sm rounded-md border border-secondary pl-2 pr-[40px] py-3 outline-none placeholder:text-[#bbb]"
                                    placeholder="Enter Cardholder Name"
                                    value={creditCardDetails.cardholderName}
                                    onChange={(e) => setCreditCardDetails({ ...creditCardDetails, cardholderName: e.target.value })}
                                    required />
                            </div>

                            <div className="relative flex flex-col">
                                <CardNumberValidate
                                    value={creditCardDetails.creditCardNumber}
                                    setValue={(value) =>
                                        setCreditCardDetails({ ...creditCardDetails, creditCardNumber: value })
                                    }
                                />

                                <BsFillCreditCard2FrontFill className="w-[18px] h-[18px] absolute right-4 items-center top-2/3 transform -translate-y-1/2 text-[#bbbbbb]" />
                            </div>

                            <div className="flex justify-between items-center gap-x-2">
                                <div className="relative flex-1 flex flex-col">
                                    <ExpiryDateValidator
                                        expiryDate={creditCardDetails.expirationDate}
                                        setExpiryDate={(value) =>
                                            setCreditCardDetails({ ...creditCardDetails, expirationDate: value })
                                        }
                                    />
                                </div>

                                <div className="relative flex-1 flex flex-col">
                                    <CvvValidate
                                        value={creditCardDetails.ccvCode}
                                        setValue={(value) =>
                                            setCreditCardDetails({ ...creditCardDetails, ccvCode: value })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="pt-4">
                                <button
                                    type='submit'
                                    className="text-white bg-secondary rounded-md border border-secondary px-2 py-2 w-full text-center"
                                >
                                    {isProcessing ?
                                        <>
                                            <Loading />
                                            Processing...
                                        </>
                                        :
                                        "Save"
                                    }
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
    )
}

export default AddCreditCard;