import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post_ForgotPasswordAdmin } from '../../services/ForgotPassword';
import EmailValidatedComponent from '../../components/EmailValidatedComponent';

const AdminForgotPassword = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState<any>({
        emailAddress: "",
    });
    const [message, setMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsProcessing(true);
        setMessage('');
        setErrorMessage('');

        post_ForgotPasswordAdmin(userData.emailAddress)
            .then((res) => {
                if (res === userData.emailAddress) {
                    setMessage(`Please check your email for password has been sent to ${userData.emailAddress}.`);
                    setIsSuccess(true);
                } else {
                    setErrorMessage('Your email address does not exist.');
                }
                setIsProcessing(false);
                setUserData({ emailAddress: '' });
            })
            .catch((error) => {
                if (error.response && error.response.status === 500) {
                    setErrorMessage('There is some problem occurring. Please try again later.');
                } else {
                    setErrorMessage('Your email address does not exist.');
                }
                console.error(error);
                setIsProcessing(false);
            });
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-2xl font-bold mb-6 text-center">Forgot Password</h2>
                {!isSuccess ? (
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <EmailValidatedComponent
                                text={userData.emailAddress}
                                setText={(value) =>
                                    setUserData({ ...userData, emailAddress: value })
                                }
                            />
                        </div>
                        <button
                            type="submit"
                            disabled={isProcessing}
                            className={`w-full ${isProcessing ? 'bg-gray-500' : 'bg-blue-700 hover:bg-gray-600'} text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
                        >
                            {isProcessing ? 'Processing...' : 'Reset Password'}
                        </button>
                    </form>
                ) : (
                    <div className="text-center">
                        <div className="mt-4 text-green-700">{message}</div>
                        <button onClick={() => navigate("/sign-in")}
                            className="mt-4 bg-blue-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Go back to sign in
                        </button>
                    </div>
                )}
                {errorMessage && (
                    <div className="mt-4 text-center text-red-500">
                        {errorMessage}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminForgotPassword;