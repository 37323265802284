import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import baseURL from '../../config/api';

const FamilyMember = () => {
  const { id, name }: any = useParams();
  const [memberData, setMemberData] = useState<any[]>([]);
  const [memberDataIsLoaded, setMemberDataIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      fetch(`${baseURL}FamilyMember/GetFamilyMemberByMemberId/${parseInt(id)}`)
        .then((res) => res.json())
        .then((data) => {
          setMemberData(
            data.map((item: any) => {
              return {
                id: item.id,
                name: item.firstName + " " + item.lastName,
                // birthday: item?.birthday ? format(new Date(item?.birthday), "MM/dd/yyyy") : "No data",
                address: `${item?.street}, ${item?.street2} ${item?.city} ${item?.state}, ${item?.country}-${item?.zip}`,
                phoneNumber: item.phoneNumber,
                emailAddress: item.emailAddress,
                imagePath:
                  item?.filePath ? `${baseURL}commonFile/Get?name=${item.filePath}` :
                    "./images/user-default.jpg"
              }
            })
          )
          setMemberDataIsLoaded(true);
        })
        .catch(error => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <section className="bg-white ">
      <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-8 lg:px-6 m-auto">
        <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-blue-500 ">{name}</h2>
          <p className="font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">{memberData.length > 0 ? "Family Member" : "No Family Member Added"}</p>
        </div>

        {memberDataIsLoaded ? (
          <div className="grid gap-8 mb-6 lg:mb-16 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {memberData.map((item, index) => (
              <div key={index} className="items-center w-[calc(100vw-80px)] md:w-full bg-gray-50 shadow-2xl rounded-lg flex flex-col sm:flex-row overflow-hidden">
                <img className="w-36 h-full rounded-lg sm:rounded-none sm:rounded-l-lg" src={item.imagePath} alt="family" />

                <div className="p-5 w-fit">
                  <h3 className="text-xl font-bold tracking-tight text-gray-900 w-fit">{item.name}</h3>


                  <div className="text-black mt-3 ">
                    <div className="flex flex-row flex-1 justify-between items-center gap-2 py-1 w-full flex-wrap">
                      <BsFillTelephoneFill />
                      <p className="flex flex-col flex-1 items-start text-base font-semibold flex-nowrap overflow-hidden overflow-ellipsis">{item.phoneNumber}</p>
                    </div>

                    <div className="flex flex-row flex-1 justify-between items-center gap-2 py-1 w-full flex-wrap">
                      <MdEmail />
                      <p className="flex flex-col flex-1 items-start text-base font-semibold flex-nowrap overflow-hidden overflow-ellipsis">{item.emailAddress}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col flex-1 justify-center items-center">
            <div
              className="h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite] text-blue-500"
              role="status"></div>
            <span className="text-black">Loading...</span>
          </div>
        )}
      </div>
    </section>
  )
}

export default FamilyMember