import axios from "axios"
import baseURL from "../config/api"

export const post_CreditCard = async (data: any) => {
    const response = await axios({
        url: `${baseURL}creditCard`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    });
    return response.data;
}

export const put_CreditCard = async (data: any) => {
    const response = await axios({
        url: `${baseURL}creditCard`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    });
    return response.data;
}

export const delete_CreditCard = async (primaryKey: number) => {
    const response = await axios({
        url: `${baseURL}creditCard`,
        method: "DELETE",
        params: { id: primaryKey }
    });
    return response.data;
}

export const getCreditCards = () => {
    return getFetch("creditCard")
}

export const getCreditCard = (primaryKey: string) => {
    return getFetch(`creditCard/${primaryKey}`)
}

export const getCreditCardOfCustomer = (customerId: string) => {
    return getFetch(`creditCard`, {customerId: customerId})
}

export const getCreditCardWithGroupIdAndIsActive = (groupId: string) => {
    return getFetch(`creditCard/GetCreditCardByGroupIdAndIsActiveWithValidExpiryDate/${groupId}`)
}

export const getCreditCardWithGroupId = (groupId: string, page: number, size: number) => {
    return getFetch(`creditCard/GetCreditCardsPagingByGroupId/${groupId},${page},${size}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}