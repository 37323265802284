import axios from "axios";
import baseURL from "../config/api";

export const post_FamilyMember = (data: any) => {
    return axios({
        url: `${baseURL}FamilyMember`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const put_FamilyMember = (data: any) => {
    return axios({
        url: `${baseURL}FamilyMember`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const delete_FamilyMember = (primaryKey: string) => {
    return axios({
        url: `${baseURL}FamilyMember`,
        method: "DELETE",
        params: { id: primaryKey }
    }).then(response => response.data);
}

export const getFamilyMembers = () => {
    return getFetch(`FamilyMember`)
}

export const getFamilyMember = (primaryKey: string) => {
    return getFetch(`FamilyMember/${primaryKey}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}