import axios from "axios"
import baseURL from "../config/api"

export const post_Group = (data: any) => {
    return axios({
        url: `${baseURL}group`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const put_Group = (data: any) => {
    return axios({
        url: `${baseURL}group`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const delete_Group = (primaryKey: string) => {
    return axios({
        url: `${baseURL}group`,
        method: "DELETE",
        params: { id: primaryKey }
    }).then(response => response.data);
}

export const getGroups = () => {
    return getFetch("group")
}

export const getGroup = (primaryKey: string) => {
    return getFetch(`group/${primaryKey}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}