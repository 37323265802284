import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { postFaq } from '../../services/Faq';
import Cookies from 'js-cookie';


const AddFaq = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [faqData, setFaqData] = useState<any>({
    question: "",
    answer: "",
    isAdmin: false,
  });

  useEffect(() => {
    if (location.pathname.endsWith("/admin/faq/addFaq")) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [location]);

  const closeModal = () => navigate("/admin/faq");
  const token = Cookies.get("token-my-cdir");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
   
    postFaq(faqData, token!.toString())
      .then(() => navigate("/admin/faq"))
      .catch((error: any) => console.error(error));
  };

  return (
    <div className={`fixed inset-0 p-4 mt-14 ${showModal ? "flex" : "hidden"} flex-wrap justify-center items-center w-full h-fit z-[1000] before:fixed before:inset-0 before:w-full before:min-h-[calc(100vh-72px)] before:bg-[rgba(0,0,0,0.5)] overflow-auto`}>
      <form onSubmit={handleSubmit} className="w-full max-w-3xl bg-white shadow-lg rounded-md px-5 py-4 relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-3 cursor-pointer shrink-0 fill-[#333] hover:fill-red-500 float-right"
          viewBox="0 0 320.591 320.591"
          onClick={closeModal}
        >
          <path
            d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
            data-original="#000000"
          ></path>
          <path
            d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
            data-original="#000000"
          ></path>
        </svg>
        <div className="my-2">
          <h4 className="text-xl text-center text-blue-500 font-semibold mb-6">Add FAQ</h4>
          <div className="mb-4">
            <label htmlFor="question" className="block text-sm text-blue-500 mb-2">Question</label>
      
            <textarea
              name="question"
              required
              rows={2}
              className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
              placeholder="Enter Question"
              value={faqData.question}
              onChange={(e) => setFaqData({ ...faqData, question: e.target.value })}
            />
          </div>
          <div className='mb-4'>
            <label htmlFor="answer" className="block text-sm text-blue-500 mb-2">Answer</label>
            
            <textarea
              name="answer"
              required
              rows={4} // Set the number of rows you want
              className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
              placeholder="Enter Answer"
              value={faqData.answer}
              onChange={(e) => setFaqData({ ...faqData, answer: e.target.value })}
            />
          </div>
        
          <div className="flex justify-center">

            <div className="w-full text-sm mb-2">
              <label htmlFor="isAdmin" className="w-full text-start block text-blue-500 text-xs">Is Admin</label>
              <select
                name="isAdmin"
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                value={faqData.isAdmin ? "true" : "false"}
                onChange={(e) => setFaqData({ ...faqData, isAdmin: e.target.value === "true" })}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

          </div>
        </div>
        <div className="flex max-sm:flex-col gap-4 !mt-4">
          <button
            type="button"
            className="px-6 py-2 min-w-[150px] w-full rounded text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-6 py-2 min-w-[150px] w-full rounded text-white text-sm font-semibold border-none outline-none bg-blue-500 hover:bg-blue-700"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddFaq;