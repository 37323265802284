import { useState, useEffect, FormEvent } from "react";
import { ImUserCheck } from "react-icons/im";
import { MdOutlineDelete } from "react-icons/md";
import axios from "axios";
import baseURL from "../../config/api";
import { AddLabel } from "./AddLabel"; // Assuming you have the AddLabel component defined

export const UserRoles = () => {
  const [tableData, setTableData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDeleted, setIsDeleted] = useState(0);
  const [isAdded, setAdded] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    fetch(`${baseURL}roles`)
      .then((res) => res.json())
      .then((data) => {
        setIsDataLoaded(true);
        setTableData(data);
      });
  }, [isDeleted]);

  const deletingLinks = async (id: number) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      await axios
        .delete(`${baseURL}roles?id=${id}`)
        .then(() => {
          setIsDeleted(isDeleted + 1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const saveNewRow = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .post(
        `${baseURL}roles`,
        JSON.stringify({
          roleName,
          roleDescription,
          isActive,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        setIsDeleted(isDeleted + 1);
        setAdded(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="p-4">
      <div className="flex items-center justify-start">
        <ImUserCheck className="text-2xl mr-2" />
        <p className="text-lg">Roles</p>
      </div>

      <hr className="my-2" />

      <div className="flex w-full justify-between items-center px-4">
        <div className="w-2/5">Label</div>
        <div className="w-2/5">Handle</div>
        <div className="w-1/5">Active?</div>
        <div className="w-1/12 ml-2 pl-2">
          <MdOutlineDelete className="text-red-500" />
        </div>
      </div>

      <hr className="my-2" />

      {isDataLoaded ? (
        tableData.map((item : any) => (
          <AddLabel
            key={item.id}
            data={item}
            handleDelete={(id) => deletingLinks(id)}
          />
        ))
      ) : (
        <div className="loading">
          <p>Please wait</p>
          <span>
            <i></i>
            <i></i>
          </span>
        </div>
      )}

      {isDataLoaded && (
        <form onSubmit={(e) => saveNewRow(e)}>
          {isAdded && (
            <div className="flex gap-4 items-center justify-center p-4 bg-gray-100">
              <input
                type="text"
                name="label"
                className="w-1/4 text-sm p-1 mr-2"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
                required
              />
              <input
                type="text"
                name="handle"
                className="w-1/4 text-sm p-1 mr-2"
                value={roleDescription}
                onChange={(e) => setRoleDescription(e.target.value)}
                required
              />
              <select
                name="active"
                className="w-1/4 text-sm p-1 mr-2"
                value={isActive ? "Yes" : "No"}
                onChange={(e) =>
                  setIsActive(e.target.value === "Yes" ? true : false)
                }
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {/* <div className="w-1/6"></div> */}
              <button
                onClick={() => {
                  if (
                    window.confirm("Are you sure you want to delete this item?")
                  )
                    setAdded(false);
                }}
              >
                <MdOutlineDelete className="text-red-500" />
              </button>
            </div>
          )}
          <div className="flex items-center justify-start gap-2 p-4">
            <button
              className="bg-blue-500 text-white rounded uppercase text-sm mr-2 hover:bg-blue-600 px-3 py-2"
              onClick={() => setAdded(true)}
            >
              Add Row
            </button>
            <button
              className="bg-blue-500 text-white rounded uppercase text-sm hover:bg-blue-600  px-3 py-2"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      )}
    </div>
  );
};
