import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="bg-gradient-to-r from-blue-400 to-blue-200">
            <div className="w-9/12 m-auto py-8 sm:py-16 min-h-screen flex items-center justify-center">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
                    <div className="border-t border-gray-200 text-center pt-8">
                        <h1 className="text-5xl sm:text-9xl font-bold text-blue-500">404</h1>
                        <h1 className="text-2xl sm:text-6xl font-medium py-4 sm:py-8">oops! Page not found</h1>
                        <p className="text-lg sm:text-2xl pb-4 sm:pb-8 px-4 sm:px-12 font-medium">Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
                        <button className="bg-gradient-to-r from-blue-400 to-blue-300 hover:from-blue-700 hover:to-blue-500 text-white font-semibold px-6 py-3 rounded-md mr-6"
                            onClick={() => navigate("/", { replace: true })}>
                            HOME
                        </button>
                        <button className="bg-gradient-to-r from-red-400 to-red-500 hover:from-red-500 hover:to-red-500 text-white font-semibold px-6 py-3 rounded-md"
                            onClick={() => navigate("/contact-us", { replace: true })}>
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;