
import baseURL from "../../../config/api";
import axios from "axios";
import { RxCross1 } from "react-icons/rx";

type Props = {
  editPopup: boolean;
  hidePopup: () => void;
  deleteCreditCard: () => void;
  particularCreditDetails: any;
};

export default function DeleteCreditCard({
  editPopup,
  hidePopup,
  deleteCreditCard,
  particularCreditDetails,
}: Props) {
  const deleteTheCreditCard = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this Credit Card?"
      ) === true
    ) {
      await axios
        .delete(`${baseURL}creditCard?id=${particularCreditDetails?.id}`)
        .then(() => {
          deleteCreditCard();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <div className={`fixed inset-0 z-10 ${editPopup ? "" : "hidden"}`}>
      <div className="flex items-center justify-center">

        <div className="fixed inset-0 bg-black opacity-50 min-h-screen" onClick={() => hidePopup()}></div>

        
        <div className="bg-[#f8f8f8] z-40 mt-4 p-4 w-full md:max-w-md max-h-96 overflow-auto rounded-md shadow-md">
          
          <div className="flex justify-between items-center mb-4">
            <p className="text-lg font-bold">Credit Card</p>
            <button onClick={() => hidePopup()} className="text-gray-500 hover:text-gray-700">
              <RxCross1 />
            </button>
          </div>

          {/* Content */}
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <p className="text-sm font-semi-bold">Last 4 digits</p>
              <p className="text-sm">....{particularCreditDetails?.creditCardNumber}</p>
            </div>

            <div className="flex justify-between items-center">
              <p className="text-sm font-semi-bold">Expiration Date</p>
              <p className="text-sn">{particularCreditDetails?.expirationDate}</p>
            </div>

            <div className="flex justify-between items-center">
              <p className="text-sm font-semi-bold">Contact</p>
              <p className="text-sm">{particularCreditDetails?.cardholderName}</p>
            </div>

            <div className="flex justify-between items-center">
              <p className="text-sm font-semi-bold">Cardholder's Name</p>
              <p className="text-sm">{particularCreditDetails?.cardholderName}</p>
            </div>

            <div className="flex justify-between items-center">
              <p className="text-sm font-semi-bold">Brand</p>
              <p className="text-sm">{particularCreditDetails?.brand}</p>
            </div>
          </div>

          {/* Footer */}
          <div className="flex justify-between items-center mt-4 gap-4">
            <button className="w-1/2 bg-red-500 text-white py-2 rounded" onClick={() => deleteTheCreditCard()}>
              Delete
            </button>
            <button className="w-1/2 bg-gray-300 text-gray-800 py-2 rounded" onClick={() => hidePopup()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>



  );
}