import { AiOutlineArrowRight } from 'react-icons/ai';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { FaMobileAlt } from 'react-icons/fa';
import { BiMessageRounded } from 'react-icons/bi';
import { BiLogoPlayStore } from 'react-icons/bi';
import { AiFillApple } from 'react-icons/ai';
import { FaRegMoneyBill1 } from 'react-icons/fa6';
import { BsFillJournalBookmarkFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import StartYourFreeTrial from '../../components/StartYourFreeTrial';
import { LuClock3 } from 'react-icons/lu';
import HeroSection from '../../components/HeroSection';

const blogData = [
  {
    id: 1,
    img: '../images/creating-church-directory.png',
    //img src: https://unsplash.com/photos/woman-reading-book-dGxOgeXAXm8
    category: 'Create Your Directory',
    link: '/blog/effortlessly-create-and-maintain-church-directory',
    title: 'Effortlessly Create and Maintain Your Church Directory',
    description: 'Managing a church directory can be a daunting task, but it does not have to be. My Church Directory offers the advantages of a professional church photo directory without the accompanying hassle.',
    publishDate: '19/07/2024',
  },
  {
    id: 2,
    img: '../images/church-community.png',
    //img src: https://unsplash.com/photos/a-church-with-a-chandelier-hanging-from-the-ceiling-Bcvt3N8lnkM
    category: 'Church Community',
    link: '/blog/top-benifits-of-my-church-directory',
    title: 'Top Benefits of Using My Church Directory for Your Church Community',
    description: 'Maintaining an up-to-date church directory is crucial for fostering community and ensuring smooth communication within your congregation. My Church Directory offers a comprehensive solution that provides numerous benefits for your church community.',
    publishDate: '19/07/2024',
  },
  {
    id: 3,
    img: '../images/church-administration.png',
    //img src: Canva
    category: 'Church Directory Administration',
    link: '/blog/simplify-church-administration',
    title: 'Simplify Church Administration with My Church Directory',
    description: 'Managing a church involves numerous administrative tasks that can be time-consuming and complex. From keeping member records updated to communicating effectively with the congregation, church administration requires a streamlined approach to ensure everything runs smoothly.',
    publishDate: '20/07/2024',
  },
];

const Blog = () => {
  const cardsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(blogData.length / cardsPerPage);
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const currentBlogData = blogData.slice(startIndex, endIndex);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <HeroSection
        mainHeading="My Church Directory Blog"
        backgroundImage="./images/blog-hero-photo.jpg"
      // Img Source: https://unsplash.com/photos/two-people-sitting-at-a-table-looking-at-a-tablet-SdlsfstOQZM
      />

      <div className="bg-gray-100 w-full flex flex-col">
        <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
          <div className="mx-auto max-w-screen-xl background grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {currentBlogData.map((item) => (
              <div
                key={item.id}
                className="blog-card bg-white rounded-md p-4 relative group hover:shadow-lg"
              >
                <div className="blog-image-category relative">
                  <img
                    src={item.img}
                    alt={item.title}
                    className="blog-image rounded-md transform transition-transform hover:scale-105"
                  />
                  <p className="absolute bottom-0 left-0 m-3 bg-blue-600 text-white p-1.5 rounded-md text-xs font-bold">
                    {item.category}
                  </p>
                </div>
                <div className="blog-details">
                  <Link to={`${item.link}`} className="blog-title-link">
                    <h2 className="blog-title text-2xl md:text-2xl my-3 font-bold hover:text-blue-500 transition-colors cursor-pointer">
                      {item.title}
                    </h2>
                  </Link>
                  <p className="blog-description mb-3">{item.description}</p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <LuClock3 className="w-4" />
                      <p className="blog-publish-date">{item.publishDate}</p>
                    </div>
                    <Link
                      to={item.link}
                      className="w-12 h-12 p-4 rounded-full bg-transparent flex flex-col justify-center items-center border-2 hover:text-blue-500 hover:border-blue-500"
                      onClick={() => { }}
                    >
                      <AiOutlineArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="body--container w-full px-4 pb-10 md:pb-12 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
          <div className="pagination-buttons max-w-screen-xl flex justify-between px-4 py-2 mx-auto">
            <button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              className="text-blue-500 font-semibold hover:text-blue-700 flex items-center"
            >
              <AiOutlineArrowLeft className="mr-1" /> <span>Previous Page</span>
            </button>
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              className="text-blue-500 font-semibold hover:text-blue-700 flex items-center"
            >
              Next Page <span><AiOutlineArrowRight className="ml-1" /></span>
            </button>
          </div>
        </section>
      </div>

      <section className="py-8 relative">
        <div className="bg-opacity-10 bg-white absolute top-0 left-0 w-full h-full"></div>
        <div
          className="bg-fixed bg-center bg-cover h-64 md:h-96"
          style={{ backgroundImage: 'url("../images/inside-church-blog.jpg")' }}
        >
          <div className="flex items-center justify-center h-full">
            <div className="max-w-screen-sm mx-auto bg-white bg-opacity-80 p-4 md:p-8 text-center flex flex-col items-center">
              <h2 className="text-[#035292] text-center text-2xl md:text-4xl my-4">My Church Directory Quick Tips</h2>
              <p className='text-[#035292] font-semibold text-lg'>1. With the online member directory, you member can now view your church directory from any computer.</p>
              <p className='text-[#035292] font-semibold text-lg  pb-3'>2. My Church Directory offers a 30-day free trial.</p>
              <p className='text-[#035292] font-semibold text-lg  pb-3'>My Church Directory offers monthly and yearly billing</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="max-w-screen-lg mx-auto px-4 py-8">
          <div className="responsive-container">
            <h2 className="text-[#035292] text-center text-2xl md:text-4xl font-bold mb-16">My Church Directory</h2>
            <div className="iframe-container mx-auto">
              <div className="max-w-450px mx-auto">
                <iframe
                  className="w-full"
                  width="100%"
                  height="450px"
                  src="https://www.youtube.com/embed/Gn7FRGhZFTk"
                  title="YouTube Video"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <p className='text-[#035292] text-center text-2xl md:text-4xl my-10'>Create. Share. Connect.</p>
            <p className='text-center'><em>My Church Directory</em> is the simple and fun way to create a Church Directory in just minutes.</p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8 mt-8">
            <div className="flex flex-col items-center w-full mb-8">
              <div><FaMobileAlt className='w-14 h-14 text-[#035292] mb-3' /></div>
              <div className="mt-2 text-center">
                <h3 className="text-[#035292] text-3xl font-semi-bold mb-3">Mobile Apps</h3>
                <p className='mb-3'>Your members can sync your church's directory right to an iPhone, iPad, iPad Mini, or Android device. Members can search for a family or member, and then email, call or text — right from their device. Plus, any change you make to the online directory is immediately synced with their device.</p>
                <div className='flex justify-center gap-4'>
                  <a href="https://play.google.com/" target='__blank'><BiLogoPlayStore className='w-10 h-10 text-blue-700' /></a>
                  <a href="https://www.apple.com" target='__blank'><AiFillApple className='w-10 h-10 text-blue-700' /></a>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center w-full mb-8">
              <div><BiMessageRounded className='w-14 h-14 text-[#035292] mb-3' /></div>
              <div className="mt-2 text-center">
                <h3 className="text-[#035292] text-3xl font-semi-bold mb-3">Email and Text Messages</h3>
                <p className='mb-3'>Send a quick email message from your desktop or mobile device to your entire directory - or selected group(s) within your directory - without having to look up a single email address - For Free! Add a text plan for as little as $3.99 a month.</p>
              </div>
            </div>

            <div className="flex flex-col items-center w-full mb-8">
              <div><FaRegMoneyBill1 className='w-14 h-14 text-[#035292] mb-3' /></div>
              <div className="mt-2 text-center">
                <h3 className="text-[#035292] text-3xl font-semi-bold mb-3">Pricing Made Simple</h3>
                <p className='mb-3'>My Church Directory offers convenient monthly or yearly pricing, with no contracts. There are no pricing tiers, it's the same price no matter what the size of your directory. And your members won't pay a dime to access your directory.</p>
              </div>
            </div>

            <div className="flex flex-col items-center w-full mb-8">
              <div><BsFillJournalBookmarkFill className='w-14 h-14 text-[#035292] mb-3' /></div>
              <div className="mt-2 text-center">
                <h3 className="text-[#035292] text-3xl font-semi-bold mb-3">High-Quality Paper Directory</h3>
                <p className='mb-3'>In addition to our online viewing, we offer many features for a paper directory. You can create high-quality PDFs in Color or Black and White. Additionally, you can print in a variety of page layouts and even insert custom pages.</p>
              </div>
            </div>

          </div>

          <div className="max-w-screen-lg flex justify-center">
            <Link
              to={"/"}
              className="bg-[#035292] text-white font-semibold py-3 px-6 md:py-4 md:px-8 rounded uppercase"
              onClick={() => ("/")}>
              Learn More About My Church Directory
            </Link>
          </div>
        </div>
      </section>

      <StartYourFreeTrial />
    </div>
  )
}

export default Blog;