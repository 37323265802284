import { useEffect, useState } from 'react'
import { useLocation, Outlet, Navigate } from 'react-router-dom';
import useAuth from '../hook/useAuth';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

type Props = {
    allowedRoles: string[];
}

const RequriedAuthForMember = ({ allowedRoles }: Props) => {
    const { setAuth }: any = useAuth();
    const [authIsFound, setAuthIsFound] = useState<boolean>(false);
    const location = useLocation();
    const groupId = Cookies.get("groupId-my-cdir") || "";
    const memberId = Cookies.get("memberId-my-cdir") || "";
    const groupName = Cookies.get("groupName-my-cdir") || "";
    const groupImage = Cookies.get("groupImage-my-cdir") || "";
    const role = Cookies.get("role-my-cdir") || "";
    const token = Cookies.get("token-my-cdir");

    useEffect(() => {
        if (token && !authIsFound) {
            const jsonData: any = jwtDecode(token);
            const id = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
            const roles = jsonData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            const user = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
            const givenname = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"];
            const emailAddress = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
            setAuth({ id, user, givenname, emailAddress, roles, token, groupId, memberId, groupName, groupImage });
            setAuthIsFound(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        token ?
            allowedRoles.some((allowedRole: string) => allowedRole.toLowerCase().includes(role?.toString()?.toLowerCase())) ?
                <Outlet />
                :
                <Navigate to="/not-found" replace={true} />
            :
            <Navigate to="/sign-in" state={{ from: location }} replace />
    );
}

export default RequriedAuthForMember