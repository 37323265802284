import axios from "axios";
import baseURL from "../config/api";

export const upload_CommonFile = (formData: FormData) => {

    return axios({
        url: `${baseURL}commonFile/upload`,
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData
    }).then(response => response.data);
};