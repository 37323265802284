import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hook/useAuth';
import { onChangePhoneNumber } from '../../utils/phoneNumberRegex';
import { upload_CommonFile } from '../../services/commonFileUpload';
import { getMember, put_Member } from '../../services/Member';
import baseURL from '../../config/api';
import FamilyMemberList from './FamilyMemberList';
import DeleteFamilyMember from './DeleteFamilyMember';

const MemberMyAccount = () => {
  const navigate = useNavigate();
  const { auth }: any = useAuth();
  const birthdayRef = useRef<HTMLInputElement>(null);
  const [userData, setUserData] = useState<any>({
    id: 0,
    userName: "",
    password: "",
    familyName: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    birthday: "",
    imagePath: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });
  const [isProcessing, setIsProcessing] = useState<boolean>(false);


  useEffect(() => {
    if (auth?.id) {
      getMember(auth?.memberId)
        .then(res => {
          setUserData({
            ...res,
            birthday: res?.birthday ? new Date(res.birthday).toISOString().split('T')[0] : "",
            imagePath: res?.imagePath ? `${baseURL}commonFile/Get?name=${res.imagePath}` : "./images/user-default.jpg"
          });
        })
        .catch(error => console.error(error));
    }
  }, [auth])

  const inputRef = useRef<HTMLInputElement>(null);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files as FileList;

    if (selectedFiles.length !== 0) {
      const formData = new FormData();
      formData.append("ImageFile", selectedFiles[0]);

      upload_CommonFile(formData)
        .then(res => {
          setUserData({ ...userData, imagePath: `${baseURL}commonFile/Get?name=${res}` });
        })
        .catch(error => {
          console.error(error)
        })
    }
  };

  const closeModal = () => navigate(-1);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isProcessing) {
      setIsProcessing(true);

      put_Member({
        ...userData,
        imagePath: userData?.imagePath?.replace(`${baseURL}commonFile/Get?name=`, "")
      })
        .then(() => {
          setIsProcessing(false);
          navigate(-1);
        })
        .catch(error => {
          setIsProcessing(false);
          console.error(error);
        });
    }
  }

  return (
    <div className="w-full">
      <div className="bg-gradient-to-r from-cyan-300 to-blue-300 w-full h-60" >
        <img
          src={userData.imagePath}
          alt="member_icon" className='w-40 h-40 rounded-full border-[8px] bg-white inline-block z-10 relative top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
          onClick={() => inputRef?.current?.click()} />
        <input
          type="file"
          accept="image/png, image/jpeg"
          name="image-choose"
          ref={inputRef}
          className="hidden"
          onChangeCapture={onFileChangeCapture} />
      </div>
      <div className="-mt-28 mb-6 px-4">
        <div className="mx-auto mb-12 max-w-6xl shadow-lg py-8 px-6 relative bg-white rounded">
          <div className="h-4 md:h-8" />
          <form onSubmit={handleSubmit} className="mt-8 grid sm:grid-cols-2 gap-6 text-blue-500">
            <div className="col-span-2">
              <label className="font-semibold text-sm">Family Name</label>
              <input
                type="text"
                placeholder="Enter Family Name"
                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                value={userData.familyName}
                onChange={(e) => setUserData({ ...userData, familyName: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">First Name</label>
              <input type='text' placeholder='Enter First Name'
                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                value={userData.firstName}
                onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Last Name</label>
              <input type='text' placeholder='Enter Last Name'
                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                value={userData.lastName}
                onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Email</label>
              <input type='email' placeholder='Email'
                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                value={userData.emailAddress}
                onChange={(e) => setUserData({ ...userData, emailAddress: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Phone Number</label>
              <input type='text' placeholder='Phone No.'
                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                pattern="[\(][0-9]{3}[\)][\s]{1}[0-9]{3}[\-]{1}[0-9]{4}"
                value={userData.phoneNumber}
                onChange={(e) => setUserData({ ...userData, phoneNumber: onChangePhoneNumber(e.target.value) })}
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Birthday</label>
              <input ref={birthdayRef} type='date'
                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                value={userData.birthday}
                onChange={(e) => setUserData({ ...userData, birthday: e.target.value })}
                onClick={() => birthdayRef?.current?.showPicker()}
                required
              />
            </div>

            <div>
              <label className="font-semibold text-sm">Street</label>
              <input type='text' placeholder='Street'
                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                value={userData.street}
                onChange={(e) => setUserData({ ...userData, street: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Street 2</label>
              <input type='text' placeholder='Street 2'
                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                value={userData.street2}
                onChange={(e) => setUserData({ ...userData, street2: e.target.value })}
              />
            </div>
            <div>
              <label className="font-semibold text-sm">City</label>
              <input type='text' placeholder='City'
                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                value={userData.city}
                onChange={(e) => setUserData({ ...userData, city: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">State</label>
              <input type='text' placeholder='state'
                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                value={userData.state}
                onChange={(e) => setUserData({ ...userData, state: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Country</label>
              <input type='text' placeholder='Country'
                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                value={userData.country}
                onChange={(e) => setUserData({ ...userData, country: e.target.value })}
                required
              />
            </div>

            <div>
              <label className="font-semibold text-sm">Zip</label>
              <input type='text' placeholder='Zip'
                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                value={userData.zip}
                onChange={(e) => setUserData({ ...userData, zip: e.target.value })}
                required
              />
            </div>
            <div />

            <div className="flex max-sm:flex-col gap-4 !mt-4">
              <button
                type="button"
                className="px-6 py-2 min-w-[150px] rounded text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-6 py-2 min-w-[150px] rounded text-white text-sm font-semibold border-none outline-none bg-blue-500 hover:bg-blue-700"
              >
                {isProcessing ?
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" fill="#fff" className="mr-2 inline animate-spin"
                      viewBox="0 0 26.349 26.35">
                      <circle cx="13.792" cy="3.082" r="3.082" data-original="#000000" />
                      <circle cx="13.792" cy="24.501" r="1.849" data-original="#000000" />
                      <circle cx="6.219" cy="6.218" r="2.774" data-original="#000000" />
                      <circle cx="21.365" cy="21.363" r="1.541" data-original="#000000" />
                      <circle cx="3.082" cy="13.792" r="2.465" data-original="#000000" />
                      <circle cx="24.501" cy="13.791" r="1.232" data-original="#000000" />
                      <path
                        d="M4.694 19.84a2.155 2.155 0 0 0 0 3.05 2.155 2.155 0 0 0 3.05 0 2.155 2.155 0 0 0 0-3.05 2.146 2.146 0 0 0-3.05 0z"
                        data-original="#000000" />
                      <circle cx="21.364" cy="6.218" r=".924" data-original="#000000" />
                    </svg>
                    Processing...
                  </>
                  :
                  "Save"
                }
              </button>
            </div>
          </form>
        </div>
        <FamilyMemberList />
        <DeleteFamilyMember />
      </div>
    </div>
  )
}

export default MemberMyAccount;