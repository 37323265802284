import React, { useEffect, useState } from 'react';
import { HiQuestionMarkCircle } from "react-icons/hi2";

import { getFaqs } from '../../services/Faq';

function AllFaq() {
    const [faqData, setFaqData] = useState<any[]>([]);

    useEffect(() => {
        getFaqs()
            .then(data => setFaqData(data))
            .catch(error => console.error('Error fetching FAQ data:', error));
    }, []);

    return (
        <div className='bg-[#f8f8f8]'>
            <section className="faq-container flex flex-col justify-center w-full h-[200px] bg-no-repeat bg-cover bg-center">
                <h1 className="text-center text-[#1D4ED8] text-5xl font-bold mb-4">FAQ</h1>
                <p className="text-center text-[#1D4ED8] text-sm sm:text-md md:text-xl">
                    See frequently asked questions about our services
                </p>
            </section>

            <div className="grid gap-5 grid-cols-1 md:grid-cols-2 p-8 md:p-10 ">
                {faqData.map((item) => (
                    <div
                        key={item.id}
                        className="bg-[#fff] w-full rounded-md p-5 mb-5 border shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]"
                    >
                        <div className="flex items-start gap-4 mb-1">
                        <p className='pt-1'><HiQuestionMarkCircle className='text-[#1D4ED8] w-6 h-6' style={{ verticalAlign: 'middle' }} /></p>
                        <h3 className="text-[#1D4ED8] text-lg font-semibold ">{item.question}
                        </h3>
                        </div>
                        <p className="text-base ml-10 opacity-70">{item.answer}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AllFaq;
