import { MutableRefObject, useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import { HiArrowNarrowUp } from "react-icons/hi";
import useActiveNavbar from "../hook/useActiveNavbar";

const signIn = [
    {
        name: "Member Sign In",
        link: "/sign-in"
    },
    {
        name: "Admin Sign In",
        link: "/sign-in-admin"
    }
];

const Header = () => {
    const navigate = useNavigate();
    const [navbarHeader, setNavbarHeader] = useState<any[]>([
        {
            name: "Home",
            link: "/",
            isChildVisible: false,
            child: []
        },
        {
            name: "Features",
            link: "",
            isChildVisible: false,
            child: [
                {
                    name: "Church Directory Features",
                    link: "/church-directory-features",
                },
                {
                    name: "Member Mobile App",
                    link: "/mobile-app",
                },
                {
                    name: "Online Member Directory",
                    link: "/online-member-directory",
                },
                {
                    name: "Text and Email Messaging",
                    link: "/text-and-email-messaging",
                },
                {
                    name: "Premium Service",
                    link: "/premium-service",
                }
            ]
        },
        {
            name: "Resources",
            link: "",
            isChildVisible: false,
            child: [
                {
                    name: "Security",
                    link: "/security",
                },
                {
                    name: "Demos",
                    link: "/demos",
                },
                {
                    name: "Blog",
                    link: "/blog",
                },
                {
                    name: "Tools",
                    link: "/tools",
                },
                {
                    name: "Contact Us",
                    link: "/contact-us",
                }
            ]
        },
        {
            name: "FAQ",
            link: "/faq",
            isChildVisible: false,
            child: []
        },
        {
            name: "Pricing",
            link: "/pricing",
            isChildVisible: false,
            child: []
        },
        {
            name: "Bill Pay",
            link: "/bill-pay",
            isChildVisible: false,
            child: []
        }
    ]);
    const { selected, setSelected }: any = useActiveNavbar();
    const [toggle, setToggle] = useState<boolean>(false);
    const [visibleTopBtn, setVisibleTopBtn] = useState<boolean>(false);
    const [signInVisible, setSignInVisible] = useState<boolean>(false);

    const mobileViewRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLButtonElement[]>([]);
    const signRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        const handleDropDown = (e: MouseEvent) => {
            if (!mobileViewRef.current?.contains(e.target as Node)) {
                if (!(dropdownRef.current)[1]?.contains(e.target as Node) && !(dropdownRef.current)[2]?.contains(e.target as Node)) {
                    setNavbarHeader((prevNavbar) =>
                        prevNavbar.map((item) => ({
                            ...item,
                            isChildVisible: false
                        }))
                    )
                }
            }

            if (!(signRef as MutableRefObject<HTMLButtonElement>).current?.contains(e.target as Node)) {
                setSignInVisible(false);
            }
        }

        document.addEventListener("click", handleDropDown);
    }, [])

    const progessBtn = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const toggleVisible = () => {
            const scrolled = document.documentElement.scrollTop;

            if (scrolled > 300) {
                setVisibleTopBtn(true);
            } else if (scrolled <= 300) {
                setVisibleTopBtn(false);
            }


        };

        window.addEventListener('scroll', toggleVisible);

        return () => window.addEventListener('scroll', toggleVisible);
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };



    const location = useLocation();
    const hideHeaderForAdmin = "admin";
    const hideHeaderForMember = "member";
    const hideHeaderForPageNotFound = "not-found";
    const hideHeaderForUnAuthorised = "un-authorized";
    if (location.pathname.replace("/", "").startsWith(hideHeaderForAdmin)
        || location.pathname.replace("/", "").startsWith(hideHeaderForMember)
        || location.pathname.replace("/", "").includes(hideHeaderForPageNotFound)
        || location.pathname.replace("/", "").includes(hideHeaderForUnAuthorised)
    ) {
        return <></>;
    }

    return (
        <>
            <header className="header-container transition-all duration-500 fixed right-0 left-0 z-50 top-0">
                <div className={`header-top bg-[#0081CA] flex justify-between w-full max-w-screen-3xl mx-auto font-semibold h-16 px-6 sm:px-2 lg:px-14 xl:px-26`}>
                    <div className="flex justify-center items-center gap-3 md:gap-4 mr-5 w-1/2 md:w-1/6 py-2">
                        <NavLink to="/" className="z-10">
                            <img className="logo-img h-10 md:h-12" src="../images/logo-no-background.png" alt="logo" />
                        </NavLink>

                    </div>
                    <div className="flex items-center sm:gap-3 md:gap-8 md:mr-10 lg:mr-20">
                        {navbarHeader.map((data, index) => (
                            data.child.length === 0 ?
                                <NavLink
                                    to={data.link}
                                    className="group h-16 flex justify-center flex-col"
                                    key={index}
                                    onClick={() => setSelected(data.link)}
                                >
                                    <span className={`text-center font-semibold text-white cursor-pointer pt-3 md:flex md:items-center hidden`}>
                                        {data.name}
                                    </span>
                                    <div
                                        className={`w-full h-0.5 ${selected === data.link
                                            ? `bg-[#2B3998] opacity-80`
                                            : `group-hover:bg-white group-hover:opacity-80`
                                            }`}
                                    />
                                </NavLink>
                                :
                                <div
                                    key={index}
                                    className="with-child-desktop-view group relative hidden md:flex"
                                >
                                    <button
                                        ref={(e: HTMLButtonElement) => dropdownRef.current[index] = e}
                                        className="flex flex-row justify-center items-center h-16 relative z-10"
                                        onClick={() =>
                                            setNavbarHeader((prevNavbar) =>
                                                prevNavbar.map((item, i) => (
                                                    i === index ? {
                                                        ...item,
                                                        isChildVisible: !item.isChildVisible
                                                    } : {
                                                        ...item,
                                                        isChildVisible: false
                                                    }
                                                ))
                                            )
                                        }>
                                        <div className="group flex flex-col">
                                            <span className={`text-center font-semibold text-white cursor-pointer pt-3 md:flex md:items-center hidden`}>
                                                {data.name}
                                            </span>
                                            <div
                                                className={`w-full h-0.5 ${data.child.some((item: any) => item.link === selected)
                                                    ? `bg-[#2B3998] opacity-80`
                                                    : `group-hover:bg-white group-hover:opacity-80`
                                                    }`}
                                            />
                                        </div>
                                        <IoMdArrowDropdown className="absolute top-[45%] -right-5 text-lg text-white" />
                                    </button>

                                    {data.isChildVisible &&
                                        <div className={`aboutus-container-desktop absolute z-[1000] mt-16 left-0`}>
                                            <ul className={`w-fit list-none overflow-hidden border-[#808080] bg-[#F4F5FA] clip-padding text-left text-base shadow-lg`}>
                                                <li className="block w-full whitespace-nowrap bg-transparent py-4">
                                                    {data.child.map((item: any, itemIndex: number) => (
                                                        <NavLink
                                                            to={item.link}
                                                            key={itemIndex}
                                                            className="hover:bg-gray-200 w-full whitespace-nowrap bg-transparent flex flex-col justify-center items-start gap-0.5 "
                                                            onClick={() => {
                                                                setNavbarHeader((prevNavbar) =>
                                                                    prevNavbar.map((item) => ({
                                                                        ...item,
                                                                        isChildVisible: false
                                                                    }))
                                                                );
                                                                setSelected(item.link);
                                                            }}
                                                        >
                                                            <span className="px-6 py-2 text-sm text-center font-semibold text-black opacity-80 cursor-pointer md:flex md:items-center hidden">
                                                                {item.name}
                                                            </span>
                                                            <div
                                                                className={`w-full h-0.5 ${selected === item.link
                                                                    ? `bg-[#2B3998] opacity-80`
                                                                    : `group-hover:bg-transparent group-hover:opacity-80`
                                                                    }`}
                                                            />

                                                        </NavLink>
                                                    ))}
                                                </li>
                                            </ul>
                                        </div>
                                    }

                                </div>


                        ))}

                        <div className="flex gap-0.5 lg:gap-2">
                            <div className="gap-0.5 lg:gap-2 hidden xl:flex">
                                {signIn.map((item, i) => (
                                    <button
                                        key={i}
                                        className="hidden md:block bg-white px-4 py-2 rounded-md font-bold text-[#2B3998]"
                                        onClick={() => navigate(item.link)}
                                    >
                                        {item.name}
                                    </button>
                                ))}
                            </div>

                            <div className="relative xl:hidden">
                                <button
                                    ref={signRef}
                                    className="hidden md:flex justify-center items-center bg-white px-4 py-2 rounded-md font-bold text-[#2B3998]"
                                    onClick={() => setSignInVisible(!signInVisible)}
                                >
                                    Sign In
                                    <IoMdArrowDropdown className={`text-lg text-[#2B3998] transform duration-300 ${signInVisible ? "-rotate-90" : "rotate-0"}`} />
                                </button>
                                <ul className={`absolute top-12 right-0 w-fit list-none bg-white px-4 py-2 rounded-md font-bold text-[#2B3998] hidden ${signInVisible ? "md:flex" : "hidden"} flex-col`}>
                                    {signIn.map((item, i) => (
                                        <li
                                            key={i}
                                            className="block w-full whitespace-nowrap bg-transparent px-4 py-2 cursor-pointer"
                                            onClick={() => navigate(item.link)}
                                        >
                                            {item.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <button
                                className="hidden 2lg:block bg-blue-700 px-4 py-2 rounded-md font-bold text-white"
                                onClick={() => navigate("/free-trial")}
                            >
                                Free Trial
                            </button>
                        </div>

                        <button
                            className="w-12 h-12 relative focus:outline-none md:hidden"
                            onClick={() => setToggle(!toggle)}
                        >
                            <div className="block w-5 absolute left-6 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                                <span
                                    className={`block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out ${toggle ? "rotate-45" : "-translate-y-1.5"
                                        }  
                `}
                                ></span>
                                <span
                                    className={`block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out ${toggle && "opacity-0"
                                        }`}
                                ></span>
                                <span
                                    className={`block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out ${toggle ? "-rotate-45" : "translate-y-1.5"
                                        }`}
                                ></span>
                            </div>
                        </button>
                    </div>

                    <div
                        ref={mobileViewRef}
                        className={`absolute w-full top-[65px] left-0 right-0 block md:hidden z-20 transform duration-500 ${toggle ? "translate-x-0" : "translate-x-full"
                            }`}
                    >
                        <div
                            className="bg-white p-3 text-start flex flex-col">
                            {navbarHeader.map((data, index) => (
                                data.child.length === 0 ?
                                    <NavLink
                                        to={data.link}
                                        className="group p-2"
                                        key={index}
                                        onClick={() => {
                                            setSelected(data.link);
                                            setToggle(false);
                                        }}>
                                        <span className={`text-md font-bold ${selected === data.link ? "text-[#2B3998]" : "text-black"}`}>{data.name}</span>
                                    </NavLink>
                                    :
                                    <div
                                        className="py-2"
                                        key={index}>
                                        <button
                                            className="group px-2 w-full flex flex-row justify-between"
                                            onClick={() =>
                                                setNavbarHeader((prevNavbar) =>
                                                    prevNavbar.map((item, i) => (
                                                        i === index ? {
                                                            ...item,
                                                            isChildVisible: !item.isChildVisible
                                                        } : {
                                                            ...item,
                                                            isChildVisible: false
                                                        }
                                                    ))
                                                )
                                            }>
                                            <span className={`w-1/2 text-start font-bold ${data.child.some((item: any) => item.link === selected) ? "text-[#2B3998]" : "text-black"}`}>{data.name}</span>

                                            <span className="group text-md font-bold text-slate-500 w-1/2 flex flex-row justify-end gap-0.5"
                                            >
                                                <span className={`block transform duration-1000 ease-in-out ${data.isChildVisible ? "w-4" : "w-1"} h-1 ${data.child.some((item: any) => item.link === selected) ? "bg-[#2B3998]" : "bg-black"} rounded-full`}></span>
                                                <span className={`block transform duration-500 ease-in-out ${data.isChildVisible ? "w-0" : "w-1"} h-1 ${data.child.some((item: any) => item.link === selected) ? "bg-[#2B3998]" : "bg-black"} rounded-full`}></span>
                                                <span className={`block transform duration-500 ease-in-out ${data.isChildVisible ? "w-0" : "w-1"} h-1 ${data.child.some((item: any) => item.link === selected) ? "bg-[#2B3998]" : "bg-black"} rounded-full`}></span>
                                            </span>
                                        </button>

                                        <div
                                            style={{ display: data.isChildVisible ? "flex" : "none" }}
                                            className={`flex flex-col`}>
                                            {data.child.map((item: any, itemIndex: number) => (
                                                <NavLink
                                                    to={item.link}
                                                    className="group p-2"
                                                    key={itemIndex}
                                                    onClick={() => {
                                                        setSelected(item.link);
                                                        setNavbarHeader((prevNavbar) =>
                                                            prevNavbar.map((item) => ({
                                                                ...item,
                                                                isChildVisible: false
                                                            }))
                                                        );
                                                        setToggle(false);
                                                    }}>
                                                    <span className={`text-md ${selected === item.link ? "text-[#2B3998]" : "text-black"}`}>{item.name}</span>
                                                </NavLink>
                                            ))}
                                        </div>
                                    </div>
                            ))}

                            <button
                                className="w-full bg-blue-700 px-4 py-2 rounded-md text-white text-sm font-semibold"
                                onClick={() => {
                                    navigate("/free-trial");
                                    setToggle(false);
                                }}
                            >
                                Free Trial
                            </button>

                            <div className="flex flex-row gap-8">
                                {signIn.map((item, i) => (
                                    <button
                                        key={i}
                                        className="w-full bg-blue-500 px-4 py-2 rounded-md text-white text-sm font-semibold mt-4"
                                        onClick={() => {
                                            navigate(item.link);
                                            setToggle(false);
                                        }}
                                    >
                                        {item.name}
                                    </button>
                                ))}
                            </div>


                        </div>
                        <div className="divider bg-[#2B3998] w-full h-1" />
                    </div>

                </div>

                <div className="divider bg-[#2B3998] w-full h-2 shadow-inner" />

                <button
                    ref={progessBtn}
                    className={`top-btn ${visibleTopBtn ? "flex" : "hidden"} w-12 h-12 px-2 py-2 rounded-full bg-[#2B3998] text-white border-2 border-[#2B3998] flex justify-center items-center fixed bottom-[65px] right-5 shadow-[0_3px_10px_rgb(0,0,0,0.2)] hover:bg-[#0081CA]`}
                    onClick={scrollToTop}>
                    <HiArrowNarrowUp />
                </button>
            </header>

            <div className="divider-view w-full mt-[72px]" />
        </>
    );
}

export default Header; 