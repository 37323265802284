import { MdEdit } from 'react-icons/md'
import DetailsTag from './DetailsTag'
import { useEffect } from 'react';
import { getGroup } from '../../services/Group';
import Cookies from 'js-cookie';

type BillingAddressProps = {
  setShowPopupUpdateBillingAddress: (value: boolean) => void;
  billingAddressData: any;
  setBillingAddressData: (value: any) => void;
}

const BillingAddress = ({ setShowPopupUpdateBillingAddress, billingAddressData, setBillingAddressData }: BillingAddressProps) => {
  const groupId = Cookies.get("groupId-my-cdir");

  useEffect(() => {
    if (groupId) {
      getGroup(groupId)
        .then(res => {
          setBillingAddressData(res)
        })
        .catch(error => {
          console.error(error);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId])

  const editTheBillingAddress = () => {
    setShowPopupUpdateBillingAddress(true);
  }


  return (
    <div className="w-full border border-secondary rounded-md">

      <div className="text-primary bg-secondary px-4 py-2 w-full flex justify-between items-center">
        <h4 className="font-medium text-primary">Billing Address</h4>

        <MdEdit
          className="cursor-pointer"
          onClick={editTheBillingAddress}
        />
      </div>

      <div className="flex flex-col p-4 gap-y-3">
        <DetailsTag
          title="Company Name"
          value={billingAddressData?.name}
        />

        <div className="flex gap-x-4">
          <DetailsTag
            title="Email"
            value={billingAddressData?.emailAddress}
          />

          <DetailsTag
            title="Phone Number"
            value={billingAddressData?.phoneNumber}
          />
        </div>

        <div className="flex gap-x-4">
          <DetailsTag
            title="Street"
            value={billingAddressData?.street}
          />

          <DetailsTag
            title="Street 2"
            value={billingAddressData?.street2}
          />

          <DetailsTag
            title="ZIP"
            value={billingAddressData?.zip}
          />

        </div>

        <div className="flex gap-x-4">
          <DetailsTag
            title="State"
            value={"TX"}
          />

          <DetailsTag
            title="City"
            value={billingAddressData?.city}
          />

          <DetailsTag
            title="Country"
            value={billingAddressData?.country}
          />
        </div>

      </div>
    </div>
  )
}

export default BillingAddress;
