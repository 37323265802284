import { useEffect, useState } from 'react'
import DetailsTag from './DetailsTag'
import { getSubscriptionByGroupId } from '../../services/Subscription'
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import { getPlan } from '../../services/Plans';
import { getCreditCardWithGroupIdAndIsActive } from '../../services/creditCard';

type SubscriptionDetailsProps = {
    setShowPopupUpgradeSubscription: (value: boolean) => void;
    planDetails: any;
    setPlanDetails: (value: any) => void;
    isRefreshSubscriptionDetails: boolean;
    setIsRefreshSubscriptionDetails: (value: boolean) => void;
    setActiveCreditCardDetails: (value: any | null) => void;
}

const SubscriptionDetails = ({ setShowPopupUpgradeSubscription, planDetails, setPlanDetails, isRefreshSubscriptionDetails, setIsRefreshSubscriptionDetails, setActiveCreditCardDetails }: SubscriptionDetailsProps) => {
    const groupId = Cookies.get("groupId-my-cdir");
    const [subscriptionDetails, setSubscriptionDetails] = useState<any>({
        planId: "",
        status: "",
        startDate: "",
        endDate: ""
    });

    useEffect(() => {
        if (groupId && isRefreshSubscriptionDetails) {
            setIsRefreshSubscriptionDetails(false);
            getSubscriptionByGroupId(groupId)
                .then(res => {
                    if (res?.planId) {
                        getPlan(res?.planId)
                            .then(res => {
                                setPlanDetails(res);
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    }

                    setSubscriptionDetails({
                        ...res,
                        startDate: res?.startDate ? format(new Date(res?.startDate), "dd/MM/yyyy") : "",
                        endDate: res?.endDate ? format(new Date(res?.endDate), "dd/MM/yyyy") : ""
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, isRefreshSubscriptionDetails])

    const upgradePlan = () => {
        if (groupId) {
            getCreditCardWithGroupIdAndIsActive(groupId?.toString())
                .then(res => {
                    setActiveCreditCardDetails(res);
                })
                .catch(error => {
                    setActiveCreditCardDetails(null);
                    console.log(error);
                });
        }

        setShowPopupUpgradeSubscription(true);
    }

    const addSupportCredit = () => {

    }

    return (
        <div className="w-full border border-secondary rounded-md bg-primary">
            <h4 className="text-primary font-medium bg-secondary px-4 py-2 w-full">Subscription Details</h4>

            <div className="flex flex-col p-4 gap-y-3">

                <div className="flex gap-x-4">
                    <DetailsTag
                        title="Billing Plan"
                        value={`${planDetails?.planName} $${planDetails?.price ? planDetails?.price?.toFixed(2) : "0.00"}/${planDetails?.billingCycle}`}
                    />

                    <div className="w-1/6">
                        <DetailsTag
                            title="Status"
                            value={subscriptionDetails?.status}
                            bg={"#4ade80"}
                        />

                    </div>
                </div>

                <div className="flex gap-x-4">
                    <DetailsTag
                        title="Start Date"
                        value={subscriptionDetails?.startDate}
                    />


                    <DetailsTag
                        title="Next Invoice Date"
                        value={subscriptionDetails?.endDate}
                    />
                </div>

                <div className="flex gap-x-4">
                    <button
                        className="bg-green-600 rounded-md px-4 py-1.5 font-medium text-white"
                        onClick={upgradePlan}
                    >
                        Upgrade
                    </button>

                    <button
                        className="bg-secondary rounded-md px-4 py-1.5 font-medium text-white"
                        onClick={addSupportCredit}
                    >
                        Add Support Credits
                    </button>
                </div>

            </div>
        </div>
    )
}

export default SubscriptionDetails
