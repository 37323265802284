import StartYourFreeTrial from "../../components/StartYourFreeTrial";
import TryMyCdir from "../../components/TryMyCdir";

const PremiumService = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url('https://images.unsplash.com/photo-1587089879249-87bf7d2972df?q=80&w=1476&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
          backgroundSize: "100% 80vh",
        }}
        className="hero--container flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border md:h-[60vh] pt-[65px]"
      ></section>

      <section className="w-full items-center justify-center px-4">
        <div className="max-w-screen-lg mx-auto py-12">
          <h1 className="text-custom-blue text-3xl md:text-4xl font-[cursive] pt-2 mb-4 text-center">
            Unlock the Exclusive Premium Experience
          </h1>
          <br /><br />
          <p className="text-center md:text-left pb-8">
            Enhance your community engagement by selecting the Premium membership
            for your online membership directory. The advanced customization, collaborative tools,
            and adaptable features offered in the Premium package enrich your directory experience
            in unforeseen ways. It's not merely an upgrade; it's an investment in fostering
            and cultivating your community in unprecedented ways.
          </p>
        </div>
      </section>

      <section className="py-8 px-2 md:px-12 bg-gray-200">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-3/5 p-6">
            <h1 className="text-custom-blue text-3xl md:text-4xl font-semibold mb-5 text-left md:text-left">
              Editors Without Limits
            </h1>
            <p className="text-left pb-2 md:text-left">
              Why confine your community engagement to a limited group? With the Boundless
              Editors feature, you're shattering barriers and embracing a truly collective
              endeavor. Whether your community consists of 50 or 500 individuals, you have
              the liberty to involve anyone in building and managing your directory.
              Moreover, you can provide select editors with increased access to functionalities,
              like sending targeted emails to members and groups using our Broadcast News
              feature. For more details on sending emails, click here.
              <br /><br />
              Invitation for All, Anytime: Incorporate as many editors as needed into your
              directory team—no more managing multiple logins or roles.
              <br /><br />
              Seamless Integration: Adding or removing multiple editors is effortlessly handled,
              allowing swift adjustments to your team as per your current requirements.
              <br /><br />
              Tailored Access: Define distinct roles and permissions for each editor,
              maintaining control while instilling a sense of ownership within your editing team.
            </p>
          </div>

          <div className="w-full md:w-2/5 flex justify-center items-center pb-8">
            <img src="../images/member-mobile.jpg" alt="member-mobile" className="w-96" />
          </div>
        </div>
      </section>
      <TryMyCdir />

      <section className="py-8 px-2 md:px-12">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 flex justify-center items-center">
            <img src="../images/church-dir-features.jpg" alt="church-dir-features" className="w-96" />
          </div>

          <div className="w-full md:w-1/2 p-6">
            <h1 className="text-custom-blue text-3xl md:text-4xl font-semibold mb-5 text-left md:text-left">
              Personalized Colors and Branding
            </h1>
            <p className="text-left pb-2 md:text-left">
              Tailor your membership directory to mirror the uniqueness of your organization.
              Employ our Custom Color and Branding features to transform your directory
              into a digital representation of your community's distinct identity.
              <br /><br />
              Integrate your organization's logo to add a professional touch and reinforce your brand's visibility.
              <br /><br />
              Select your brand's colors to showcase across the member website and mobile apps,
              guaranteeing a uniform and cohesive appearance.
            </p>
          </div>
        </div>
      </section>

      <section className="py-8 px-2 md:px-12 bg-gray-200">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-3/5 p-6">
            <h1 className="text-custom-blue text-3xl md:text-4xl font-semibold mb-5 text-left md:text-left">
              Access More Custom Menu Labels
            </h1>
            <p className="text-left pb-2 md:text-left">
              Adapt to Varied Terms: Our Custom Menu Labels feature empowers you to align
              directory terminology with your organization's language, ensuring seamless
              navigation for your community.
              <br /><br />
              Tailor Titles: Adjust labels such as “Staff Tab” and “Buttons”
              to better match your community's vocabulary.
              <br /><br />
              Cater to Group Diversity: Customize the “Groups & Activities” tabs to
              encompass your organization's array of events and sub-communities.
              <br /><br />
              Personalized Communication: Reconfigure the “Pastor Letter” label to
              align with your leadership's title and role.
              <br /><br />
              Beyond Churches: For non-church communities, effortlessly remove the “Church”
              label to create a more generalized experience.
            </p>
          </div>

          <div className="w-full md:w-2/5 flex justify-center items-center">
            <img src="../images/tell-your-members.jpg" alt="tell-your-members-3" className="w-96" />
          </div>
        </div>
      </section>

      <section className="py-8 px-2 md:px-12">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-3/5 p-6">
            <h1 className="text-custom-blue text-3xl md:text-4xl font-semibold mb-5 text-left md:text-left">
              Acquire Premium Access for 30 Days, FREE!
            </h1>
            <p className="text-left pb-2 md:text-left">
              Opt for a contemporary approach to managing church directories with My Church Directory.
              <br /><br />
              Embark on a free trial for an enhanced online member directory experience. Don't let
              outdated directories hinder your church's progress any longer. My Church Directory
              puts you in the driver's seat.
              <br /><br />
              Our pricing structure is straightforward. No complex plans or binding contracts.
              <br /><br />
              If you find My Church Directory suitable, simply do nothing, and your
              membership will automatically continue after the trial period ends.
              All your data will be securely retained, so there's no need to restart.
              <br /><br />
              There's no intricate tiered pricing based on the number of members,
              and no obligatory annual contracts. Additionally, your members won't
              encounter charges for mobile apps or online access, nor feel pressured to make photo purchases.
            </p>
          </div>

          <div className="w-full md:w-2/5 flex justify-center items-center">
            <img src="../images/broadcast-email.jpg" alt="broadcast-email" className="w-96" />
          </div>
        </div>
      </section>

      <StartYourFreeTrial />
    </>
  );
};

export default PremiumService;
