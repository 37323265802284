import axios from "axios"
import baseURL from "../config/api"

export const post_User = (data: any) => {
    return axios({
        url: `${baseURL}users`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const put_User = (data: any) => {
    return axios({
        url: `${baseURL}users`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const delete_User = (primaryKey: string) => {
    return axios({
        url: `${baseURL}users`,
        method: "DELETE",
        params: { id: primaryKey }
    }).then(response => response.data);
}

export const getUsers = () => {
    return getFetch("users")
}

export const getUser = (primaryKey: string) => {
    return getFetch(`users/${primaryKey}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}