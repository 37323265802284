import StartYourFreeTrial from "../../components/StartYourFreeTrial"
import videoData from "../../videoData"

const Demos = () => {
  return (
    <div>

      <section className="max-w-screen-lg mx-auto px-4 py-8 mt-16">
        <img src="../images/demo-hero-img.png" alt="" className="object-cover w-full h-64 md:h-96" />
        <h1 className="text-[#035292] text-center text-2xl md:text-5xl my-8 pt-5"><strong>View My Church Directory's software demos.</strong></h1>
        <p className='text-justify leading-7 mb-12'>Keeping your members' directory information accurate is in your control, and we will take you through the process. Download and review below a sample directory in PDF format to see what your directory could look.</p>

        <div className="flex flex-wrap -mx-4">
         
          <div className="w-full md:w-1/3 px-4">
            <img src="../images/sample-pdf.jpg" alt="Img by Debby Hudson on Unsplash" className='w-full h-[240px]' />
            <p className="mt-4 text-center text-lg">Download a Sample PDF</p>
          </div>

         
          <div className="w-full md:w-1/3 px-4">
            <iframe
              width="100%"
              height="240"
              src="https://www.youtube.com/embed/Gn7FRGhZFTk"
              title="YouTube Video"
              frameBorder="0"
              allowFullScreen
            ></iframe>
            <p className="mt-4 text-center text-lg">Watch the video and download a PowerPoint presentation to share with your members.</p>
          </div>

         
          <div className="w-full md:w-1/3 px-4">
            <iframe
              width="100%"
              height="240"
              src="https://www.youtube.com/embed/Gn7FRGhZFTk"
              title="YouTube Video"
              frameBorder="0"
              allowFullScreen
            ></iframe>
            <p className="mt-4 text-center text-lg">My Church Directory Overview</p>
          </div>
        </div>




      </section>

      
      <section className="bg-[#f8f8f8] px-4 py-12">
      <div className="max-w-screen-lg mx-auto my-6">
        <h2 className="text-[#2B3998] font-semibold text-4xl mt-6 mb-3">
          See for yourself how <em>My Church Directory</em> works.
        </h2>
        <p className='text-justify leading-7 mb-10'>
          View our library of video demos below to explore and understand the great features offered with our online church directory program.
        </p>

        {videoData.map((section) => (
          <div key={section.id}>
            <h2 className="text-blue-900 text-3xl font-semibold mb-8">{section.title}</h2>
            <div className="flex flex-wrap -mx-4">
              {section.videos.map((video) => (
                <div key={video.id} className="w-full md:w-1/3 px-4 mb-8">
                  <iframe
                    width="100%"
                    height="240"
                    src={video.videoSrc}
                    title="YouTube Video"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                  <p className="mt-4 text-center font-bold text-lg">{video.videoTitle}</p>
                  <p className="mt-2 text-justify">{video.videoDescription}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>


    <StartYourFreeTrial />


    </div>
  )
}

export default Demos