const videoData = [
    {
      id: 1,
      title: 'Getting Started',
      videos: [
        {
          id: 1,
          videoSrc: 'https://www.youtube.com/embed/Gn7FRGhZFTk',
          videoTitle: 'Add or Edit a Family',
          videoDescription: 'We take you through the process to add a family or individual to your directory.',
        },
        {
          id: 2,
          videoSrc: 'https://www.youtube.com/embed/Gn7FRGhZFTk',
          videoTitle: 'Pastor Letter',
          videoDescription: 'Provide members with a letter from the Pastor. Enter name, title, letter copy, photo and signature for a personal touch.',
        },
        {
          id: 3,
          videoSrc: 'https://www.youtube.com/embed/Gn7FRGhZFTk',
          videoTitle: 'Staff',
          videoDescription: 'Enter names, titles and photos of your staff members — with photo captions, if you want. A current staff directory is a must-have for introducing new and existing members to your staff.',
        },
        {
            id: 4,
            videoSrc: 'https://www.youtube.com/embed/Gn7FRGhZFTk',
            videoTitle: 'Photos',
            videoDescription: 'Upload a photo per family or use our import tool to upload multiple photos and assign to family entries. Edit, delete or update photos as often as you need. Request photos or members can submit their own photos for approval, too.',
        },
        {
            id: 5,
            videoSrc: 'https://www.youtube.com/embed/Gn7FRGhZFTk',
            videoTitle: 'Cover Page',
            videoDescription: 'Select any photo and title for the directory cover — or choose your own art or illustration!',
        },
      ],
    },
    {
      id: 2,
      title: 'Customize Your Directory',
      videos: [
        {
          id: 1,
          videoSrc: 'https://www.youtube.com/embed/Gn7FRGhZFTk',
          videoTitle: 'Creating Groups',
          videoDescription: 'Add individuals or families to a group then add a directory just for that group!',
        },
        {
          id: 2,
          videoSrc: 'https://www.youtube.com/embed/Gn7FRGhZFTk',
          videoTitle: 'Activities',
          videoDescription: 'Inform members about the many ministry opportunities available at your church. Use the Activities tab to create attractive displays of various church groups and events.',
        },
        {
          id: 3,
          videoSrc: 'https://www.youtube.com/embed/Gn7FRGhZFTk',
          videoTitle: 'Directory PDF Page Options',
          videoDescription: 'You can create your directory PDF using any or all of our page options and layouts.',
        },
        {
            id: 4,
            videoSrc: 'https://www.youtube.com/embed/Gn7FRGhZFTk',
            videoTitle: 'Members Submit Their Own Updates',
            videoDescription: 'Members can submit suggested updates to their address, photo, email address or phone number right from the online directory for administrator approval.',
        },
        {
            id: 5,
            videoSrc: 'https://www.youtube.com/embed/Gn7FRGhZFTk',
            videoTitle: 'Import or Export Directory Data',
            videoDescription: 'Import basic information via .CSV to jumpstart creating your directory. Export directory data to create mailing labels or mail merge documents.',
        },
      ],
    },
    {
      id: 3,
      title: 'Share Your Directory',
      videos: [
        {
          id: 1,
          videoSrc: 'https://www.youtube.com/embed/Gn7FRGhZFTk',
          videoTitle: 'Generate a PDF to Print',
          videoDescription: 'Before you can download or share your directory or one of your group directories, you must first generate them. You can choose your page options, upload custom pages and update your print options as well as choose your own secure password for your directory PDF.',
        },
        {
          id: 2,
          videoSrc: 'https://www.youtube.com/embed/Gn7FRGhZFTk',
          videoTitle: 'ICD Member Apps',
          videoDescription: 'Our free apps allow members online or mobile access to the directory as the directory syncs to their mobile devices. Administrators enable this feature.',
        },
        {
          id: 3,
          videoSrc: 'https://www.youtube.com/embed/Gn7FRGhZFTk',
          videoTitle: 'Broadcast News and Text Messaging',
          videoDescription: 'Our free emailing service provides quick and easy communication to your members. Our optional text messaging service sends text messages from your administrator account to member\'s mobile devices.',
        },
        {
            id: 4,
            videoSrc: 'https://www.youtube.com/embed/Gn7FRGhZFTk',
            videoTitle: 'Share',
            videoDescription: 'Share your directory with your members using our mobile apps or online member\'s directory website.',
          },
      ],
    },
  ];

  export default videoData;