import { useEffect, useState } from 'react'
import { useLocation, Outlet, Navigate } from 'react-router-dom';
import useAuth from '../hook/useAuth';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

type Props = {
    allowedRoles: string[];
}

const RequriedAuth = ({ allowedRoles }: Props) => {
    const { setAuth }: any = useAuth();
    const [authIsFound, setAuthIsFound] = useState<boolean>(false);
    const location = useLocation();
    const groupId = Cookies.get("groupId-my-cdir") || "";
    const role = Cookies.get("role-my-cdir") || "";
    const token = Cookies.get("token-my-cdir");
    const isAdmin: string = Cookies.get("isAdmin-my-cdir") as string;

    useEffect(() => {
        if (token && !authIsFound) {
            const jsonData: any = jwtDecode(token);
            const id = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
            const roles = jsonData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            const user = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
            const givenname = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"];
            const emailAddress = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
            setAuth({ id, user, givenname, emailAddress, roles, token, groupId });
            setAuthIsFound(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        token ?
            allowedRoles.some((allowedRole: string) => allowedRole.toLowerCase().includes(role?.toString()?.toLowerCase())) ?
                (role?.toString()?.toLowerCase() === "admin" && (location?.pathname.includes("user") || location?.pathname.includes("credit-cards") || location?.pathname.includes("faq") || location?.pathname.includes("payment-gateway") || location?.pathname.includes("addChurch") || location?.pathname.includes("deleteChurch"))) ?
                    <Navigate to="/un-authorized" state={{ from: location }} replace />
                    :
                    (parseInt(isAdmin) === 0 && location?.pathname.includes("billing")) ?
                        <Navigate to="/un-authorized" state={{ from: location }} replace />
                        :
                        <Outlet />
                :
                <Navigate to="/not-found" replace={true} />
            :
            <Navigate to="/sign-in-admin" state={{ from: location }} replace />
    );
}

export default RequriedAuth;