import { useState } from 'react';
import Cross from '../../icons/Cross'
import Loading from '../../icons/Loading';
import { BsFillCreditCard2FrontFill } from 'react-icons/bs';
import { post_CreditCard } from '../../services/creditCard';
import Cookies from 'js-cookie';
import CardNumberValidate from '../../components/CardNumberValidate';
import CvvValidate from '../../components/CvvValidate';
import ExpiryDateValidator from '../../components/ExpiryDateValidator';

type UpgradePlanProps = {
    showPopupForPayment: boolean;
    setShowPopupForPayment: (value: boolean) => void;
    upgradPlan: any;
    isProcessing: boolean;
    setIsProcessing: (value: boolean) => void;
    setIsCreditCardDataSaved: (value: boolean) => void;
    callTheAPIForUpgradeSubscription: (upgradedPlan: any, creditCardDetails: any, cb: any) => void;
    setPaymentType: (value: number) => void;
    setIsRefresh: (value: boolean) => void; 
}

const UpgradePlan = ({ showPopupForPayment, setShowPopupForPayment, upgradPlan, isProcessing, setIsProcessing, setIsCreditCardDataSaved, callTheAPIForUpgradeSubscription, setPaymentType, setIsRefresh }: UpgradePlanProps) => {
    const userId = Cookies.get("userId-my-cdir");
    const groupId = Cookies.get("groupId-my-cdir");
    const [creditCardDetails, setCreditCardDetails] = useState<any>({
        cardholderName: "",
        creditCardNumber: "",
        expirationDate: "",
        ccvCode: ""
    });

    const closeModal = () => {
        setShowPopupForPayment(false);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isProcessing) {
            setIsProcessing(true);

            post_CreditCard({
                ...creditCardDetails,
                isActive: true,
                userId: userId,
                groupId: groupId
            })
                .then(() => {
                    setIsCreditCardDataSaved(true);
                    setPaymentType(0);
                })
                .catch(error => {
                    console.log(error);
                    setIsProcessing(false);
                })

            callTheAPIForUpgradeSubscription(upgradPlan, creditCardDetails, () => {
                setCreditCardDetails({
                    cardholderName: "",
                    creditCardNumber: "",
                    expirationDate: "",
                    ccvCode: ""
                });
                setIsRefresh(true);
                closeModal();
            });
        }
    }

    return (
            <div className={`fixed inset-0 p-4 mt-14 ${showPopupForPayment ? "flex" : "hidden"} flex-wrap justify-center items-center w-full h-fit z-[1000] before:fixed before:inset-0 before:w-full before:min-h-[calc(100vh-72px)] before:bg-[rgba(0,0,0,0.5)] overflow-auto`}>
                <div className="w-full max-w-lg bg-white shadow-lg rounded-md relative">

                    <div className="text-primary bg-secondary px-4 py-2 w-full flex justify-between items-center rounded-tl-md rounded-tr-md">
                        <h4 className="font-medium">Payment</h4>

                        <Cross
                            className="w-3 cursor-pointer shrink-0 fill-white active:fill-blue-500 float-right"
                            closeModal={closeModal}
                        />
                    </div>

                    <hr className="w-full bg-secondary/20 h-0.5" />

                    <div className="w-full px-4 pt-4 pb-8">
                        <form className="mt-2 space-y-4" onSubmit={handleSubmit}>

                            <div className="flex flex-col">
                                <label className="text-sm mb-1">Cardholder Name</label>
                                <input
                                    name="cardholderName"
                                    type="text"
                                    className="bg-transparent w-full text-sm rounded-md border border-secondary pl-2 pr-[40px] py-3 outline-none placeholder:text-[#bbb]"
                                    placeholder="Enter Cardholder Name"
                                    value={creditCardDetails.cardholderName}
                                    onChange={(e) => setCreditCardDetails({ ...creditCardDetails, cardholderName: e.target.value })}
                                    required />
                            </div>

                            <div className="relative flex flex-col">
                                <CardNumberValidate
                                    value={creditCardDetails.creditCardNumber}
                                    setValue={(value) =>
                                        setCreditCardDetails({ ...creditCardDetails, creditCardNumber: value })
                                    }
                                />

                                <BsFillCreditCard2FrontFill className="w-[18px] h-[18px] absolute right-4 items-center top-2/3 transform -translate-y-1/2 text-[#bbbbbb]" />
                            </div>

                            <div className="flex justify-between items-center gap-x-4">
                                <div className="relative flex-1 flex flex-col">
                                    <ExpiryDateValidator
                                        expiryDate={creditCardDetails.expirationDate}
                                        setExpiryDate={(value) =>
                                            setCreditCardDetails({ ...creditCardDetails, expirationDate: value })
                                        }
                                    />
                                </div>

                                <div className="relative flex-1 flex flex-col">
                                    <CvvValidate
                                        value={creditCardDetails.ccvCode}
                                        setValue={(value) =>
                                            setCreditCardDetails({ ...creditCardDetails, ccvCode: value })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="pt-4">
                                <button
                                    type='submit'
                                    className="text-white bg-secondary rounded-md border border-secondary px-2 py-2 w-full text-center"
                                >
                                    {isProcessing ?
                                        <>
                                            <Loading />
                                            Processing...
                                        </>
                                        :
                                        "Pay"
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    )
}

export default UpgradePlan;