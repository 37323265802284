import { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const QuickPay = () => {
    const navigate = useNavigate();
    const [accountNumber, setAccountNumber] = useState<string>("");
    const [required, setRequired] = useState<boolean>(false);

    const handleQuickPay = () => {
        if (accountNumber.trim() !== "") {
            setRequired(false);
            navigate(`/quick-pay/${accountNumber}`);
        } else {
            setRequired(true);
        }
    }

    return (
        <div className='w-full'>
            <div className="max-w-lg w-full mx-auto space-y-2">

                <h3 className="text-3xl font-extrabold">Quick Pay</h3>

                <div className="relative flex flex-col justify-center">
                    <label className="after:content-['*'] after:text-red-400 after:ml-1 after:font-bold">Account Number</label>

                    <input name="email" type="text" required
                        className="bg-transparent w-full text-sm rounded-md border border-secondary px-2 py-3 outline-none placeholder:text-[#bbb]"
                        placeholder="Enter Account Number"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)} />

                    {required && <span className="text-red-400">Required</span>}
                </div>

                <div className='w-full text-end'>
                    <button
                        type='button'
                        className="text-white bg-secondary border border-secondary rounded-md px-6 py-1"
                        onClick={handleQuickPay}
                    >
                        Pay
                    </button>
                </div>
            </div>
        </div >
    )
}

export default QuickPay
