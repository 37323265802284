import React from "react";

interface CardNumberValidateProps {
  value: string;
  setValue: (value: string) => void;
  className?: any;
}

const CardNumberValidate = ({
  value,
  setValue,
  className,
}: CardNumberValidateProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
    const isValid = /^\d{8,19}$/gm.test(value);
    e.target.setCustomValidity(
      isValid ? "" : "Please enetr a valid card number"
    );
  };

  return (
    <>
    <label className="text-sm mb-1">Card Number</label>
    <input
      name="cardnumber"
      type="text"
      className="bg-transparent w-full text-sm rounded-md border border-secondary pl-2 pr-[40px] py-3 outline-none placeholder:text-[#bbb]"
      placeholder="Card number"
      required
      value={value}
      onChange={handleChange}
    />
    </>
  );
};

export default CardNumberValidate;
