import { useState } from 'react';
import Input from '../../components/Input';
import axios from "axios";
import baseURL from '../../config/api';

const ContactUs = () => {
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [question, setQuestion] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const onChangePhoneNumber = (value: string) => {
    const { length } = value;

    const regex = () => value.replace(/[^\d.]+/g, '');

    const areaCode = () => `(${regex().slice(0, 3)})`;

    const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;

    const trailer = (start: number) => `${regex().slice(start, regex().length)}`;
    if (length < 3) {
      setPhone(regex());
    } else if (length >= 3 && length < 10) {
      setPhone(`${areaCode()} ${trailer(3)}`);
    } else if (length === 5) {
      setPhone(`${areaCode().replace(")", "")}`);
    } else if (length >= 10 && length < 15) {
      setPhone(`${firstSix()}-${trailer(6)}`);
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .post(
        `${baseURL}customerEnquiry`,
        JSON.stringify({
          email,
          name,
          question,
          phone,
          created: new Date(),
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        resetForm();
        setSubmitted(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const resetForm = () => {
    setEmail("");
    setName("");
    setPhone("");
    setQuestion("");
  };

  return (
    <div>
      <section className="max-w-screen-lg mx-auto px-4 py-8">
        <img src="../images/contact-us-hero.jpg" alt="" className="object-cover w-full h-64 md:h-96" />
        <h1 className="text-[#035292] text-center text-2xl md:text-5xl my-4 pt-8"><strong>Contact Us</strong></h1>
      </section>

      <section className="w-full pb-10 bg-white">
        <div className="max-w-screen-lg mx-auto py-1">
          <div className="px-5 md:px-1 py-6">

            <p className="text-[#035292] text-base md:text-4xl font-bold mb-2 text-center md:text-center">
            Open a Ticket with Customer Support

            </p>
            <h2 className="text-base md:text-2xl pt-2 mb-10 px-10 text-center md:text-center">
            We try our best to reply within 24-48 business hours.
            </h2>
          </div>
        </div>

        <div className="max-w-screen-lg mx-auto py-1">
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2 lg:w-1/2 px-8 md:px-1 md:pr-2 py-6 text-center border-r lg:border-r-1 border-b md:border-b-0 border-gray-300">
              <h2 className="text-3xl md:text-3xl font-bold pt-2 mb-4">
                USA Spring
              </h2>

              <p className="text-lg font-normal text-gray-700">
                24624 Interstate 45 N Suite 200,<br />
                Spring, TX 77386
              </p>

              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3451.0952715809685!2d-95.44079649999999!3d30.1200872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8647343421fd0135%3A0xa8a51ce8ee769957!2s24624%20Interstate%2045%20N%20Suite%20200%2C%20Spring%2C%20TX%2077386%2C%20USA!5e0!3m2!1sen!2sin!4v1699002913944!5m2!1sen!2sin"
                width="100%"
                height="350"
                style={{ border: 0, padding: "10px" }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div className="md:w-full lg:w-1/2 px-8 md:px-1 md:pr-2 py-6 text-center">
              <h2 className="text-3xl md:text-3xl font-bold pt-2 mb-4">
                USA Sugar Land
              </h2>

              <p className="text-lg font-normal text-gray-700">
                54 Sugar Creek Center Blvd STE 300,<br />
                Sugar Land, TX 77478
              </p>

              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3468.397953022852!2d-95.60810362544842!3d29.62118303850247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640e6ff0ed3930f%3A0x76bf2c10013f2a4b!2s54%20Sugar%20Creek%20Center%20Blvd%20STE%20300%2C%20Sugar%20Land%2C%20TX%2077478%2C%20USA!5e0!3m2!1sen!2sin!4v1699003331966!5m2!1sen!2sin"
                width="100%"
                height="350"
                style={{ border: '0', padding: "10px" }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full items-center justify-center bg-white">
        <div className="max-w-screen-lg mx-auto py-8">
          <div className="text-center pb-10">
            {/* <Link to="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} size="3x" className="p-3" /></Link> */}
            {/* <Link to="https://twitter.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} size="3x" className="p-3" /></Link> */}
            {/* <Link to="https://linkedin.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} size="3x" className="p-3" /></Link> */}
          </div>

          <h1 className="text-[#035292] text-2xl px-5 md:text-4xl font-bold pt-2 mb-4 py-10 text-center md:text-center">Drop us a line. We'd love to hear from you!</h1>
          {submitted && <div className="flex justify-center">
            <div className="text-center">
              <p className="mb-4 px-6 font-bold text-slate-500">Thank you! We'll be in touch soon.</p>
            </div>
          </div>
          }
          <div className="flex justify-center items-center mb-12">

            <form className="flex flex-col gap-4 w-9/12" onSubmit={handleSubmit}>
              <div className="flex flex-col md:flex-row gap-4">

                <Input
                  type="text"
                  label="Name"
                  pattern=""
                  title=""
                  inputValue={name}
                  setInputValue={setName}
                />

                <Input
                  type="email"
                  label="Email Address"
                  pattern=""
                  title=""
                  inputValue={email}
                  setInputValue={setEmail}
                />
              </div>

              <Input
                type="text"
                label="Phone Number"
                pattern="[\(][0-9]{3}[\)][\s]{1}[0-9]{3}[\-]{1}[0-9]{4}"
                title="Please Enter a 10 digits numbers."
                inputValue={phone}
                setInputValue={onChangePhoneNumber}
              />

              <div className="w-full">
                <div className="relative w-full">
                  <textarea
                    name="question"
                    className={`peer h-full w-full rounded-[7px] border ${question && "border-t-transparent"} border-gray-200 bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-gray-200 placeholder-shown:border-t-gray-200 focus:border-2 focus:border-gray-400 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-gray-50`}
                    rows={6} placeholder=" "
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    required
                  />
                  <label
                    htmlFor="question"
                    className="before:content[' '] after:content[' '] text-slate-500 pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-400 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-400 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-gray-500">
                    Question
                  </label>
                </div>
              </div>

              <div className="flex justify-center">
                <button
                  type="submit"
                  name="Send"
                  style={{ border: "none" }}
                  className={`inline-block bg-slate-500 hover:bg-slate-700 border border-white text-white font-semibold py-4 px-8 rounded uppercase`}
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactUs;