type Props = {
    type: string;
    label: string;
    pattern: string;
    title: string;
    inputValue: string;
    setInputValue: (inputValue: string) => void;
    autoComplete?: string;
    options?: { value: string; label: string }[];
}

const Input = ({
    type,
    label,
    pattern,
    title,
    inputValue,
    setInputValue,
    autoComplete,
    options
}: Props) => {

    return (
        <div className="w-full">
            <div className="relative h-12 w-full mb-4">
                {type === 'select' ? (
                    <select
                        name={label}
                        className="peer h-full w-full rounded-[7px] border border-gray-200 bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-gray-700 outline outline-0 transition-all focus:border-2 focus:border-gray-400 focus:outline-0"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        required
                    >
                        {options?.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                ) : (
                    <input
                        type={type}
                        name={label}
                        autoComplete={autoComplete}
                        className={`peer h-full w-full rounded-[7px] border ${inputValue && "border-t-transparent"} border-gray-200 bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-gray-200 placeholder-shown:border-t-gray-200 focus:border-2 focus:border-gray-400 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-gray-50`}
                        placeholder=" "
                        pattern={pattern ? pattern : undefined}
                        title={title}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        required
                    />
                )}
                {type !== 'select' && (
                    <label
                        htmlFor={label}
                        className="before:content[' '] after:content[' '] text-slate-500 pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-400 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-400 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-gray-500">
                        {label}
                    </label>
                )}
            </div>
        </div>
    )
}

export default Input