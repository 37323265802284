import React, { useState } from 'react';

type PasswordValidationProps = {
    password: string;
    setPassword: (password: string) => void;
    isValid: boolean;
    setIsValid: (isValid: boolean) => void;
    className?: any;
    touched: boolean;
    setTouched: (touched: boolean) => void;
};

const PasswordValidation = ({
    password,
    setPassword,
    isValid,
    setIsValid,
    className = {
        label: "w-full text-start block text-blue-500 text-xs",
        div: "w-full text-sm relative",
        input: "w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500 pr-10",
    },
    touched,
    setTouched

}: PasswordValidationProps) => {
    const [passwordTextType, setPasswordTextType] = useState<boolean>(false);
    const [isPasswordFocused, setIsPasswordFocused] = useState<boolean>(false);

    const togglePasswordVisibility = () => {
        setPasswordTextType(!passwordTextType);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPassword(value);
        setTouched(true);

        const isValidPassword =
            value.length >= 8 &&
            /\d/.test(value) &&
            /[a-z]/.test(value) &&
            /[A-Z]/.test(value) &&
            /[!@#$%^&*(),.?":{}|<>]/.test(value);

        setIsValid(isValidPassword);

        if (!isValidPassword && isPasswordFocused) {
            e.target.setCustomValidity("Password must be at least 8 characters long and include at least one lowercase letter (a-z), one uppercase letter (A-Z), one number (0-9), and one special character (!@#$%^&*()).");
        } else {
            e.target.setCustomValidity("");
        }
    };

    return (
        <div className={className.div}>
            <label htmlFor="password" className={className.label}>
                Password
            </label>
            <div className="flex items-center">
                <input
                    name="password"
                    type={passwordTextType ? 'text' : 'password'}
                    required
                    className={className.input}
                    placeholder="Enter Password"
                    value={password}
                    onChange={handleChange}
                    autoComplete="new-password"
                    onFocus={() => setIsPasswordFocused(true)}
                    onBlur={() => setIsPasswordFocused(false)}
                />
                <div className="mb-[18px] cursor-pointer">
                    {passwordTextType ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" className="w-5 h-5 absolute right-4 cursor-pointer" viewBox="0 0 32 32" onClick={togglePasswordVisibility}>
                            <path d="M8.10869891,20.8913011 C4.61720816,18.8301147 3,16 3,16 C3,16 7,9 16,9 C17.3045107,9 18.5039752,9.14706466 19.6014388,9.39856122 L18.7519017,10.2480983 C17.8971484,10.0900546 16.9800929,10 16,10 C8,10 4.19995117,16 4.19995117,16 C4.19995117,16 5.71472808,18.3917225 8.84492713,20.1550729 L8.10869891,20.8913011 L8.10869891,20.8913011 L8.10869891,20.8913011 Z M12.398561,22.601439 C13.4960246,22.8529356 14.6954892,23.0000001 16,23 C25,22.999999 29,16 29,16 C29,16 27.3827918,13.1698856 23.8913008,11.1086992 L23.1550727,11.8449273 C26.2852719,13.6082776 27.8000488,16 27.8000488,16 C27.8000488,16 24,21.999999 16,22 C15.019907,22.0000001 14.1028515,21.9099455 13.2480981,21.7519019 L12.398561,22.601439 L12.398561,22.601439 L12.398561,22.601439 Z M19.8986531,15.1013469 C19.9649658,15.3902115 20,15.6910144 20,16 C20,18.2091391 18.2091391,20 16,20 C15.6910144,20 15.3902115,19.9649658 15.1013469,19.8986531 L16,19 C16.7677669,19.0000001 17.5355339,18.7071068 18.1213203,18.1213203 C18.7071068,17.5355339 19.0000001,16.7677669 19,16 L19.8986531,15.1013469 L19.8986531,15.1013469 L19.8986531,15.1013469 Z M16.8986531,12.1013469 C16.6097885,12.0350342 16.3089856,12 16,12 C13.7908609,12 12,13.7908609 12,16 C12,16.3089856 12.0350342,16.6097885 12.1013469,16.8986531 L13,16 C12.9999999,15.2322331 13.2928932,14.4644661 13.8786797,13.8786797 C14.4644661,13.2928932 15.2322331,12.9999999 16,13 L16.8986531,12.1013469 L16.8986531,12.1013469 L16.8986531,12.1013469 Z M24,7 L7,24 L8,25 L25,8 L24,7 L24,7 Z" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" className="w-5 h-5 absolute right-4 cursor-pointer" viewBox="0 0 128 128" onClick={togglePasswordVisibility}>
                            <path d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z" />
                        </svg>
                    )}
                </div>
            </div>
            {!isValid && touched && (
                <p className="text-red-500 text-xs mt-1">
                    Password must be at least 8 characters long and include at least one lowercase letter (a-z),
                    one uppercase letter (A-Z),
                    one number (0-9),
                    and one special character (!@#$%^&*()).
                </p>
            )}
        </div>
    );
};

export default PasswordValidation;