import { useEffect, useState } from 'react';
import Cross from '../../icons/Cross';
import Loading from '../../icons/Loading';
import { put_Group } from '../../services/Group';
import { onChangePhoneNumber } from '../../utils/phoneNumberRegex';
import EmailValidatedComponent from '../../components/EmailValidatedComponent';

type UpdateBillingAddressProps = {
  showPopupUpdateBillingAddress: boolean;
  setShowPopupUpdateBillingAddress: (value: boolean) => void;
  billingAddressData: any;
  setBillingAddressData: (value: any) => void;
}


const UpdateBillingAddress = ({ showPopupUpdateBillingAddress, setShowPopupUpdateBillingAddress, billingAddressData, setBillingAddressData }: UpdateBillingAddressProps) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [formBillingAddress, setFormBillingAddress] = useState<any>({
    name: "",
    emailAddress: "",
    phoneNumber: "",
    street: "",
    street2: "",
    zip: "",
    state: "",
    city: "",
    country: ""
  });

  useEffect(() => {
    if (showPopupUpdateBillingAddress) {
      setFormBillingAddress(billingAddressData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopupUpdateBillingAddress])

  const closeModal = () => {
    setShowPopupUpdateBillingAddress(false);
    setFormBillingAddress({
      name: "",
      emailAddress: "",
      phoneNumber: "",
      street: "",
      street2: "",
      zip: "",
      state: "",
      city: "",
      country: ""
    });
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isProcessing) {
      setIsProcessing(true);
      put_Group(formBillingAddress)
        .then(() => {
          setIsProcessing(false);
          setBillingAddressData(formBillingAddress);
          setShowPopupUpdateBillingAddress(false)
        })
        .catch(error => {
          console.log(error);
          setIsProcessing(false);
        })
    }
  }


  return (
    <div className={`fixed inset-0 p-4 mt-11 ${showPopupUpdateBillingAddress ? "flex" : "hidden"} flex-wrap justify-center items-center w-full h-fit z-[1000] before:fixed before:inset-0 before:w-full before:min-h-[calc(100vh-72px)] before:bg-[rgba(0,0,0,0.5)] overflow-auto`}>
      <div className="w-full max-w-lg bg-white shadow-lg rounded-md px-5 py-4 relative">
        <Cross closeModal={closeModal} />

        <form onSubmit={handleSubmit}>
          <div className="my-2 text-center">
            <h4 className="text-lg text-secondary font-semibold">Update Biling Address</h4>

            <div className="flex justify-between gap-2 mb-4">
              <div className="w-full text-sm">
                <label htmlFor="companyName" className="w-full text-start block text-secondary text-xs font-medium mb-0.5">Company Name</label>
                <input name="companyName" type="text" required className="w-full border bg-primary border-secondary px-2 py-2 rounded outline-secondary" placeholder="Enter Company Name"
                  value={formBillingAddress.name}
                  onChange={(e) => setFormBillingAddress({ ...formBillingAddress, name: e.target.value })}
                />
              </div>
            </div>

            <div className="flex justify-between gap-2 mb-4">
              <EmailValidatedComponent
                text={formBillingAddress.emailAddress}
                setText={(value) =>
                  setFormBillingAddress({ ...formBillingAddress, emailAddress: value })
                }
                className={{
                  parentDiv: "w-full text-sm",
                  label: "w-full text-start block text-secondary text-xs font-medium mb-0.5",
                  input: "w-full border bg-primary border-secondary px-2 py-2 rounded outline-secondary",
                }}
              />
            </div>

            <div className="flex justify-between gap-2 mb-4">
              <div className="w-full text-sm">
                <label htmlFor="phoneNumber" className="w-full text-start block text-secondary text-xs font-medium mb-0.5">Phone Number</label>
                <input name="phoneNumber" type="text" required className="w-full border bg-primary border-secondary px-2 py-2 rounded outline-secondary" placeholder="Enter Phone Number"
                  value={formBillingAddress.phoneNumber}
                  onChange={(e) => setFormBillingAddress({ ...formBillingAddress, phoneNumber: onChangePhoneNumber(e.target.value) })}
                />
              </div>
            </div>

            <div className="flex justify-between gap-2 my-4">
              <div className="w-full text-sm">
                <label htmlFor="street" className="w-full text-start block text-secondary text-xs font-medium mb-0.5">Street</label>
                <input
                  name="street"
                  type="text"
                  required
                  className="w-full border bg-primary border-secondary px-2 py-2 rounded outline-secondary"
                  placeholder="Enter Street"
                  value={formBillingAddress.street}
                  onChange={(e) => setFormBillingAddress({ ...formBillingAddress, street: e.target.value })}
                />
              </div>

              <div className="w-full text-sm">
                <label htmlFor="street2" className="w-full text-start block text-secondary text-xs font-medium mb-0.5">Street 2</label>
                <input
                  name="street2"
                  type="text"
                  className="w-full border bg-primary border-secondary px-2 py-2 rounded outline-secondary"
                  placeholder="Enter Street2"
                  value={formBillingAddress.street2}
                  onChange={(e) => setFormBillingAddress({ ...formBillingAddress, street2: e.target.value })}
                />
              </div>
            </div>

            <div className="flex justify-between gap-2 my-4">
              <div className="w-full text-sm">
                <label htmlFor="zip" className="w-full text-start block text-secondary text-xs font-medium mb-0.5">ZIP</label>
                <input
                  name="zip"
                  type="text"
                  required
                  className="w-full border bg-primary border-secondary px-2 py-2 rounded outline-secondary"
                  placeholder="Enter ZIP"
                  value={formBillingAddress.zip}
                  onChange={(e) => setFormBillingAddress({ ...formBillingAddress, zip: e.target.value })}
                />
              </div>

              <div className="w-full text-sm">
                <label htmlFor="state" className="w-full text-start block text-secondary text-xs font-medium mb-0.5">State</label>
                <input
                  name="state"
                  type="text"
                  required
                  className="w-full border bg-primary border-secondary px-2 py-2 rounded outline-secondary"
                  placeholder="Enter State"
                  value={formBillingAddress.state}
                  onChange={(e) => setFormBillingAddress({ ...formBillingAddress, state: e.target.value })}
                />
              </div>

            </div>

            <div className="flex justify-between gap-2 my-4">
              <div className="w-full text-sm">
                <label htmlFor="city" className="w-full text-start block text-secondary text-xs font-medium mb-0.5">City</label>
                <input
                  name="city"
                  type="text"
                  required
                  className="w-full border bg-primary border-secondary px-2 py-2 rounded outline-secondary"
                  placeholder="Enter City"
                  value={formBillingAddress.city}
                  onChange={(e) => setFormBillingAddress({ ...formBillingAddress, city: e.target.value })}
                />
              </div>

              <div className="w-full text-sm">
                <label htmlFor="country" className="w-full text-start block text-secondary text-xs font-medium mb-0.5">Country</label>
                <input
                  name="country"
                  type="text"
                  required
                  className="w-full border bg-primary border-secondary px-2 py-2 rounded outline-secondary"
                  placeholder="Enter Country"
                  value={formBillingAddress.country}
                  onChange={(e) => setFormBillingAddress({ ...formBillingAddress, country: e.target.value })}
                />
              </div>

            </div>

          </div>

          <div className="flex max-sm:flex-col gap-4 !mt-4">
            <button
              type="button"
              className="px-6 py-2 min-w-[150px] w-full rounded text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-6 py-2 min-w-[150px] w-full rounded text-white text-sm font-semibold border-none outline-none bg-secondary"
            >
              {isProcessing ?
                <>
                  <Loading />
                  Processing...
                </>
                :
                "Save"
              }
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UpdateBillingAddress
