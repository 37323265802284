import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();
    const location = useLocation();

    useEffect(() => {
        if (!location.pathname.replace("/", "").includes("delete")) {
            window.scrollTo(0, 0);
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return null;
}