import { useState, useEffect } from 'react';
import { MdQuestionAnswer } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { RxCross1 } from "react-icons/rx";
import baseURL from '../../config/api';
import Pagination from '../../components/Pagination';


const Faq = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [searchTableData, setSearchTableData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [displayFilter, setDisplayFilter] = useState<boolean>(false);
  const [faqFilterValue, setFaqFilterValue] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [paginationModel, setPaginationModel] = useState<{ page: number, size: number }>({
    page: 0,
    size: 5,
  });
  const [totalLength, setTotalLength] = useState<number>(0);
  const [selectedFilter, setSelectedFilter] = useState<string>("Question");
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  const [showEntries, setShowEntries] = useState<number>(5);


  useEffect(() => {
    if (searchValue === "" && !isFirstTime) {
      const newPaginationModel = {
        page: 0,
        size: paginationModel.size,
      };

      setPaginationModel(newPaginationModel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      setIsFirstTime(false)
      apiCall(paginationModel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel])

  const apiCall = (newPaginationModel: { page: number, size: number }) => {
    setIsDataLoaded(true);
    fetch(`${baseURL}faq/GetFaqsPaging/${newPaginationModel.page},${newPaginationModel.size}`)
      .then((res) => res.json())
      .then((data) => {
        const result = data.faqs;

        setSearchTableData(result);
        setTotalLength(data.count);
        setIsDataLoaded(false);

      }).catch((error) => {
        console.log(error);
        setIsDataLoaded(false);
        setSearchTableData([]);
        setTotalLength(0);
      });
  }


  useEffect(() => {
    let timeOutId: NodeJS.Timeout;

    if (searchValue !== "") {
      timeOutId = setTimeout(() => {
        const newPaginationModel = {
          page: 0,
          size: paginationModel.size,
        };

        setPaginationModel(newPaginationModel);

      }, 1000);
    }

    return () => clearTimeout(timeOutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue !== "") {
      filteApiCall(paginationModel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel])

  const filteApiCall = (newPaginationModel: { page: number, size: number }) => {
    let filterData: any[] = [];
    setIsDataLoaded(true);
    switch (faqFilterValue) {
      case 0:
        fetch(
          `${baseURL}faq/GetFaqsSearchWithQuestion/${searchValue},${newPaginationModel.page},${newPaginationModel.size}`
        )
          .then((res) => res.json())
          .then((data) => {
            filterData = data.faqs;
            setSearchTableData(filterData);
            setTotalLength(data.count);
            setIsDataLoaded(false);

          })
          .catch((error) => {
            console.log(error);
            setIsDataLoaded(false);
            setSearchTableData([]);
            setTotalLength(0);
          });

        break;
      case 1:
        fetch(
          `${baseURL}faq/GetFaqsSearchWithAnswer/${searchValue},${newPaginationModel.page},${newPaginationModel.size}`
        )
          .then((res) => res.json())
          .then((data) => {
            filterData = data.faqs;
            setSearchTableData(filterData);
            setTotalLength(data.count);
            setIsDataLoaded(false);

          })
          .catch((error) => {
            console.log(error);
            setIsDataLoaded(false);
            setSearchTableData([]);
            setTotalLength(0);
          });

        break;

    }
  }

  const applyFilter = () => {
    setSelectedFilter(() =>
      faqFilterValue === 0 ? "Question" : "Answer"
    );
    setSearchValue("");
    setDisplayFilter(false);
  };

  const clearFilter = () => {
    setSearchValue("");
    setDisplayFilter(false);
  };



  const addFaq = () => {
    navigate('/admin/faq/addFaq');
  };

  const deleteFaq = (id: string) => {
    navigate(`/admin/faq/deleteFaq/${id}`);
  };

  const openModal = (item: any) => {
    setSelectedItem(item);
  };

  const closeModal = () => {
    setSelectedItem(null);
  };

  const editFaq = (id: string) => {
    navigate(`/admin/faq/editFaq/${id}`);
  };

  return (
    <section className="user__container overflow-x-auto">
      <div className="min-w-full flex flex-row justify-between item-center pb-8 ">
        <h1 className="px-4 py-1.5 flex items-center gap-2">
          <MdQuestionAnswer fontSize={20} />
          <span className="text-lg">FAQ</span>
        </h1>
        <button
          className="bg-blue-500 hover:bg-blue-700 px-4 py-2 rounded text-white"
          onClick={addFaq}
        >
          Add FAQ
        </button>
      </div>
      <hr />
      <div className=" pt-8">

        <div className="max-w-screen-sm mx-auto rounded-full flex space-x-3 justify-start items-center border rounded-pill py-3 pl-8 shadow-md">
          <div className="relative">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded-md"
              onClick={() => setDisplayFilter(!displayFilter)}
            >
              {selectedFilter}
            </button>
            <div
              className={`${displayFilter ? 'opacity-100 block'
                : 'opacity-0 hidden'
                } absolute w-40 ml-1 p-2 bg-white border transition-opacity duration-300 ease-in border-black top-0 left-full z-10`}
            >
              <div className="flex justify-between">
                <label className="text-xs font-bold mb-3" htmlFor="fields">
                  Fields
                  <br />
                </label>
                <div
                  onClick={() => setDisplayFilter(false)}
                  className="w-4 h-4 cursor-pointer text-center items-center"
                ><RxCross1 /></div>
              </div>
              <select
                className="w-full text-xs py-1 border mt-1 mb-1"
                name="fields"
                value={faqFilterValue}
                onChange={(e) => setFaqFilterValue(parseInt(e.target.value))}
              >
                <option value="0">Question</option>
                <option value="1">Answer</option>
              </select>
              <div className='flex mt-2 justify-between whitespace-nowrap'>
                <button
                  className="text-xs bg-blue-500 text-white py-1 px-2 rounded-md mt-3 hover:bg-blue-600 focus:outline-none"
                  onClick={() => applyFilter()}
                >
                  Apply Filter
                </button>

                <button
                  className="text-xs bg-blue-500 text-white py-1 px-2 rounded-md mt-3 hover:bg-blue-600 focus:outline-none"
                  onClick={() => clearFilter()}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="ml-auto">
            <div className="flex items-center gap-2 w-full">
              <AiOutlineSearch className="text-gray-500 cursor-pointer" />
              <input
                type="text"
                placeholder="Search…"
                className="py-1 text-sm pl-2 focus:outline-none w-full"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
        </div>


      </div>
      {!isDataLoaded ?
        <>
          <div className="overflow-x-auto pt-8">
            <table className="min-w-full bg-white">
              <thead className="bg-gray-200 whitespace-nowrap">
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-black">#</th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-black">Question</th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-black">Answer</th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-black">IsAdmin?</th>

                  <th className="px-6 py-3 text-left text-sm font-semibold text-black">Actions</th>
                </tr>
              </thead>
              <tbody className="whitespace-nowrap">
                {searchTableData.length > 0 ? (
                  searchTableData.slice(0, showEntries).map((item, index) => {
                    return (
                      <tr
                        key={item.id}
                        className={index % 2 === 0 ? 'bg-blue-50' : 'bg-blue-100'}

                      >
                        <td className="px-6 py-4 text-sm"
                          onClick={() => openModal(item)}
                          style={{ cursor: 'pointer' }}>
                          <span className="text-blue-500 underline">{(paginationModel.page * showEntries) + index + 1}</span>
                        </td>
                        <td className="px-6 py-4 text-sm">
                          {item.question.length > 20 ? `${item.question.slice(0, 40)}...` : item.question}
                        </td>
                        <td className="px-6 py-4 text-sm">
                          {item.answer.length > 20 ? `${item.answer.slice(0, 40)}...` : item.answer}
                        </td>
                        <td className="px-6 py-4 text-sm">{item.isAdmin ? "Yes" : "No"}</td>
                        <td className="px-6 py-4 text-sm">
                          <button className="mr-4" title="Edit" onClick={() => editFaq(item.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 fill-blue-500 hover:fill-blue-700"
                              viewBox="0 0 348.882 348.882">
                              <path
                                d="m333.988 11.758-.42-.383A43.363 43.363 0 0 0 304.258 0a43.579 43.579 0 0 0-32.104 14.153L116.803 184.231a14.993 14.993 0 0 0-3.154 5.37l-18.267 54.762c-2.112 6.331-1.052 13.333 2.835 18.729 3.918 5.438 10.23 8.685 16.886 8.685h.001c2.879 0 5.693-.592 8.362-1.76l52.89-23.138a14.985 14.985 0 0 0 5.063-3.626L336.771 73.176c16.166-17.697 14.919-45.247-2.783-61.418zM130.381 234.247l10.719-32.134.904-.99 20.316 18.556-.904.99-31.035 13.578zm184.24-181.304L182.553 197.53l-20.316-18.556L294.305 34.386c2.583-2.828 6.118-4.386 9.954-4.386 3.365 0 6.588 1.252 9.082 3.53l.419.383c5.484 5.009 5.87 13.546.861 19.03z"
                                data-original="#000000" />
                              <path
                                d="M303.85 138.388c-8.284 0-15 6.716-15 15v127.347c0 21.034-17.113 38.147-38.147 38.147H68.904c-21.035 0-38.147-17.113-38.147-38.147V100.413c0-21.034 17.113-38.147 38.147-38.147h131.587c8.284 0 15-6.716 15-15s-6.716-15-15-15H68.904C31.327 32.266.757 62.837.757 100.413v180.321c0 37.576 30.571 68.147 68.147 68.147h181.798c37.576 0 68.147-30.571 68.147-68.147V153.388c.001-8.284-6.715-15-14.999-15z"
                                data-original="#000000" />
                            </svg>
                          </button>
                          <button className="mr-4" title="Delete" onClick={() => deleteFaq(item.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 fill-red-500 hover:fill-red-700"
                              viewBox="0 0 24 24">
                              <path
                                d="M19 7a1 1 0 0 0-1 1v11.191A1.92 1.92 0 0 1 15.99 21H8.01A1.92 1.92 0 0 1 6 19.191V8a1 1 0 0 0-2 0v11.191A3.918 3.918 0 0 0 8.01 23h7.98A3.918 3.918 0 0 0 20 19.191V8a1 1 0 0 0-1-1Zm1-3h-4V2a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v2H4a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM10 4V3h4v1Z"
                                data-original="#000000" />
                              <path d="M11 17v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Zm4 0v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Z"
                                data-original="#000000" />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    )
                  })
                )
                  : (
                    <tr>
                      <td colSpan={7} className="border border-gray-300 px-2 py-1 text-sm font-medium">No data available</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>

          {
            totalLength > 0 && (
              <Pagination
                pageOption={[5, 10, 15, 20, 25, 50, 75, 100]}
                totalLength={totalLength}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                showEntries={showEntries}
                setShowEntries={setShowEntries}
              />
            )
          }
        </>
        :
        (<div id="loading-colors" className="min-w-full h-[480px] flex flex-col flex-1 justify-center items-center">
          <div className="flex flex-col items-center">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite] text-blue-500"
              role="status"></div>
            <span className="text-black">Loading...</span>
          </div>
        </div>)
      }
      {selectedItem && (
        <div className="fixed inset-0 p-4 mt-14 flex flex-wrap justify-center items-center w-full h-fit z-[1000] before:fixed before:inset-0 before:w-full before:min-h-[calc(100vh-72px)] before:bg-[rgba(0,0,0,0.5)] overflow-auto">
          <div className="w-full max-w-2xl bg-white shadow-lg rounded-md px-5 py-4 relative">
            <h4 className="text-xl text-center text-blue-500 font-semibold mb-4">FAQ Details</h4>
            <div className="mb-4">
              <p className="text-base font-semibold mb-1">Question:</p>
              <p className="text-sm">{selectedItem.question}</p>
            </div>
            <div className="mb-4">
              <p className="text-base font-semibold mb-1">Answer:</p>
              <p className="text-sm">{selectedItem.answer}</p>
            </div>
            <div className="mb-4">
              <p className="text-base font-semibold mb-2">Is Admin:</p>
              <p className="text-sm">{selectedItem.isAdmin ? 'Yes' : 'No'}</p>
            </div>
            <button
              className="px-6 py-2 min-w-[150px] w-full rounded text-white text-sm font-semibold border-none outline-none bg-blue-500 hover:bg-blue-700"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Faq;
