import Cookies from 'js-cookie';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CreditCardView from './CreditCardView';

const BillPayDetails = () => {
  const navigate = useNavigate();
  const isAdmin = Cookies.get("isAdmin-my-cdir");
  const [amountDue, setAmountDue] = useState<number>(10.10);

  useEffect(() => {
    if (isAdmin === undefined || parseInt(isAdmin) === 0) {
      navigate(`/bill-pay`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin])

  useEffect(() => {
    setAmountDue(10.10);
  }, [])

  return (
    <div className="min-h-[calc(100vh-60px)] py-10 px-4 bg-bill">
      <h4 className="font-medium text-xl bg-white border border-secondary rounded-md w-fit px-3 py-2">
        <span>Account: </span>
        <span className="underline">37855445 -  Address</span>
      </h4>

      {/* Bill Details Container */}
      <div className="flex flex-col justify-center bg-white border border-secondary w-2/6 mx-auto px-6 py-4 rounded-md mt-4">
        <h3 className="font-bold text-3xl">My Account</h3>

        <p className="mt-6 flex justify-between items-center">
          <span className="">Due Data: 08/26/2025</span>
          <span className="cursor-pointer underline text-blue-500">View Bill</span>
        </p>

        <p className="mt-4 flex justify-between items-center">
          <span className="">Past</span>
          <span className="">$0.00</span>
        </p>

        <p className="mt-4 flex justify-between items-center">
          <span className="">Current Charges Due</span>
          <span className="">$0.00</span>
        </p>

        <p className="mt-4 flex justify-between items-center">
          <span className="font-bold text-xl">Amount Due</span>
          <span className="">${amountDue?.toFixed(2)}</span>
        </p>

        {amountDue <= 0 ?
          (
            <p className="mt-4 text-sm">
              Nothing is due. No payment is neccessary at this time
            </p>
          ) : (
            <CreditCardView amountDue={amountDue} />
          )
        }
      </div>
    </div>




  )
}

export default BillPayDetails
