import axios from "axios"
import baseURL from "../config/api"

export const getCountries = () => {
    return getFetch("country")
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}