import { Link } from 'react-router-dom'

const FreeTrialTag = () => {
    return (
        <div className="w-full px-8 py-4 bg-[#2B3998] flex flex-col md:flex-row gap-4 items-center">
            <p className="text-center md:text-start text-white font-semibold text-3xl flex-1">Try My Church Directory for 30 days FREE.</p>
            <span className="text-center">
                <Link to="/free-trial" className="bg-blue-700 text-white py-2 px-4 rounded-sm font-bold">Start FREE Trial</Link>
            </span>
        </div>
    )
}

export default FreeTrialTag