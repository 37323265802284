import { AllCreditCards } from './AllCreditCards';
import { FaRegCreditCard } from "react-icons/fa";

export const CreditCards = () => {

  return (
    <div>
      <div className="w-full flex justify-between">
        <div className="flex flex-row items-center">
          <h1 className="px-4 py-1.5 flex items-center gap-2">
            <FaRegCreditCard fontSize={20} />
            <span className="text-lg">Credit Cards</span>
          </h1>
        </div>
      </div>

      <div>
        <AllCreditCards />
      </div>
    </div>
  );
};
