import { useEffect, useState } from 'react'
import { FaRegFilePdf } from 'react-icons/fa';
import { getInvoicesByGroupId } from '../../services/Invoice';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import Pagination from '../../components/Pagination';

const heading = ["#", "Issued On", "Total Amount", "Status"];

const Invoice = () => {
    const groupId = Cookies.get("groupId-my-cdir");
    const [creditCardData, setCreditCardData] = useState<any[]>([]);
    const [paginationModel, setPaginationModel] = useState<{ page: number, size: number }>({
        page: 0,
        size: 5
    });
    const [totalLength, setTotalLength] = useState<number>(0);
    const [showEntries, setShowEntries] = useState<number>(5);

    useEffect(() => {
        if (groupId) {
            getInvoicesByGroupId(groupId, paginationModel.page, paginationModel.size)
                .then(res => {
                    setTotalLength(res?.count)
                    const data = res?.invoices?.map((item: any) => ({
                        ...item,
                        invoiceDate: item?.invoiceDate ? format(new Date(item?.invoiceDate), "dd/MM/yyyy") : "",
                        totalAmount: item?.amountDue ?? 0 + item?.amountPaid ?? 0,
                    }))
                    setCreditCardData(data);
                })
                .catch(error => {
                    console.error(error);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, paginationModel])


    return (
        <div className="w-full border border-secondary rounded-md bg-primary">
            <h4 className="text-primary font-medium bg-secondary px-4 py-2 w-full">Invoices</h4>

            <div className="flex flex-col p-4 gap-y-3">

                <table>
                    <thead className="border-b-2 border-secondary">
                        <tr className="text-sm text-start">
                            {heading.map((item, index) => (
                                <th key={index} className="border-r border-secondary text-start px-0.5">
                                    {item}
                                </th>
                            ))}
                            <th className="text-start px-0.5 !pl-1.5">Actions</th>
                        </tr>
                    </thead>

                    <tbody className="border-b border-secondary">
                        {creditCardData?.map((item: any, index: number) => (
                            <tr
                                key={index}
                                className="text-sm text-start"
                            >
                                <td className="border-r border-b border-secondary text-start px-0.5 py-1.5">{item?.id}</td>
                                <td className="border-r border-b border-secondary text-start px-0.5 py-1.5">{item?.invoiceDate}</td>
                                <td className="border-r border-b border-secondary text-end px-0.5 py-1.5">${item?.totalAmount === null ? "0.00" : (parseFloat(item?.totalAmount))?.toFixed(2)}</td>
                                <td className="border-r border-b border-secondary text-start px-1.5 py-1.5">
                                    <span
                                        style={{
                                            backgroundColor: "#4ade80"
                                        }}
                                        className="px-1.5 py-[1px] w-fit rounded text-white"
                                    >
                                        {item?.status}
                                    </span>
                                </td>
                                <td className="text-start border-b border-secondary px-0.5 !pl-1.5 py-1.5 h active:text-blue-700 font-bold cursor-pointer select-none">
                                    <span className="cursor-pointer text-blue-400 active:text-blue-700 text-sm flex items-center">
                                        <FaRegFilePdf />
                                        <span>PDF</span>
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Pagination
                    totalLength={totalLength}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    showEntries={showEntries} 
                    setShowEntries={setShowEntries}
                />

            </div>

        </div>
    )
}

export default Invoice
