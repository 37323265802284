const heading = ["#", "Subject", "Unused"];

const Support = () => {
  return (
    <div className="w-full border border-secondary rounded-md bg-primary">
            <h4 className="text-primary font-medium bg-secondary px-4 py-2 w-full">Support</h4>

            <div className="flex flex-col p-4 gap-y-3">

                <table>
                    <thead className="border-b-2 border-secondary">
                        <tr className="text-sm text-start">
                            {heading.map((item, index) => (
                                <th key={index} className="border-r border-secondary text-start px-0.5">
                                    {item}
                                </th>
                            ))}
                            <th className="text-start px-0.5 !pl-1.5 text-blue-500 font-bold select-none"></th>
                        </tr>
                    </thead>

                    <tbody className="border-b border-secondary">
                    </tbody>
                </table>

            </div>

        </div>
  )
}

export default Support
