import React from 'react'
import FreeTrialTag from '../../components/FreeTrialTag'
import StartYourFreeTrial from '../../components/StartYourFreeTrial'



const Tools = () => {
  return (
    <div>
      <section className="max-w-screen-lg mx-auto px-4 py-8 mt-16">
        <img src="../images/tool-hero-img.png" alt="Img by airfocus on Unsplash" className="object-cover w-full h-64 md:h-96" />
        <h1 className="text-[#035292] text-center text-2xl md:text-5xl my-8 pt-5"><strong>Teach your church about My Church Directory.</strong></h1>

        <h2 className="text-[#035292] text-2xl md:text-4xl my-8 pt-10">We have the tools to help you communicate to your church.</h2>
        <p className='text-justify leading-7 mb-12'>We have created a collection of printouts and templates to help you present <em>My Church Directory</em> to your decision-makers and members. Getting your members familiar with the directory once it's up and running is an important part of the process that is often overlooked. These documents will help ensure that your congregation knows what the directory offers and how to access the free member apps.</p>

      </section>


      <section className="bg-[#f8f8f8] relative px-4 py-8">
        <div className="max-w-screen-lg mx-auto">
          <h2 className="text-[#035292] font-semibold text-4xl my-8 ">Find tools and resources for decision-makers.</h2>

          <div className="flex flex-wrap mb-6">
            {/* Left Child Div */}
            <div className="w-full md:w-1/2 pr-6"> {/* Added pr-4 for right margin */}
              <p className='text-justify mb-6'>If you are the decision-maker for your church and want to know more about <em>My Church Directory</em> or would like to share information with your directory committee or colleagues, please review the information at the links below. Don't forget, we offer a 30-day free trial, too to test drive the program and see if it's right for you!</p>

              <h2 className='text-[#035292] text-2xl md:text-3xl mb-2'>Facts</h2>
              <a href="_#" className="hover:underline"><p className='mb-3'>Simple Facts and Stats about My Church Directory</p></a>

              <h2 className='text-[#035292] text-2xl md:text-3xl mb-2'>Security</h2>
              <a href="_#" className="hover:underline"><p className='mb-3'>Security Information for peace of mind</p></a>

              <h2 className='text-[#035292] text-2xl md:text-3xl mb-2'>Committee Presentation</h2>
              <a href="_#" className="hover:underline"><p className='mb-3'>PowerPoint Presentation about <em>My Church Directory</em></p></a>
            </div>

            {/* Right Child Div */}
            <div className="w-full md:w-1/2">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/Gn7FRGhZFTk"
                title="YouTube Video"
                frameBorder="0"
                allowFullScreen
              ></iframe>
              <h2 className='text-[#035292] text-center text-2xl md:text-3xl mb-2'>A message from our creative director.
              </h2>
            </div>
          </div>
        </div>
        <div className="md:absolute left-0 right-0 -bottom-8 w-full md:px-12 lg:px-16 xl:px-20 2xl:px-24">
          <FreeTrialTag />
        </div>
      </section>

      <section className="bg-[#fff] px-4 py-12">
        <div className="max-w-screen-lg mx-auto my-6">
          <h2 className="text-[#035292] font-semibold text-4xl my-4 pt-8 ">Download our convenient files to engage your members.</h2>
          <p className='text-justify mb-12'>When you are ready to announce your new directory to your church, we have a full list of resources and materials below that will help you communicate all the information you need to share with your members. Include these announcements in newsletters or bulletins, email or post them on flyers or on your church's website—wherever you can best reach your members.</p>

          <h2 className="text-[#2B3998] font-semibold text-3xl my-6 pb-4">Templates</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-center">
  {/* First Item */}
  <div className="mb-8">
    <h3 className="text-blue-900 font-[cursive] text-xl mb-4">Announcement Flyers</h3>
    <img
      src="../images/announcement.png"
      alt="Img 1"
      className="w-full max-h-48 object-contain mb-4"
    />
    <a href="_#" className="hover:underline">
      <p className="text-center">.DOCX File</p>
    </a>
    <a href="_#" className="hover:underline">
      <p className="text-center">.PUB File</p>
    </a>
  </div>

  {/* Second Item */}
  <div className="mb-8">
    <h3 className="text-blue-900 font-[cursive] text-xl mb-4">3 X 5 Newsletters & Bulletin Ads</h3>
    <img
      src="../images/3x5-template.png"
      alt="Img 2"
      className="w-full max-h-48 object-contain mb-4"
    />
    <a href="_#" className="hover:underline">
      <p className="text-center">JPG - Black & White</p>
    </a>
    <a href="_#" className="hover:underline">
      <p className="text-center">JPG - Color</p>
    </a>
    <a href="_#" className="hover:underline">
      <p className="text-center">.DOCX File</p>
    </a>
    <a href="_#" className="hover:underline">
      <p className="text-center">.PUB File</p>
    </a>
  </div>

  {/* Third Item */}
  <div className="mb-8">
    <h3 className="text-blue-900 font-[cursive] text-xl mb-4">2 X 6 Newsletters & Bulletin Ads</h3>
    <img
      src="../images/2x6-template.png"
      alt="Img 3"
      className="w-full max-h-48 object-contain mb-4"
    />
    <a href="_#" className="hover:underline">
      <p className="text-center">JPG - Black & White</p>
    </a>
    <a href="_#" className="hover:underline">
      <p className="text-center">JPG - Color</p>
    </a>
    <a href="_#" className="hover:underline">
      <p className="text-center">.DOCX File</p>
    </a>
    <a href="_#" className="hover:underline">
      <p className="text-center">.PUB File</p>
    </a>
  </div>

  <div className="mb-8">
    <h3 className="text-blue-900 font-[cursive] text-xl mb-4">Email Templates</h3>
    <img src="../images/email-template.png" alt="Img 3" className="w-full max-h-48 object-contain mb-4" />
    <a href="_#" className="hover:underline">
      <p className="text-center">Member Website Email</p>
    </a>
    <a href="_#" className="hover:underline">
      <p className="text-center">Mobile App Email</p>
    </a>
  </div>

  <div className="mb-8">
    <h3 className="text-blue-900 font-[cursive] text-xl mb-4">Printable Family Form</h3>
    <img src="../images/pdf-template.png" alt="Img 3" className="w-full max-h-48 object-contain mb-4" />
    <a href="_#" className="hover:underline">
      <p className="text-center">.PDF File</p>
    </a>
  </div>
</div>

          <div className="flex flex-wrap mb-6">

            <div className="w-full md:w-1/2 pr-8">
              <h2 className="text-[#035292] font-semibold text-3xl my-8 ">QR Codes for App Downloads</h2>
              <p className='mb-6'>Here you can download the QR codes in .PNG format for the mobile apps and for the online members' directory website for quick access.</p>

              <a href="_#" className="text-blue-700 hover:underline"><p className='mb-3'>iOS Mobile App QR Code</p></a>
              <a href="_#" className="text-blue-700 hover:underline"><p className='mb-3'>Android Mobile App QR Code</p></a>
              <a href="_#" className="text-blue-700 hover:underline"><p className='mb-3'>Online Members' Directory Website QR Code</p></a>
            </div>


            <div className="w-full md:w-1/2 pl-6">
              <h2 className="text-[#035292] font-semibold text-3xl my-8 ">My Church Directory Official Brand</h2>
              <p className='mb-6'>We've compiled a zip file of official logos and graphics specific to My Church Directory to help you customize your own announcements.</p>

              <a href="_#" className="text-blue-700 hover:underline"><p className='mb-3'>Branding Tool Zip File</p></a>
            </div>
          </div>

        </div>
      </section>


      <section className="bg-[#f8f8f8] px-4 py-8 mt-16">
        <div className="max-w-screen-xl mx-auto">


          <div className="flex flex-wrap mb-6">

            <div className="w-full md:w-1/2 pr-3">
              <h2 className="text-[#035292] font-semibold text-4xl my-8 ">Available resources for your members to access the directory.</h2>

              <h2 className='text-[#035292] text-2xl md:text-2xl mb-2'>iPhone® / iPad® Login</h2>
              <a href="_#" className="hover:underline"><p className='mb-3'>Step-by-step Login Instructions for iPhone, iPad or any iOS device</p></a>

              <h2 className='text-[#035292] text-2xl md:text-2xl mb-2'>Android Login</h2>
              <a href="_#" className="hover:underline"><p className='mb-3'>Step-by-step Login Instructions for Android devices</p></a>

              <h2 className='text-[#035292] text-2xl md:text-2xl mb-2'>Online Members' Directory Website Login</h2>
              <a href="_#" className="hover:underline"><p className='mb-3'>Step-by-step Login Instructions for members. MyChurchDirectory.com</p></a>

              <h2 className='text-[#035292] text-2xl md:text-2xl mb-2'>Looking for additional help for your church members?</h2>
              <a href="_#" className="hover:underline"><p className='mb-3'>Visit our help library for more video tutorials and other helpful information for your members.</p></a>
            </div>


            <div className="w-full md:w-1/2 flex items-center justify-center">
              <img src="../images/tool-page-church.png" alt="img by Sigurdur Fjalar Jonsson on Unsplash" />
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 relative">
        <div className="bg-opacity-10 bg-white absolute top-0 left-0 w-full h-full"></div>


        <div
          className="bg-fixed bg-center bg-cover h-64 md:h-96"
          style={{ backgroundImage: 'url("../images/church-interior-unsplash.jpg")' }}
        >

          <div className="flex items-center justify-center h-full">

            <div className="max-w-screen-sm mx-auto bg-white bg-opacity-80 p-4 md:p-8 text-center flex flex-col items-center">

              <p className='text-[#035292] text-2xl md:text-3xl mb-2'>
                Visit our Blog to find more ideas on how to share your church directory with your members.
              </p>
            </div>
          </div>
        </div>
      </section>

      <StartYourFreeTrial />

    </div>
  )
}

export default Tools