const YourFreeTrialOffer = () => {
    return (
      <div className="w-full md:w-2/6 px-6 md:px-10 py-10">
      <div className="p-4 md:p-6 border border-grey-200">
        <h2 className='text-lg mb-2 font-medium'>Your FREE Trial Offer</h2>
        <p>
          When you subscribe to My Church Directory, you are placed into a 30-day free trial period and will enjoy full access to our website. Although a valid credit card is required to start your membership, your credit card will not be charged during your FREE trial period.
          <br /><br />
          At the end of the 30-day trial period, if you do nothing, your membership to My Church Directory will continue and your credit card will be charged. If you don't wish to continue after your trial period--just let us know, and we'll cheerfully issue you a full refund.
          <br /><br />
          For your convenience, My Church Directory is a continuous subscription. If you ever decide that My Church Directory is not meeting your needs, simply let us know, and we will issue you a full, prompt and courteous refund, no questions asked.
        </p>
      </div>
    </div>
    )
  }
  
  export default YourFreeTrialOffer