import axios from "axios"
import baseURL from "../config/api"

export const post_SubGroup = (data: any) => {
    return axios({
        url: `${baseURL}subGroup`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const put_SubGroup = (data: any) => {
    return axios({
        url: `${baseURL}subGroup`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const delete_SubGroup = (primaryKey: string) => {
    return axios({
        url: `${baseURL}subGroup`,
        method: "DELETE",
        params: { id: primaryKey }
    }).then(response => response.data);
}

export const getSubGroups = () => {
    return getFetch("subGroup")
}

export const getSubGroup = (primaryKey: string) => {
    return getFetch(`subGroup/${primaryKey}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}