import { FaComment, FaFacebook, FaLinkedin, FaWhatsappSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoBookmark } from "react-icons/io5";
import { LuClock3 } from "react-icons/lu";
import HeroSection from "../../components/HeroSection";

const SimplifyChurchAdministration = () => {
    return (
        <div className="bg-[#fff] w-full flex flex-col">
            <HeroSection
                mainHeading="Simplify Church Administration with My Church Directory"
                backgroundImage="../images/church-administration.png"
                // Img Source: Canva
            />

            <section>
                <div className='flex justify-center items-center gap-4 mt-10'>
                    <div className="flex items-center gap-2">
                        <LuClock3 className="w-4" />
                        <p className="blog-publish-date">22/06/2024</p>
                    </div>

                    <div className="flex items-center gap-2">
                        <FaComment className="w-4" />
                        <p className="blog-publish-date">0 Comments</p>
                    </div>

                    <div className="flex items-center gap-2">
                        <IoBookmark />
                        <p className="blog-publish-date">850 Views</p>
                    </div>
                </div>
            </section>

            <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
                <div className="mx-auto max-w-screen-md">
                    <p>
                        Managing a church involves numerous administrative tasks that can be time-consuming and complex. From keeping member records updated to communicating effectively with the congregation, church administration requires a streamlined approach to ensure everything runs smoothly. My Church Directory offers an all-in-one solution to simplify church administration and enhance your church community's experience.
                    </p>

                    <div className="mt-4">
                        <h2 className="text-[#000] text-xl font-semibold mb-3">Streamline Member Management</h2>
                        <p>
                            Keeping track of member information is essential for effective church management. My Church Directory allows you to easily store, update, and manage member data, including contact details, family relationships, and membership status. With an intuitive interface, administrators can quickly access and edit information, ensuring the directory is always accurate and up-to-date.
                        </p>
                    </div>

                    <div className="mt-4">
                        <h2 className="text-[#000] text-xl font-semibold mb-3">Enhance Communication</h2>
                        <p>
                            Effective communication is the backbone of a well-connected church community. My Church Directory provides tools to facilitate seamless communication among members and church leaders. The built-in emailing service allows you to send unlimited messages to your congregation, keeping everyone informed about upcoming events, announcements, and important updates. Additionally, the optional text messaging service ensures urgent messages are delivered instantly.
                        </p>
                    </div>

                    <div className="mt-4">
                        <h2 className="text-[#000] text-xl font-semibold mb-3">Improve Community Engagement</h2>
                        <p>
                            A strong sense of community is vital for any church. My Church Directory helps to foster engagement by making it easy for members to connect with one another. Members can log in to the secure online directory to view contact information, see photos, and find group affiliations. This accessibility encourages members to reach out, form connections, and build a supportive church family.
                        </p>
                    </div>

                    <div className="mt-4">
                        <h2 className="text-[#000] text-xl font-semibold mb-3">Efficient Event Planning</h2>
                        <p>
                            Organizing church events can be a complex task. My Church Directory simplifies event planning by providing tools to manage RSVPs, send reminders, and communicate event details to the congregation. This streamlined approach helps to ensure successful and well-attended events, strengthening the church community.
                        </p>
                    </div>

                    <div className="mt-4">
                        <h2 className="text-[#000] text-xl font-semibold mb-3">Accessible from Anywhere</h2>
                        <p>
                            In today's digital age, having access to important information on the go is crucial. My Church Directory is accessible from any device with an internet connection, allowing administrators and members to stay connected no matter where they are. Whether you're at home, at the church, or on the move, you can access and update the directory with ease.
                        </p>
                    </div>

                    <div className="mt-4">
                        <p>
                            In conclusion, My Church Directory is a powerful tool designed to simplify church administration and enhance community engagement. By streamlining member management, improving communication, fostering engagement, facilitating event planning, and providing accessible information, My Church Directory helps your church operate more efficiently and effectively. Embrace My Church Directory to transform your church administration and create a more connected and vibrant church community.
                        </p>
                    </div>
                </div>
            </section>

            <section className="mb-8">
                <div className="max-w-screen-md mx-auto rounded-md flex flex-col lg:flex-row justify-center items-center lg:items-start gap-6">
                    <div className="flex flex-wrap gap-2">
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Church Administration</p>
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Community Engagement</p>
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Event Planning</p>
                    </div>
                    <div className="flex flex-wrap justify-center lg:justify-start items-center gap-3">
                        <div className="flex items-center bg-[#42599E] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <FaFacebook />
                            </a>
                        </div>
                        <div className="flex items-center bg-[#55ACEE] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                                <FaXTwitter />
                            </a>
                        </div>
                        <div className="flex items-center bg-[#34AF23] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
                                <FaWhatsappSquare />
                            </a>
                        </div>
                        <div className="flex items-center bg-[#0798CF] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SimplifyChurchAdministration;