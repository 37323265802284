import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import useAuth from '../../hook/useAuth';
import { post_UserLogin } from '../../services/Login';
import { getGroup } from '../../services/Group';

const BillPayLogin = () => {
    const navigate = useNavigate();
    const { setAuth }: any = useAuth();
    const [passwordTextType, setPasswordTextType] = useState<boolean>(false);
    const [isRememberMe, setIsRememberMe] = useState<boolean>(true);
    const [userData, setUserData] = useState<any>({ userName: "", password: "" });
    const [errorMessage, setErroMessage] = useState<string>("");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
  
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
  
      if (!isProcessing) {
        setIsProcessing(true);
        post_UserLogin({
          userName: userData.userName,
          password: userData.password
        })
          .then(res => {
            const jsonData: any = jwtDecode(res);
            const groupId = jsonData["http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid"]
            getGroupData(groupId, res);
          })
          .catch(error => {
            setIsProcessing(false);
            setErroMessage(error.response.data);
            console.error(error);
          });
      }
    }
  
    const getGroupData = (id: string, accessToken: string) => {
      getGroup(id)
      .then(res => {
        const adminUserId = res.adminUserId;
        const jsonData: any = jwtDecode(accessToken);
        const id = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
        const roles = jsonData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        const givenname = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"];
        const emailAddress = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
        const groupId = jsonData["http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid"]
        const userName = userData.userName;

        let IsAdmin = false;

        if (parseInt(adminUserId) === parseInt(id)) {
          IsAdmin = true;
        }
  
        if (isRememberMe) {
          Cookies.set("token-my-cdir", accessToken, {
            expires: jsonData.exp,
          });
          Cookies.set("role-my-cdir", roles, {
            expires: jsonData.exp,
          });
          Cookies.set("groupId-my-cdir", groupId, {
            expires: jsonData.exp,
          });
          Cookies.set("userId-my-cdir", id, {
            expires: jsonData.exp,
          })
          Cookies.set("isAdmin-my-cdir", IsAdmin ? "1" : "0", {
            expires: jsonData.exp,
          });
        } else {
          Cookies.set("token-my-cdir", accessToken, {
            expires: new Date().setHours(new Date().getHours() + 1)
          });
          Cookies.set("role-my-cdir", roles, {
            expires: new Date().setHours(new Date().getHours() + 1)
          });
          Cookies.set("groupId-my-cdir", groupId, {
            expires: new Date().setHours(new Date().getHours() + 1)
          });
          Cookies.set("userId-my-cdir", IsAdmin ? "1" : "0", {
            expires: jsonData.exp,
          });
          Cookies.set("isAdmin-my-cdir", id, {
            expires: jsonData.exp,
          });
        }
  
        setAuth({ id, userName, givenname, emailAddress, roles, accessToken, groupId, IsAdmin: IsAdmin ? "1" : "0" });
          setUserData({ userName: "", password: "" });
          setIsProcessing(false);
          navigate("/bill-details");
        })
        .catch(error => {
          setIsProcessing(false);
          setErroMessage("Please username and password");
          console.error(error);
        });
    }

    return (
        <div className='w-full'>
            <div className="max-w-lg w-full mx-auto">
                <form className="bg-opacity-70 bg-primary rounded-2xl p-6 border border-secondary" onSubmit={handleSubmit}>
                    <div className="mb-10">
                        <h3 className="text-3xl font-extrabold">Admin Sign in</h3>
                    </div>
                    <div>
                        <div className="relative flex items-center">
                            <input name="email" type="text" required
                                className="bg-transparent w-full text-sm border-b border-secondary px-2 py-3 outline-none placeholder:text-[#bbb]"
                                placeholder="Enter username"
                                value={userData.userName}
                                onChange={(e) => setUserData({ ...userData, userName: e.target.value })} />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" className="w-[18px] h-[18px] absolute right-4" viewBox="0 0 24 24">
                                <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
                                <path d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z" data-original="#000000"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="mt-8">
                        <div className="relative flex items-center">
                            <input name="password" type={passwordTextType ? "text" : "password"} required
                                className="bg-transparent w-full text-sm border-b border-secondary px-2 py-3 outline-none placeholder:text-[#bbb]"
                                placeholder="Enter password"
                                value={userData.password}
                                onChange={(e) => setUserData({ ...userData, password: e.target.value })} />
                            {
                                passwordTextType ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#161616" stroke="#161616" className="w-[22px] h-[22px] absolute right-4 cursor-pointer" viewBox="0 0 32 32" onClick={() => setPasswordTextType(false)}>
                                        <path d="M8.10869891,20.8913011 C4.61720816,18.8301147 3,16 3,16 C3,16 7,9 16,9 C17.3045107,9 18.5039752,9.14706466 19.6014388,9.39856122 L18.7519017,10.2480983 C17.8971484,10.0900546 16.9800929,10 16,10 C8,10 4.19995117,16 4.19995117,16 C4.19995117,16 5.71472808,18.3917225 8.84492713,20.1550729 L8.10869891,20.8913011 L8.10869891,20.8913011 L8.10869891,20.8913011 Z M12.398561,22.601439 C13.4960246,22.8529356 14.6954892,23.0000001 16,23 C25,22.999999 29,16 29,16 C29,16 27.3827918,13.1698856 23.8913008,11.1086992 L23.1550727,11.8449273 C26.2852719,13.6082776 27.8000488,16 27.8000488,16 C27.8000488,16 24,21.999999 16,22 C15.019907,22.0000001 14.1028515,21.9099455 13.2480981,21.7519019 L12.398561,22.601439 L12.398561,22.601439 L12.398561,22.601439 Z M19.8986531,15.1013469 C19.9649658,15.3902115 20,15.6910144 20,16 C20,18.2091391 18.2091391,20 16,20 C15.6910144,20 15.3902115,19.9649658 15.1013469,19.8986531 L16,19 C16.7677669,19.0000001 17.5355339,18.7071068 18.1213203,18.1213203 C18.7071068,17.5355339 19.0000001,16.7677669 19,16 L19.8986531,15.1013469 L19.8986531,15.1013469 L19.8986531,15.1013469 Z M16.8986531,12.1013469 C16.6097885,12.0350342 16.3089856,12 16,12 C13.7908609,12 12,13.7908609 12,16 C12,16.3089856 12.0350342,16.6097885 12.1013469,16.8986531 L13,16 C12.9999999,15.2322331 13.2928932,14.4644661 13.8786797,13.8786797 C14.4644661,13.2928932 15.2322331,12.9999999 16,13 L16.8986531,12.1013469 L16.8986531,12.1013469 L16.8986531,12.1013469 Z M24,7 L7,24 L8,25 L25,8 L24,7 L24,7 Z" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#161616" stroke="#161616" className="w-[18px] h-[18px] absolute right-4 cursor-pointer" viewBox="0 0 128 128" onClick={() => setPasswordTextType(true)}>
                                        <path d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z" data-original="#000000"></path>
                                    </svg>
                                )
                            }
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-2 mt-6">
                        <div className="flex items-center">
                            <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 shrink-0 border-gray-300 rounded"
                                checked={isRememberMe}
                                onChange={() => setIsRememberMe(!isRememberMe)}
                            />
                            <label htmlFor="remember-me" className="ml-3 block text-sm">
                                Remember me
                            </label>
                        </div>
                        <div>
                            <Link to="/forgot-password" className="text-sm font-semibold hover:underline">
                                Forgot Password?
                            </Link>
                        </div>
                    </div>
                    <div className="mt-10">
                        <button type="submit"
                            className="w-full py-2.5 px-4 text-sm font-semibold rounded-full text-white bg-secondary hover:bg-[#222] focus:outline-none">
                            {isProcessing ?
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" fill="#fff" className="mr-2 inline animate-spin"
                                        viewBox="0 0 26.349 26.35">
                                        <circle cx="13.792" cy="3.082" r="3.082" data-original="#000000" />
                                        <circle cx="13.792" cy="24.501" r="1.849" data-original="#000000" />
                                        <circle cx="6.219" cy="6.218" r="2.774" data-original="#000000" />
                                        <circle cx="21.365" cy="21.363" r="1.541" data-original="#000000" />
                                        <circle cx="3.082" cy="13.792" r="2.465" data-original="#000000" />
                                        <circle cx="24.501" cy="13.791" r="1.232" data-original="#000000" />
                                        <path
                                            d="M4.694 19.84a2.155 2.155 0 0 0 0 3.05 2.155 2.155 0 0 0 3.05 0 2.155 2.155 0 0 0 0-3.05 2.146 2.146 0 0 0-3.05 0z"
                                            data-original="#000000" />
                                        <circle cx="21.364" cy="6.218" r=".924" data-original="#000000" />
                                    </svg>
                                    Loading...
                                </>
                                :
                                "Sign in"
                            }
                        </button>
                    </div>

                    {errorMessage && <p className="text-sm !mt-10 text-red-500 text-center">{errorMessage}</p>}
                </form>
            </div>
        </div>
    )
}

export default BillPayLogin
