import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getCountries } from '../../services/Country';
import { post_Group } from '../../services/Group';
import baseURL from '../../config/api';
import axios from 'axios';

const AddChurch = () => {
  const navigate = useNavigate();
  const [countryData, setCountryData] = useState<any[]>([]);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);

  const [churchData, setChurchData] = useState<any>({
    name: "",
    discription: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    country: "",
    state: "",
    city: "",
    street: "",
    street2: "",
    zip: "",
    isActive: "",
    imagePath: "",
  });

  useEffect(() => {
    getCountries()
      .then(res => {
        setCountryData(res);
        setChurchData({ ...churchData, country: res[0]?.id })
      })
      .catch(error => console.log(error));
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const close = () => navigate("/admin/church");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    post_Group(churchData)
      .then(() => navigate("/admin/church"))
      .catch(error => console.error(error));
  }

  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPleaseWaitPopup(true);
    const selectedFiles = e.target.files as FileList;

    if (selectedFiles.length !== 0) {
      const formData = new FormData();
      formData.append("ImageFile", selectedFiles[0]);

      axios
        .post(`${baseURL}commonFile/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        })
        .then((response) => {
          setChurchData({ ...churchData, imagePath: response.data });
          setPleaseWaitPopup(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

    } else {
      setPleaseWaitPopup(false);
    }
  };

  return (
    <div className="flex-wrap justify-center items-center w-full">
      <form onSubmit={handleSubmit} className="w-full px-5 py-4">
        <div className="my-2 text-center">
          <h4 className="text-xl text-blue-500 font-semibold">Add Church</h4>
          <div className="flex max-sm:flex-col justify-between gap-2 my-4">
            <div className="w-full text-sm">
              <label htmlFor="Church" className="w-full text-start block text-blue-500 text-xs">
                Church or Organization
              </label>
              <input name="Church"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter Church or Organization"
                value={churchData.name}
                onChange={(e) => setChurchData({ ...churchData, name: e.target.value })}
              />
            </div>

            <div className="w-full text-sm">
              <label htmlFor="title" className="w-full text-start block text-blue-500 text-xs">
                Title
              </label>
              <input name="title"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter Title"
                value={churchData.discription}
                onChange={(e) => setChurchData({ ...churchData, discription: e.target.value })}
              />
            </div>

            <div className="w-full text-sm">
              <label htmlFor="churchImage" className="w-full text-start block text-blue-500 text-xs md:ml-2">
                Church Image
              </label>

              {!churchData?.imagePath ? (
                <div className="flex items-center ml-2 mt-2">
                  <label
                    className="text-white bg-blue-500 text-xs cursor-pointer p-2 rounded"
                    onClick={() => {
                      if (inputFileRef.current !== null) {
                        inputFileRef.current.click();
                      }
                    }}
                  >
                    Add Image
                  </label>
                </div>
              ) : (
                <div>
                  <label
                    className="text-white bg-blue-500 text-xs cursor-pointer p-2 rounded mr-2"
                    onClick={() => setChurchData({ ...churchData, imagePath: "" })}
                  >
                    Delete
                  </label>
                  <img
                    src={`https://mycdirapi.azurewebsites.net/api/commonFile/Get?name=${churchData?.imagePath}`}
                    width={100}
                    height={80}
                    alt="churchImage"
                    className="md:ml-2 -mt-2.5"
                  />
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                name="churchImage"
                ref={inputFileRef}
                onChangeCapture={onFileChangeCapture}
                className="hidden"
              />
            </div>
          </div>


          <div className="flex max-sm:flex-col justify-between gap-2 my-4">
            <div className="w-full text-sm">
              <label htmlFor="firstname" className="w-full text-start block text-blue-500 text-xs">
                First Name
              </label>
              <input name="firstname"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter First Name"
                value={churchData.firstName}
                onChange={(e) => setChurchData({ ...churchData, firstName: e.target.value })}
              />
            </div>

            <div className="w-full text-sm">
              <label htmlFor="lastname" className="w-full text-start block text-blue-500 text-xs">
                Last Name
              </label>
              <input name="lastname"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter Last Name"
                value={churchData.lastName}
                onChange={(e) => setChurchData({ ...churchData, lastName: e.target.value })}
              />
            </div>
          </div>

          <div className="flex max-sm:flex-col justify-between gap-2 my-4">
            <div className="w-full text-sm">
              <label htmlFor="email" className="w-full text-start block text-blue-500 text-xs">
                Email Address
              </label>
              <input name="email"
                type="email" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter Email"
                value={churchData.emailAddress}
                onChange={(e) => setChurchData({ ...churchData, emailAddress: e.target.value })}
              />
            </div>

            <div className="w-full text-sm">
              <label htmlFor="phoneNumber" className="w-full text-start block text-blue-500 text-xs">
                Phone Number
              </label>
              <input name="phoneNumber"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter phone number"
                value={churchData.phoneNumber}
                onChange={(e) => setChurchData({ ...churchData, phoneNumber: e.target.value })}
              />
            </div>
          </div>

          <div className="flex max-sm:flex-col justify-between gap-2 my-4">
            <div className="w-full text-sm">
              <label htmlFor="country" className="w-full text-start block text-blue-500 text-xs">
                Country
              </label>
              <select name="country" required className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500" 
                value={churchData.country}
                onChange={(e) => setChurchData({ ...churchData, country: e.target.value })}
              >
                {countryData.map((item) => (
                  <option key={item.id} value={item.id}>{item.countryName}</option>
                ))}
              </select>
            </div>

            <div className="w-full text-sm">
              <label htmlFor="state" className="w-full text-start block text-blue-500 text-xs">
                State
              </label>
              <input name="state"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter State"
                value={churchData.state}
                onChange={(e) => setChurchData({ ...churchData, state: e.target.value })}
              />
            </div>
          </div>

          <div className="flex max-sm:flex-col justify-between gap-2 my-4">
            <div className="w-full text-sm">
              <label htmlFor="city" className="w-full text-start block text-blue-500 text-xs">
                City
              </label>
              <input name="city"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter City"
                value={churchData.city}
                onChange={(e) => setChurchData({ ...churchData, city: e.target.value })}
              />
            </div>

            <div className="w-full text-sm">
              <label htmlFor="street" className="w-full text-start block text-blue-500 text-xs">
                Street
              </label>
              <input name="street"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter Street"
                value={churchData.street}
                onChange={(e) => setChurchData({ ...churchData, street: e.target.value })}
              />
            </div>
          </div>

          <div className="flex max-sm:flex-col justify-between gap-2 my-4">
            <div className="w-full text-sm">
              <label htmlFor="street2" className="w-full text-start block text-blue-500 text-xs">
                Street 2
              </label>
              <input name="street2"
                type="text"
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter Street 2"
                value={churchData.street2}
                onChange={(e) => setChurchData({ ...churchData, street2: e.target.value })}
              />
            </div>

            <div className="w-full text-sm">
              <label htmlFor="zipCode" className="w-full text-start block text-blue-500 text-xs">
                Zip Code
              </label>
              <input name="zip"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter Zip Code"
                value={churchData.zip}
                onChange={(e) => setChurchData({ ...churchData, zip: e.target.value })}
              />
            </div>
          </div>

          <div className="flex max-sm:flex-col justify-between gap-2 my-4">
            <div className="w-full text-sm">
              <label htmlFor="active" className="w-full text-start block text-blue-500 text-xs">
                Active?
              </label>{" "}
              <div className="flex flex-row">
                <div className="flex items-center mr-2">
                  <input
                    type="radio"
                    value="Yes"
                    name="active"
                    style={{ fontSize: "12px" }}
                    checked={isActive ? true : false}
                    onChange={(e) => setIsActive(e.target.value === "Yes" ? true : false)}
                  />
                  <label className="ml-1">Yes</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    value="No"
                    name="active"
                    style={{ fontSize: "12px" }}
                    checked={isActive ? false : true}
                    onChange={(e) => setIsActive(e.target.value === "Yes" ? true : false)}
                  />
                  <label className="ml-1">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex max-sm:flex-col gap-4 !mt-4 mb-8">
          <button
            type="button"
            className="px-6 py-2 min-w-[150px] w-full rounded text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
            onClick={close}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-6 py-2 min-w-[150px] w-full rounded text-white text-sm font-semibold border-none outline-none bg-blue-500 hover:bg-blue-700"
          >
            {pleaseWaitPopup ?
              <>
                <svg xmlns="http://www.w3.org/2000/svg" width="18px" fill="#fff" className="mr-2 inline animate-spin"
                  viewBox="0 0 26.349 26.35">
                  <circle cx="13.792" cy="3.082" r="3.082" data-original="#000000" />
                  <circle cx="13.792" cy="24.501" r="1.849" data-original="#000000" />
                  <circle cx="6.219" cy="6.218" r="2.774" data-original="#000000" />
                  <circle cx="21.365" cy="21.363" r="1.541" data-original="#000000" />
                  <circle cx="3.082" cy="13.792" r="2.465" data-original="#000000" />
                  <circle cx="24.501" cy="13.791" r="1.232" data-original="#000000" />
                  <path
                    d="M4.694 19.84a2.155 2.155 0 0 0 0 3.05 2.155 2.155 0 0 0 3.05 0 2.155 2.155 0 0 0 0-3.05 2.146 2.146 0 0 0-3.05 0z"
                    data-original="#000000" />
                  <circle cx="21.364" cy="6.218" r=".924" data-original="#000000" />
                </svg>
                Processing...
              </>
              :
              "Save"
            }
          </button>
        </div>
      </form>
    </div>
  )
}

export default AddChurch