import QuickPay from './QuickPay'
import BillPayLogin from './BillPayLogin'
import { IoClose } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

const BillPay = () => {
    const navigate = useNavigate();
    const isAdmin = Cookies.get("isAdmin-my-cdir");

    useEffect(() => {
        if (isAdmin && parseInt(isAdmin) === 1) {
            navigate(`/bill-details`);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdmin])

    return (
        <div className="pb-40 sm:pb-64 md:min-h-[calc(100vh-600px)] lg:min-h-[calc(100vh-400px)] py-10 px-4">
            <div className="flex flex-col md:flex-row gap-8">

                <div className="flex flex-col gap-y-8 flex-1">
                    <QuickPay />

                    <div className="flex flex-row gap-x-2 w-full px-8">
                        <hr className='bg-secondary h-1 w-[calc(47.5%-0.5rem)]' />
                        <IoClose className='text-center text-secondary -mt-3' size={28} />
                        <hr className='bg-secondary h-1 w-[calc(47.5%-0.5rem)]' />
                    </div>

                    <BillPayLogin />
                </div>

                <div className='flex-1 hidden md:block'>
                    <div className="w-8/12 xl:w-10/12 h-full flex justify-center items-center">
                        {/* Download from https://www.freepik.com/ */}
                        <img src="./images/billpay1.jpg" className="w-full h-full object-cover" alt="Dining Experience" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillPay
