import StartYourFreeTrial from "../../components/StartYourFreeTrial";
import TryMyCdir from "../../components/TryMyCdir";

const membership = [
  {
    name: "Enable the online member directory website access in just one step.",
    description: "Your church or organization can choose to enable the online member directory website feature or not. This feature is disabled when you first sign up, but with a click of a mouse, you can enable the access and invite your members to create a login. It's that easy to share the directory with your members via the online members' directory website.",
  },
  {
    name: "Who has access?",
    description: "Just like our mobile apps, only active, current members with authorization have access to view your directory and member profiles and contact information. As soon as a member is marked as inactive, their ability to log into the directory is revoked immediately.",
  },
  {
    name: "How members access the online members' directory website:",
    description: "Simply direct your members to log in to: members.MyChurchDirectory.com.Upon visiting the first time, members will create a login using their email address and their own password. Once their email is verified, they log in using their email address and password.Any current members who want to access the online members' directory website must have a valid email address found in the directory. Member email addresses can be set to private so only the administrator or editors can see them.",
  },
  {
    name: "Tell your members about My Church Directory.",
    description: "Once you enable the online members' directory website and mobile apps, you can tell your members about it in several ways. It's important to announce the online directory multiple times to your members. We have several tools to help you do just that! Learn more.",
  },
];

const OnlineMemberDirectory = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url('https://images.unsplash.com/photo-1506953752663-add60014e80e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
          backgroundSize: "100% 80vh",
        }}
        className="hero--container flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border md:h-[60vh] pt-[65px]"
      ></section>

      <section className="py-8 px-2 md:px-12">
        <h1 className="text-custom-blue text-3xl md:text-4xl font-[cursive] pt-2 mb-6 text-center">
          Free Online Church Members' Directory
        </h1>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 p-6">
            <h1 className="text-custom-blue text-3xl md:text-4xl font-semibold mb-5 text-left md:text-left">
              Online membership access available on any computer or device, granting
              access from anywhere, at any time.
            </h1>
            <p className="text-left pb-2 md:text-left">
              Our online member website amplifies connectivity, catering to church members
              who lack smartphones or spend significant time on computers. It serves as an
              excellent platform for internet-based access to the online directory.
              <br /><br />
              Any updates made by the administrator immediately reflect in the online membership
              directory on the website, ensuring the congregation relies on dependable and accurate information.
              <br /><br />
              Members have the ability to search for other families or individuals, viewing their
              family photos along with names, addresses, phone and email contacts, birthdates, and associated groups.
              <br /><br />
              Furthermore, members will consistently have access to the most current staff
              contact information, providing easy communication with the church when needed.
              <br /><br />
              To maintain easy access to the members' website, individuals can pin it to
              their computer desktop or add it to their preferred bookmarks.
            </p>
          </div>

          <div className="w-full md:w-1/2 flex justify-center items-center">
            <img src="../images/faq.png" alt="faq" className="w-96" />
          </div>
        </div>
      </section>

      <section className="py-8 px-2 md:px-12 bg-gray-200">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 p-6">
            <h1 className="text-custom-blue text-3xl md:text-4xl font-semibold mb-5 text-left md:text-left">
              Your data is kept safe and secure
            </h1>
            <p className="text-left pb-2 md:text-left">
              Members have the capability to access your directory through any computer
              or mobile device via the members' website. This online members' directory
              operates similarly to the mobile apps but is accessible through a browser
              window like IE, Firefox, and Chrome on your computer or tablet.
              <br /><br />
              Security measures for the online members' directory site are aligned with the
              password and authentication protocols used in our mobile apps. We manage security
              to ensure that only active members can access your directory online.
              <br /><br />
              Members are responsible for creating their own login credentials, using their
              unique email address and password. Login requests undergo verification,
              eliminating the need for a shared password among the congregation or frequent
              password changes when a member departs.
              <br /><br />
              We refrain from using cookies to store login data, and there is no "remember me"
              feature available. This practice allows administrators to revoke a member's
              directory privileges if necessary.
            </p>
          </div>

          <div className="w-full md:w-1/2 flex justify-center items-center pb-8">
            <img src="../images/safe-and-secure.png" alt="safe-and-secure" className="w-96" />
          </div>
        </div>
      </section>
      <TryMyCdir />

      <section>
        <div className="py-6 md:py-12 px-2 md:px-12">
          <div className="flex flex-wrap md:flex-row pt-1">
            {membership.map((item, index) => (
              <div
                key={index}
                className="body--container px-4 md:p-4 md:w-1/2 lg:w-1/2 xl:w-2/4"
              >
                <div className="flex flex-col items-left mb-1 pt-2 md:pt-1">
                  <h3 className="text-custom-blue text-lg md:text-xl mb-2 p-1">
                    {item.name}
                  </h3>
                  <p className="text-left p-0.5">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <StartYourFreeTrial />
    </>
  );
};

export default OnlineMemberDirectory;
