import { Link } from "react-router-dom";
import StartYourFreeTrial from "../../components/StartYourFreeTrial";
import TryMyCdir from "../../components/TryMyCdir";

const membership = [
  {
    name: "Effortless Email and Text Messaging",
    description: "Communicate effortlessly with your entire membership or specific groups from your directory without the need for email lookup. Compose your message, select recipients, and send – it's that simple.",
  },
  {
    name: "Filter and send by groups.",
    description: "Add individuals or families to a group and send email messages to just that group. Perfect for when you need to quickly communicate to just a few key members and not your entire directory.",
  },
  {
    name: "Send unlimited emails with your paid membership.",
    description: "Send as many email messages as you'd like for no additional charge. The only requirement is that your church has an active membership with My Church Directory. You will not have access to this feature during your FREE trial.",
  },
  {
    name: "We take care of the sending for you.",
    description: "All of our email messages are completely advertisement-free and look like they are coming directly from you and your church. Send an email message right now or schedule it for a later date — either way, we send the email for you, ensuring your emails are always in CAN-SPAM compliance.",
  },
];

const churchMembers = [
  {
    name: "The power of text messaging.",
    description: "For an extra level of engagement, explore our optional text messaging feature! Text credits start at just $5, putting the power of My communication right at your fingertips.Our platform empowers you to send text messages at any time. Simply enter a subject, create a new text message, select the desired members from your church directory, and hit send! It's that simple.",
  },
  {
    name: "Personalized and Consistent Delivery.",
    description: "Utilize the online directory texting feature to set up your own unique Text Alert Name, allowing your members to quickly identify who is sending them a text message.The church texting feature utilizes a commercial grade short code to ensure prompt and dependable delivery of your messages. Rest easy knowing your text messages will make it to your church members.",
  },
  {
    name: "Easily reach your members.",
    description: "Why choose My Church Directory for your church texting service? Well, statistics show that more than 97% of text messages are opened and read within moments of receiving them. It's the most effective way to broadcast important news to your congregation.",
  },
  {
    name: "Purchase credits for as little as $5.",
    description: "Text messaging is a pay-as-you-go service. Purchase credits only when you are ready to send a text. There is no additional monthly fee and no hidden costs. Text credit plans start at $5 for 200 texts.",
  },
];


const faq = [
  {
    name: "What are text message credits?",
  },
  {
    name: "Why is texting an extra cost?"
  },
  {
    name: "Do my credits expire?"
  },
  {
    name: "How do I get started with text messaging?"
  },
  {
    name: "My Church Directory Text Message Service Terms of Service"
  },
];

const TextAndEmailMessaging = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url('https://images.unsplash.com/photo-1563986768609-322da13575f3?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
          backgroundSize: "100% 80vh",
        }}
        className="hero--container flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border md:h-[60vh] pt-[65px]"
      ></section>

      <section className="w-full items-center justify-center px-4">
        <div className="max-w-screen-lg mx-auto py-8">
          <h1 className="text-custom-blue text-2xl md:text-4xl font-[cursive] pt-2 mb-4 text-center">
            Church Email and Text Messaging Service.
          </h1>
          <br /><br />
          <h1 className="text-custom-blue text-2xl md:text-4xl font-medium pt-2 text-center">
            Spread your news through your <br />Church Directory
          </h1>
          <h1 className="text-custom-blue text-lg md:text-xl font-medium pt-2 mb-4 text-center">
            directly from your mobile phone.
          </h1>
        </div>
      </section>

      <section className="py-12 md:py-24 px-5 md:px-12 bg-gray-200">
        <div className="flex flex-col md:flex-row ">
          <div className="w-full md:w-3/5">
            <h2 className="text-custom-blue text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-semibold text-left px-1 mb-4 pt-1 md:pt-1">
              Email Your Church Members
            </h2>

            {membership.map((item, index) => (
              <div
                key={index}
                className="body--container md:mr-4"
              >
                <div className="flex flex-col items-left mb-2 pt-2 md:pt-4">
                  <h3 className="text-custom-blue text-lg md:text-xl mb-2 p-1">
                    {item.name}
                  </h3>
                  <p className="text-left p-0.5">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="w-full md:w-2/5 justify-center items-center">
            <img className="p-10" src="../images/send-an-email.jpg" alt="send-an-email" />
          </div>
        </div>
      </section>

      <section className="py-24 px-2 md:px-12">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-2/5 p-6">
            <img src="../images/broadcast-email.jpg" alt="broadcast-email" />                       
          </div>

          <div className="w-full md:w-3/5 justify-center items-center px-4">
            <h1 className="text-custom-blue text-3xl md:text-4xl font-semibold mb-5 text-left md:text-left">
              Text Message Your Church Members
            </h1>
            {churchMembers.map((item, index) => (
              <div
                key={index}
                className="body--container"
              >
                <div className="flex flex-col items-left mb-1 pt-2 md:pt-1">
                  <h3 className="text-custom-blue text-lg md:text-xl mb-2 p-1">
                    {item.name}
                  </h3>
                  <p className="text-left p-0.5">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <TryMyCdir />

      <section className="py-8 px-2 md:px-12 mt-5 bg-gray-200">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 p-6">
            <h1 className="text-custom-blue text-3xl md:text-4xl font-semibold mb-5 text-left md:text-left">
              Frequently Asked Questions
            </h1>
            {faq.map((item, index) => (
              <div
                key={index}
                className="body--container"
              >
                <div className="pt-6">
                  <p className="text-left pb-2 md:text-left">
                    {item.name}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="w-full md:w-1/2 flex justify-center items-center">
            <img className="p-10 w-96" src="../images/faq.png" alt="faq-2" />
          </div>
        </div>
      </section>

      <section
        style={{
          backgroundImage: `url('../images/my-Church-Directory-learn-more-image.jpg')`,
          backgroundSize: "100% 100vh",
        }}
        className="body--container relative flex flex-col justify-center w-full h-80vh bg-no-repeat bg-fixed md:bg-origin-border md:h-80vh py-24 px-2 md:px-12"
      >
        <div className="flex flex-col md:flex-row">
          <div className="md:w-3/5 p-10 bg-white bg-opacity-70 flex items-center">
            <p className="text-lg font-normal text-left pb-2 md:text-left text-custom-blue">
              <span><Link to={"/blog"} onClick={() => ("/blog")} className="text-xl">Explore our Blog </Link></span>
              for additional ideas on sharing your church directory with your members.
            </p>
          </div>
        </div>
      </section>

      <StartYourFreeTrial />
    </>
  );
};

export default TextAndEmailMessaging;
