import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getCountries } from '../../services/Country';
import { getGroup, put_Group } from '../../services/Group';
import baseURL from '../../config/api';
import axios from 'axios';

const EditChurch = () => {
  const navigate = useNavigate();
  const { id }: any = useParams();
  const [countryData, setCountryData] = useState<any[]>([]);
  const [countryDataIsLoaded, setCountryDataIsLoaded] = useState<boolean>(false);

  const [churchData, setChurchData] = useState<any>({
    id: 0,
    name: "",
    discription: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    country: "",
    state: "",
    city: "",
    street: "",
    street2: "",
    zip: "",
    isActive: "",
    imagePath: "",
  });

  useEffect(() => {
    getCountries()
      .then(res => {
        setCountryData(res);
        setCountryDataIsLoaded(true);
      })
      .catch(error => console.log(error));
  }, [])

  useEffect(() => {
    if (id) {
      getGroup(id)
        .then(res => setChurchData({
          id: res.id,
          name: res.name,
          discription: res.discription,
          firstName: res.firstName,
          lastName: res.lastName,
          emailAddress: res.emailAddress,
          phoneNumber: res.phoneNumber,
          country: res.country,
          state: res.state,
          city: res.city,
          street: res.street,
          street2: res.street2,
          zip: res.zip,
          isActive: res.isActive,
          imagePath: res.imagePath,
        }))
        .catch(error => console.error(error));
    }
  }, [id, countryDataIsLoaded])

  const close = () => navigate("/admin/church");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    put_Group(churchData)
      .then(() => navigate("/admin/church"))
      .catch(error => console.error(error));
  }

  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files as FileList;

    if (selectedFiles.length !== 0) {
      const formData = new FormData();
      formData.append("ImageFile", selectedFiles[0]);

      axios
        .post(`${baseURL}commonFile/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        })
        .then((response) => {
          setChurchData({ ...churchData, imagePath: response.data });
        })
        .catch((error) => {
          console.error("Error:", error);
        });

    }
  };

  return (
    <div className="flex-wrap justify-center items-center w-full">
      <form onSubmit={handleSubmit} className="w-full px-5 py-4">
        <div className="my-2 text-center">
          <h4 className="text-xl text-blue-500 font-semibold">Edit Church</h4>
          <div className="flex max-sm:flex-col justify-between gap-2 my-4">
            <div className="w-full text-sm">
              <label htmlFor="Church" className="w-full text-start block text-blue-500 text-xs">
                Church or Organization
              </label>
              <input name="Church"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter Church or Organization"
                value={churchData.name}
                onChange={(e) => setChurchData({ ...churchData, name: e.target.value })}
              />
            </div>

            <div className="w-full text-sm">
              <label htmlFor="title" className="w-full text-start block text-blue-500 text-xs">
                Title
              </label>
              <input name="title"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter Title"
                value={churchData.discription}
                onChange={(e) => setChurchData({ ...churchData, discription: e.target.value })}
              />
            </div>

            <div className="w-full text-sm">
              <label htmlFor="churchImage" className="w-full text-start block text-blue-500 text-xs md:ml-2">
                Church Image
              </label>

              {!churchData.imagePath ? (
                <div className="flex items-center ml-2 mt-2">
                  <label
                    className="text-white bg-blue-500 text-xs cursor-pointer p-2 rounded"
                    onClick={() => {
                      if (inputFileRef.current !== null) {
                        inputFileRef.current.click();
                      }
                    }}
                  >
                    Add Image
                  </label>
                </div>
              ) : (
                <div>
                  <label
                    className="text-white bg-blue-500 text-xs cursor-pointer p-2 rounded lg:mr-20"
                    onClick={() => setChurchData({...churchData, imagePath: ""})}
                  >
                    Delete
                  </label>
                  <img
                    src={`${baseURL}commonFile/Get?name=${churchData.imagePath}`}
                    width={100}
                    height={80}
                    alt="churchImage"
                    className="md:ml-2 -mt-2.5"
                  />
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                name="churchImage"
                ref={inputFileRef}
                onChangeCapture={onFileChangeCapture}
                className="hidden"
              />
            </div>
          </div>

          <div className="flex max-sm:flex-col justify-between gap-2 my-4">
            <div className="w-full text-sm">
              <label htmlFor="firstname" className="w-full text-start block text-blue-500 text-xs">
                First Name
              </label>
              <input name="firstname"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter First Name"
                value={churchData.firstName}
                onChange={(e) => setChurchData({ ...churchData, firstName: e.target.value })}
              />
            </div>

            <div className="w-full text-sm">
              <label htmlFor="lastname" className="w-full text-start block text-blue-500 text-xs">
                Last Name
              </label>
              <input name="lastname"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter Last Name"
                value={churchData.lastName}
                onChange={(e) => setChurchData({ ...churchData, lastName: e.target.value })}
              />
            </div>
          </div>

          <div className="flex max-sm:flex-col justify-between gap-2 my-4">
            <div className="w-full text-sm">
              <label htmlFor="email" className="w-full text-start block text-blue-500 text-xs">
                Email Address
              </label>
              <input name="email"
                type="email" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter Email"
                value={churchData.emailAddress}
                onChange={(e) => setChurchData({ ...churchData, emailAddress: e.target.value })}
              />
            </div>

            <div className="w-full text-sm">
              <label htmlFor="phoneNumber" className="w-full text-start block text-blue-500 text-xs">
                Phone Number
              </label>
              <input name="phoneNumber"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter phone number"
                value={churchData.phoneNumber}
                onChange={(e) => setChurchData({ ...churchData, phoneNumber: e.target.value })}
              />
            </div>
          </div>

          <div className="flex max-sm:flex-col justify-between gap-2 my-4">
            <div className="w-full text-sm">
              <label htmlFor="country" className="w-full text-start block text-blue-500 text-xs">
                Country
              </label>
              <select name="country" required className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500" 
                value={churchData.country}
                onChange={(e) => setChurchData({ ...churchData, country: e.target.value })}
              >
                {countryData.map((item) => (
                  <option key={item.id} value={item.id}>{item.countryName}</option>
                ))}
              </select>
            </div>

            <div className="w-full text-sm">
              <label htmlFor="state" className="w-full text-start block text-blue-500 text-xs">
                State
              </label>
              <input name="state"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter State"
                value={churchData.state}
                onChange={(e) => setChurchData({ ...churchData, state: e.target.value })}
              />
            </div>
          </div>

          <div className="flex max-sm:flex-col justify-between gap-2 my-4">
            <div className="w-full text-sm">
              <label htmlFor="city" className="w-full text-start block text-blue-500 text-xs">
                City
              </label>
              <input name="city"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter City"
                value={churchData.city}
                onChange={(e) => setChurchData({ ...churchData, city: e.target.value })}
              />
            </div>

            <div className="w-full text-sm">
              <label htmlFor="street" className="w-full text-start block text-blue-500 text-xs">
                Street
              </label>
              <input name="street"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter Street"
                value={churchData.street}
                onChange={(e) => setChurchData({ ...churchData, street: e.target.value })}
              />
            </div>
          </div>

          <div className="flex max-sm:flex-col justify-between gap-2 my-4">
            <div className="w-full text-sm">
              <label htmlFor="street2" className="w-full text-start block text-blue-500 text-xs">
                Street 2
              </label>
              <input name="street2"
                type="text"
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter Street 2"
                value={churchData.street2}
                onChange={(e) => setChurchData({ ...churchData, street2: e.target.value })}
              />
            </div>

            <div className="w-full text-sm">
              <label htmlFor="zipCode" className="w-full text-start block text-blue-500 text-xs">
                Zip Code
              </label>
              <input name="zip"
                type="text" required
                className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                placeholder="Enter Zip Code"
                value={churchData.zip}
                onChange={(e) => setChurchData({ ...churchData, zip: e.target.value })}
              />
            </div>
          </div>

          <div className="flex max-sm:flex-col justify-between gap-2 my-4">
            <div className="w-full text-sm">
              <label htmlFor="active" className="w-full text-start block text-blue-500 text-xs">
                Active?
              </label>{" "}
              <div className="flex flex-row">
                <div className="flex items-center mr-2">
                  <input
                    type="radio"
                    value="Yes"
                    name="active"
                    style={{ fontSize: "12px" }}
                    checked={churchData.isActive ? true : false}
                    onChange={(e) => setChurchData({ ...churchData, isActive: e.target.value === "Yes" ? true : false })} 
                  />
                  <label className="ml-1">Yes</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    value="No"
                    name="active"
                    style={{ fontSize: "12px" }}
                    checked={churchData.isActive ? false : true}
                    onChange={(e) => setChurchData({ ...churchData, isActive: e.target.value === "Yes" ? true : false })} 
                  />
                  <label className="ml-1">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex max-sm:flex-col gap-4 !mt-4 mb-8">
          <button
            type="button"
            className="px-6 py-2 min-w-[150px] w-full rounded text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
            onClick={close}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-6 py-2 min-w-[150px] w-full rounded text-white text-sm font-semibold border-none outline-none bg-blue-500 hover:bg-blue-700"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default EditChurch