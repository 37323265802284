import React, { useState, useEffect, useRef } from "react";
import { FaCheckCircle } from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';

interface EmailAlreadyExistCheckingProps {
    text: string;
    setText: (value: string) => void;
    className?: any;
    getEmailChecking: (email: string) => Promise<boolean>;
    touched: boolean;
    setTouched: (touched: boolean) => void;
}

const EmailAlreadyExistChecking = ({
    text,
    setText,
    getEmailChecking,
    className = {
        parentDiv: "",
        label: "font-semibold text-sm",
        input: "w-full rounded py-2 px-4 mt-2 border-2 text-sm text-black outline-tertiary"
    },
    touched,
    setTouched
}: EmailAlreadyExistCheckingProps) => {
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const [isEmailAvailable, setIsEmailAvailable] = useState<boolean | null>(null);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        let debounceTimer: NodeJS.Timeout;

        if (touched && text.trim() !== '') {
            debounceTimer = setTimeout(() => {
                const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/gm.test(text.trim());
                setIsEmailValid(isValid);
                if (isValid) {
                    checkEmailAvailability(text.trim());
                } else {
                    setIsEmailAvailable(false);
                    setShowErrorMessage(false);
                    setShowSuccessMessage(false);
                }
            }, 1000);
        } else {
            setIsEmailValid(true);
            setIsEmailAvailable(false);
            setShowErrorMessage(false);
            setShowSuccessMessage(false);
        }

        return () => clearTimeout(debounceTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text, touched]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setText(value);
        setTouched(true);
        setIsEmailValid(true);
        setIsEmailAvailable(false);
        setShowErrorMessage(false);
        setShowSuccessMessage(false);
        e.target.setCustomValidity("");
    };

    const checkEmailAvailability = (email: string) => {
        getEmailChecking(email)
            .then((response: boolean) => {
                setIsEmailAvailable(response);
                setShowErrorMessage(response);
                setShowSuccessMessage(!response);

                if (!response) {
                    setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 3000);
                }

                if (inputRef.current) {
                    inputRef.current.setCustomValidity(response ? "Email is already exist" : "");
                }
            })
            .catch((error: Error) => {
                console.error('Error checking email availability:', error);
                setIsEmailAvailable(null);
                setShowErrorMessage(false);
                setShowSuccessMessage(false);
            });
    };

    return (
        <div className={className.parentDiv}>
            <label className={className.label}>Email</label>
            <input
                ref={inputRef}
                type="email"
                placeholder="Enter Email"
                className={`${className.input} ${isEmailValid ? '' : 'border-red-500'}`}
                value={text}
                onChange={handleChange}
                autoComplete="new-email"
            />
            {!isEmailValid && (
                <div className="flex items-center mt-2 text-xs text-red-500">
                    <MdErrorOutline className="mr-1" style={{ fontSize: 16 }} />
                    <span>Please enter a valid email address</span>
                </div>
            )}
            {showSuccessMessage && !isEmailAvailable && (
                <div className="flex items-center mt-2 text-xs text-green-500">
                    <FaCheckCircle className="mr-1" style={{ fontSize: 16 }} />
                    <span>Email is available</span>
                </div>
            )}
            {showErrorMessage && isEmailAvailable && (
                <div className="flex items-center mt-2 text-xs text-red-500">
                    <MdErrorOutline className="mr-1" style={{ fontSize: 16 }} />
                    <span>Email is already exist</span>
                </div>
            )}
        </div>
    );
};

export default EmailAlreadyExistChecking;