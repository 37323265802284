import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { MdFamilyRestroom } from "react-icons/md";
import useAuth from '../../hook/useAuth';
import { format } from 'date-fns';
import baseURL from '../../config/api';


const FamilyMemberList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { auth }: any = useAuth();
  const [memberData, setMemberData] = useState<any[]>([]);
  const [memberDataIsLoaded, setMemberDataIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (auth) {
      fetch(`${baseURL}FamilyMember/GetFamilyMemberByMemberId/${parseInt(auth?.memberId)}`)
        .then((res) => res.json())
        .then((data) => {
          setMemberData(
            data.map((item: any) => {
              return {
                id: item.id,
                name: item.firstName + " " + item.lastName,
                birthday: item?.birthday ? format(new Date(item?.birthday), "MM/dd/yyyy") : "No data",
                address: `${item?.street}, ${item?.street2} ${item?.city} ${item?.state}, ${item?.country}-${item?.zip}`,
                phoneNumber: item.phoneNumber,
                emailAddress: item.emailAddress,
                imagePath:
                  item?.filePath ? `${baseURL}commonFile/Get?name=${item.filePath}` :
                    "./images/user-default.jpg"
              }
            })
          )
          setMemberDataIsLoaded(true);
        })
        .catch(error => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, location]);

  const addFamilyMember = () => {
    navigate(`/member/family-members/add-family-members`);
  }

  const editFamilyMember = (id: string) => {
    navigate(`/member/family-members/edit-family-members/${id}`);
  }

  const deleteFamilyMember = (id: string) => {
    navigate(`/member/my-account/delete-family-members/${id}`);
  }

  return (
    <div className='member__list__container w-full h-full flex flex-col flex-1 mx-auto max-w-6xl'>

      <div className="min-w-full flex flex-row justify-between item-center">
        <h1 className="px-4 py-1.5 flex items-center gap-2">
          <MdFamilyRestroom fontSize={20} />
          <span className="sm:text-lg font-bold">Family Member</span>
        </h1>
        <button
          className="bg-blue-500 hover:bg-blue-700 px-4 py-1.5 rounded-sm text-white"
          onClick={addFamilyMember}
        >
          Add Family Member
        </button>
      </div>

      <div className="min-w-full h-0.5 bg-slate-500 my-1" />

      {memberDataIsLoaded ? (
        <div className="member__list__container grid grid-cols-1 md:grid-cols-2 3lg:grid-cols-3 gap-y-4 lg:gap-x-4 m-auto w-full">
          {memberData.map((item, index) => (
            <div key={index} className="member__view__container flex flex-col w-full h-fit gap-2 md:gap-3 rounded-lg bg-gray-50 shadow-2xl py-2">
              <div className="flex flex-col md:flex-row gap-2 md:gap-3 px-2">
                <div className="member__view flex gap-2 md:gap-4 flex-col items-center">
                  <div className="image__view w-40 h-40">
                    <img src={item.imagePath} alt="member" className="w-40 h-40" />
                  </div>
                </div>

                <div className="text__container flex md:hidden flex-col flex-1 justify-center items-center w-full px-2">
                  <h1 className="name__text text-lg font-bold">{item.name}</h1>
                  <h3 className="name__text text-sm font-semibold">{item.birthday}</h3>
                </div>

                <div className="family__view flex flex-col-reverse md:flex-col min-w-[150px] lg:w-full">
                  <div className="flex gap-4 w-full flex-col lg:flex-row ">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 px-4 py-1 rounded-sm font-bold text-white w-full"
                      onClick={() => editFamilyMember(item.id)}
                    >
                      Edit
                    </button>

                    <button
                      className="bg-blue-500 hover:bg-blue-700 px-4 py-1 rounded-sm font-bold text-white w-full"
                      onClick={() => deleteFamilyMember(item.id)}
                    >
                      Delete
                    </button>

                  </div>

                  <div className="text-black flex flex-col items-center md:items-start md:mt-4">
                    <div className="flex flex-row flex-1 justify-between items-center md:items-start gap-4 py-1">
                      <BsFillTelephoneFill />
                      <p className="flex flex-col flex-1 items-start text-base font-semibold">{item.phoneNumber}</p>
                    </div>

                    <div className="flex flex-row flex-1 justify-between items-center md:items-start gap-4 py-1">
                      <MdEmail />
                      <p className="flex flex-col flex-1 items-start text-base font-semibold">{item.emailAddress}</p>
                    </div>
                  </div>

                </div>
              </div>

              <div className="text__container hidden md:flex flex-col flex-1 justify-center w-full px-2">
                <h1 className="name__text text-lg font-bold">{item.name}</h1>
                <h3 className="name__text text-sm font-semibold">{item.birthday}</h3>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col flex-1 justify-center items-center">
          <div
            className="h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite] text-blue-500"
            role="status"></div>
          <span className="text-black">Loading...</span>
        </div>
      )}
    </div>
  )
}

export default FamilyMemberList;