import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hook/useAuth';
import { FileDrop } from '../../components/FileDrop';
import { onChangePhoneNumber } from '../../utils/phoneNumberRegex';
import AdminFamilyMemberList from './AdminFamilyMemberList';
import { getMember, put_Member } from '../../services/Member';
import { getGroups } from '../../services/Group';
import baseURL from '../../config/api';
import Cookies from 'js-cookie';
import AddAdminFamilyMember from './AddAdminFamilyMember';
import EditAdminFamilyMember from './EditAdminFamilyMember';

enum CurrentPage {
  ADD_FAMILY,
  ADD,
  EDIT
};

const EditAdminMember = () => {
  const fromEdit = true;
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { auth }: any = useAuth();
  const role = Cookies.get("role-my-cdir") || "";
  const [currentpage, setCurrentPage] = useState<CurrentPage>(CurrentPage.ADD_FAMILY);
  const birthdayRef = useRef<HTMLInputElement>(null);
  const [familyMember, setFamilyMember] = useState<any[]>([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
  const [userData, setUserData] = useState<any>({
    familyName: "",
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    emailAddress: "",
    phoneNumber: "",
    birthday: "",
    imagePath: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    groupId: 0,
    subGroupId: 0,
    imageSize: 0,
    createdAt: "",
    createdBy: "",
    uploadedAt: "",
    uploadedBy: ""
  });
  const [groupData, setGroupData] = useState<any[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  useEffect(() => {
    if (role?.toString()?.toLowerCase().replaceAll(" ", "") === "admin") {
      setIsSuperAdmin(false);
    } else if (role?.toString()?.toLowerCase().replaceAll(" ", "") === "superadmin") {
      setIsSuperAdmin(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (role?.toString()?.toLowerCase().replaceAll(" ", "") === "superadmin") {
      getGroups()
        .then(res => {
          setGroupData(res.map((item: any) => {
            return {
              id: item.id,
              name: item.name
            }
          }));
        })
        .catch(error => console.error(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (id) {
      getMember(id)
        .then(res => {
          setUserData({
            ...res,
            birthday: res?.birthday ? new Date(res.birthday).toISOString().split('T')[0] : "",
            imagePath: res?.imagePath ? res.imagePath : "./images/user-default.jpg"
          });
        })
        .catch(error => console.error(error));
    }
  }, [id, location])

  useEffect(() => {
    if (id && isFirstTime) {
      fetch(`${baseURL}FamilyMember/GetFamilyMemberByMemberId/${parseInt(id)}`)
        .then((res) => res.json())
        .then((data) => {
          setFamilyMember(data)
          setIsFirstTime(false);
        }
        )
        .catch(error => console.error(error));
    }

  }, [id, isFirstTime])

  const closeModal = () => navigate(-1);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isProcessing) {
      const filterFamilyMember = familyMember.map((item: any) => {

        return {
          ...item,
          groupId: userData.groupId,
          subGroupId: parseInt(userData.groupId) === parseInt(item?.groupId) ? item.subGroupId : "",
          relationToMember: `${item.firstName} ${item.lastName}`,
          firstName: item.firstName,
          lastName: item.lastName,
          userName: item.userName,
          password: item.password,
          emailAddress: item.emailAddress,
          phoneNumber: item.phoneNumber,
          birthday: item.birthday,
          filePath: item.filePath,
          imageSize: 0,
          createdAt: new Date().toISOString(),
          createdBy: auth?.givenname,
          uploadedAt: new Date().toISOString(),
          uploadedBy: auth?.givenname,
        }
      });

      setIsProcessing(true);

      put_Member({
        ...userData,
        imagePath: userData?.imagePath?.replace(`${baseURL}commonFile/Get?name=`, ""),
        createdAt: new Date().toISOString(),
        createdBy: auth?.givenname,
        uploadedAt: new Date().toISOString(),
        uploadedBy: auth?.givenname,
        familyMembers: filterFamilyMember
      })
        .then(() => {
          setIsProcessing(false);
          navigate(-1);
        })
        .catch(error => {
          setIsProcessing(false);
          console.error(error);
        });
    }
  }


  return (
    <div className="w-full">
      <div className="my-2">
        <div className="mx-auto max-w-6xl  relative bg-white">
          <h2 className={`text-xl text-blue-500 font-bold ${currentpage === CurrentPage.ADD_FAMILY ? "block" : "hidden"}`}>Edit Family</h2>
          {currentpage === CurrentPage.ADD_FAMILY && (
            <form onSubmit={handleSubmit} className="mt-8 grid sm:grid-cols-2 gap-6 text-blue-500">
              <div className='col-span-2'>
                <label className="font-semibold text-sm">Family Name</label>
                <input type='text' placeholder='Enter Family Name'
                  className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                  value={userData.familyName}
                  onChange={(e) => setUserData({ ...userData, familyName: e.target.value })}
                  required
                />
              </div>
              <div>
                <label className="font-semibold text-sm">First Name</label>
                <input type='text' placeholder='Enter First Name'
                  className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                  value={userData.firstName}
                  onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                  required
                />
              </div>
              <div>
                <label className="font-semibold text-sm">Last Name</label>
                <input type='text' placeholder='Enter Last Name'
                  className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                  value={userData.lastName}
                  onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                  required
                />
              </div>
              <div>
                <label className="font-semibold text-sm">Email</label>
                <input type='email' placeholder='Email'
                  className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                  value={userData.emailAddress}
                  onChange={(e) => setUserData({ ...userData, emailAddress: e.target.value })}
                  required
                />
              </div>
              <div>
                <label className="font-semibold text-sm">Phone Number</label>
                <input type='text' placeholder='Phone No.'
                  className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                  pattern="[\(][0-9]{3}[\)][\s]{1}[0-9]{3}[\-]{1}[0-9]{4}"
                  value={userData.phoneNumber}
                  onChange={(e) => setUserData({ ...userData, phoneNumber: onChangePhoneNumber(e.target.value) })}
                />
              </div>
              <div>
                <label className="font-semibold text-sm">Birthday</label>
                <input ref={birthdayRef} type='date'
                  className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                  value={userData.birthday}
                  onChange={(e) => setUserData({ ...userData, birthday: e.target.value })}
                  onClick={() => birthdayRef?.current?.showPicker()}
                  required
                />
              </div>
              {isSuperAdmin &&
                <div>
                  <label className="font-semibold text-sm">Church/Organization</label>
                  <select
                    className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                    value={userData.groupId}
                    onChange={(e) => setUserData({ ...userData, groupId: parseInt(e.target.value) })}
                    required
                  >
                    {groupData.map((item) => (
                      <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
              }
              <div>
                <label className="font-semibold text-sm">Street</label>
                <input type='text' placeholder='Street'
                  className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                  value={userData.street}
                  onChange={(e) => setUserData({ ...userData, street: e.target.value })}
                  required
                />
              </div>
              <div>
                <label className="font-semibold text-sm">Street 2</label>
                <input type='text' placeholder='Street 2'
                  className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                  value={userData.street2}
                  onChange={(e) => setUserData({ ...userData, street2: e.target.value })}
                />
              </div>
              <div>
                <label className="font-semibold text-sm">City</label>
                <input type='text' placeholder='City'
                  className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                  value={userData.city}
                  onChange={(e) => setUserData({ ...userData, city: e.target.value })}
                  required
                />
              </div>
              <div>
                <label className="font-semibold text-sm">State</label>
                <input type='text' placeholder='State'
                  className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                  value={userData.state}
                  onChange={(e) => setUserData({ ...userData, state: e.target.value })}
                  required
                />
              </div>
              <div>
                <label className="font-semibold text-sm">Country</label>
                <input type='text' placeholder='Country'
                  className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                  value={userData.country}
                  onChange={(e) => setUserData({ ...userData, country: e.target.value })}
                  required
                />
              </div>
              <div>
                <label className="font-semibold text-sm">Zip</label>
                <input type='text' placeholder='Zip'
                  className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                  value={userData.zip}
                  onChange={(e) => setUserData({ ...userData, zip: e.target.value })}
                  required
                />
              </div>
              {!isSuperAdmin && <div />}

              <div >
                <FileDrop
                  value={userData.imagePath}
                  setValue={(value) => setUserData({ ...userData, imagePath: value })}
                />
              </div>
              <div />

              <div className="flex max-sm:flex-col gap-4 !mt-4">
                <button
                  type="button"
                  className="px-6 py-2 min-w-[150px] rounded text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-6 py-2 min-w-[150px] rounded text-white text-sm font-semibold border-none outline-none bg-blue-500 hover:bg-blue-700"
                >
                  {isProcessing ?
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18px" fill="#fff" className="mr-2 inline animate-spin"
                        viewBox="0 0 26.349 26.35">
                        <circle cx="13.792" cy="3.082" r="3.082" data-original="#000000" />
                        <circle cx="13.792" cy="24.501" r="1.849" data-original="#000000" />
                        <circle cx="6.219" cy="6.218" r="2.774" data-original="#000000" />
                        <circle cx="21.365" cy="21.363" r="1.541" data-original="#000000" />
                        <circle cx="3.082" cy="13.792" r="2.465" data-original="#000000" />
                        <circle cx="24.501" cy="13.791" r="1.232" data-original="#000000" />
                        <path
                          d="M4.694 19.84a2.155 2.155 0 0 0 0 3.05 2.155 2.155 0 0 0 3.05 0 2.155 2.155 0 0 0 0-3.05 2.146 2.146 0 0 0-3.05 0z"
                          data-original="#000000" />
                        <circle cx="21.364" cy="6.218" r=".924" data-original="#000000" />
                      </svg>
                      Processing...
                    </>
                    :
                    "Save"
                  }
                </button>
              </div>
            </form>
          )
          }
          <div className={`family__member__list__container ${currentpage === CurrentPage.ADD_FAMILY ? "block" : "hidden"}`}>
            <AdminFamilyMemberList
              setCurrentPage={setCurrentPage}
              familyMember={familyMember}
              setFamilyMember={setFamilyMember}
              setSelectedRowIndex={setSelectedRowIndex}
              fromEdit={fromEdit}
              setIsFirstTime={setIsFirstTime}
            />
          </div>

          {currentpage === CurrentPage.ADD &&
            <div className={`add__family__member__container ${currentpage === CurrentPage.ADD ? "block" : "hidden"}`}>
              <AddAdminFamilyMember
                familyMember={familyMember}
                setFamilyMember={setFamilyMember}
                setCurrentPage={setCurrentPage}
                groupId={parseInt(userData.groupId)}
              />
            </div>
          }

          {currentpage === CurrentPage.EDIT &&
            <div className={`edit__family__member__container ${currentpage === CurrentPage.EDIT ? "block" : "hidden"}`}>
              <EditAdminFamilyMember
                setCurrentPage={setCurrentPage}
                familyMemberValue={familyMember[selectedRowIndex]}
                familyMember={familyMember}
                setFamilyMember={setFamilyMember}
                selectedRowIndex={selectedRowIndex}
                groupId={parseInt(userData.groupId)}
              />
            </div>
          }

        </div>
      </div>
    </div>
  )
}
export default EditAdminMember