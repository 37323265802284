import { ReactNode, createContext, useState } from 'react'


const DeleteContext = createContext({});

export const DeleteProvider = ({ children }: { children: ReactNode }) => {
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [data, setData] = useState<any>();

    const value = { deletePopup, setDeletePopup, data, setData };

    return (
        <DeleteContext.Provider value={value}>
            {children}
        </DeleteContext.Provider>
    )
}

export default DeleteContext
