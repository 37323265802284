import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import useAuth from "../hook/useAuth";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import AddUser from "../pages/user/AddUser";
import EditUser from "../pages/user/EditUser";
import DeleteUser from "../pages/user/DeleteUser";
import DeleteChurch from "../pages/church/DeleteChurch";
import DeleteAdminMember from "../pages/admin/DeleteAdminMember";
import DeleteAdminFamilyMember from "../pages/admin/DeleteAdminFamilyMember";
import DeleteSubGroup from "../pages/subgroup/DeleteSubGroup";
import DeleteEvent from "../pages/event/DeleteEvent";
import DeleteFamilyMembers from "../pages/familyMembers/DeleteFamilyMembers";
import DeleteDevice from "../pages/support/DeleteDevice";
import DeletePopup from "./DeletePopup";

const AdminMainLayout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { auth, setAuth }: any = useAuth();
    const isAdmin = Cookies.get("isAdmin-my-cdir");
    const [navbarHeader, setNavbarHeader] = useState<any[]>([
        {
            name: "Help",
            link: "/help",
            isChildVisible: false,
            child: []
        },
        {
            name: "Tutorial",
            link: "/tutorial",
            isChildVisible: false,
            child: []
        },
        {
            name: "Blog",
            link: "/blog",
            isChildVisible: false,
            child: []
        },
        {
            name: "Faq",
            link: "admin-f-a-q",
            isChildVisible: false,
            child: []
        },
        {
            name: "My Account",
            link: "my-account",
            isChildVisible: false,
            child: []
        }
    ]);
    const [sideBar, setSideBar] = useState<any[]>([
        {
            name: "User Managment",
            link: "",
            isChildVisible: true,
            child: [
                {
                    name: "User",
                    link: "/admin/user",
                    forSuperAdmin: true
                },
                {
                    name: "User Roles",
                    link: "/admin/user-roles",
                    forSuperAdmin: true
                }
            ]
        },
        {
            name: "Organization",
            link: "",
            isChildVisible: true,
            child: [
                {
                    name: "Church",
                    link: "/admin/church",
                    forSuperAdmin: false
                },
                {
                    name: "SubGroup",
                    link: "/admin/subgroup",
                    forSuperAdmin: false
                },
                {
                    name: "Family",
                    link: "/admin/member",
                    forSuperAdmin: false
                },
                {
                    name: "Family Members",
                    link: "/admin/family-members",
                    forSuperAdmin: false
                },
                {
                    name: "Event",
                    link: "/admin/event",
                    forSuperAdmin: false
                }
            ]
        },
        {
            name: "Payment",
            link: "",
            isChildVisible: true,
            child: [
                {
                    name: "Payment Gateway",
                    link: "/admin/payment-gateway",
                    forSuperAdmin: true
                },
                {
                    name: "Credit Cards",
                    link: "/admin/credit-cards",
                    forSuperAdmin: true
                }
            ]
        },
        {
            name: "Support",
            link: "",
            isChildVisible: true,
            child: [
                {
                    name: "FAQ",
                    link: "/admin/faq",
                    forSuperAdmin: true,
                },
                {
                    name: "Login Devices",
                    link: "/admin/login-devices",
                    forSuperAdmin: false,
                }
            ]
        },
        ...(isAdmin && parseInt(isAdmin) === 1
            ? [
                {
                    name: "Billing",
                    link: "",
                    isChildVisible: true,
                    child: [
                        {
                            name: "Billing Details",
                            link: "/admin/billing",
                            forSuperAdmin: false,
                        }
                    ]
                },
            ]
            : []),
    ]);
    const [sideBarHide, setSideBarHide] = useState<boolean>(false);

    useEffect(() => {
        if (auth?.roles?.toString()?.toLowerCase().replaceAll(" ", "") === "admin") {
            const result = sideBar.map((item: any) => {
                const newChild = item?.child.filter((item: any) => !item.forSuperAdmin);
                if (newChild.length > 0) {
                    return { ...item, child: newChild };
                } else {
                    return null;
                }

            }).filter(undefinedItem => undefinedItem !== null);
            setSideBar(result);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth])

    useEffect(() => {
        const headerForAdmin = "admin";
        if (location.pathname.replaceAll("/", "").endsWith(headerForAdmin)) {
            if (auth?.roles?.toString()?.toLowerCase().replaceAll(" ", "") === "admin") {
                navigate("/admin/church", { replace: true });
            } else if (auth?.roles?.toString()?.toLowerCase().replaceAll(" ", "") === "superadmin") {
                navigate("/admin/user", { replace: true });
            }
        }

        const hideHeaderForAdminFaq = "admin-faq";

        if (location.pathname.replace("/", "").endsWith(hideHeaderForAdminFaq)) {
            setSideBarHide(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const logOut = () => {
        setAuth(null);
        Cookies.remove("role-my-cdir");
        Cookies.remove("token-my-cdir");
        Cookies.remove("groupId-my-cdir");
        Cookies.remove("userId-my-cdir");
        Cookies.remove("isAdmin-my-cdir");
        navigate("/sign-in-admin", { state: { from: location }, replace: true });
    }

    return (
        <section className="admin___layout__container">
            <Topbar
                navbarHeader={navbarHeader}
                setNavbarHeader={setNavbarHeader}
                logOut={logOut}
            />

            <div className="divider-view w-full mt-[72px]" />

            <section className="admin__body__container flex flex-row">
                <Sidebar
                    sideBar={sideBar}
                    setSideBar={setSideBar}
                    sideBarHide={sideBarHide}
                    setSideBarHide={setSideBarHide}
                />

                <section className={`admin__main__container transform duration-500 px-2 py-4 ${sideBarHide ? "z-0 ml-8 mr-2 min-w-[calc(100%-40px)]" : "-z-10 md:z-0 md:ml-64 min-w-[calc(100%-160px)] md:min-w-[calc(100%-256px)]"}`}>
                    <Outlet />
                </section>
            </section>

            {location.pathname.replace("/", "").includes("admin/user/addUser") && <AddUser />}
            {location.pathname.replace("/", "").includes("admin/user/editUser") && <EditUser />}
            <DeleteUser />

            <DeleteChurch />

            <DeleteSubGroup />

            <DeleteAdminMember />
            <DeleteAdminFamilyMember />
            <DeleteFamilyMembers />
            <DeleteFamilyMembers />

            <DeleteEvent />
            <DeleteDevice />
            <DeletePopup />
        </section>
    )
}

export default AdminMainLayout;