import { FaComment, FaFacebook, FaLinkedin, FaWhatsappSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoBookmark } from "react-icons/io5";
import { LuClock3 } from "react-icons/lu";
import HeroSection from "../../components/HeroSection";

const TopBenefitsOfMyChurchDirectory = () => {
    return (
        <div className="bg-[#fff] w-full flex flex-col">
            <HeroSection
                mainHeading="Top Benefits of Using My Church Directory for Your Church Community"
                backgroundImage="../images/church-community.png"
                // Img Source: https://unsplash.com/photos/a-church-with-a-chandelier-hanging-from-the-ceiling-Bcvt3N8lnkM
            />

            <section>
                <div className='flex justify-center items-center gap-4 mt-10'>
                    <div className="flex items-center gap-2">
                        <LuClock3 className="w-4" />
                        <p className="blog-publish-date">22/06/2024</p>
                    </div>

                    <div className="flex items-center gap-2">
                        <FaComment className="w-4" />
                        <p className="blog-publish-date">0 Comments</p>
                    </div>

                    <div className="flex items-center gap-2">
                        <IoBookmark />
                        <p className="blog-publish-date">850 Views</p>
                    </div>
                </div>
            </section>

            <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
                <div className="mx-auto max-w-screen-md">
                    <p>
                        Maintaining an up-to-date church directory is crucial for fostering community and ensuring smooth communication within your congregation. My Church Directory offers a comprehensive solution that provides numerous benefits for your church community. Let's explore the top advantages of using My Church Directory.
                    </p>

                    <div className="mt-4">
                        <h2 className="text-[#000] text-xl font-semibold mb-3">1. Easy Setup and Maintenance</h2>
                        <p>
                            Creating and maintaining a church directory has never been easier. With My Church Directory, you can set up your directory in just a few hours. The intuitive interface and easy-to-follow steps make it simple for administrators and volunteers to manage and update member information.
                        </p>
                    </div>

                    <div className="mt-4">
                        <h2 className="text-[#000] text-xl font-semibold mb-3">2. Secure Online Access</h2>
                        <p>
                            Your directory is accessible from anywhere with a secure online account. Members can log in to view the directory, update their information, and stay connected with the church community. This ensures that everyone has the most up-to-date contact information at their fingertips.
                        </p>
                    </div>

                    <div className="mt-4">
                        <h2 className="text-[#000] text-xl font-semibold mb-3">3. Customizable and Printable PDF Directories</h2>
                        <p>
                            Customizing your directory is a breeze with My Church Directory. You can create a beautiful, printable PDF version of your directory, complete with photos, contact information, and any other details you wish to include. This feature is perfect for those who prefer a physical copy of the directory.
                        </p>
                    </div>

                    <div className="mt-4">
                        <h2 className="text-[#000] text-xl font-semibold mb-3">4. Efficient Communication</h2>
                        <p>
                            My Church Directory enhances communication within your church. The integrated emailing service allows you to send unlimited emails to your members, keeping everyone informed about events, updates, and important announcements. Additionally, the optional text messaging service ensures that urgent messages reach your congregation instantly.
                        </p>
                    </div>

                    <div className="mt-4">
                        <h2 className="text-[#000] text-xl font-semibold mb-3">5. Enhanced Community Engagement</h2>
                        <p>
                            By providing easy access to member information and allowing members to connect with one another, My Church Directory fosters a sense of community. Members can find contact details, view photos, and even see group affiliations, helping them to connect and engage more deeply with the church community.
                        </p>
                    </div>

                    <div className="mt-4">
                        <h2 className="text-[#000] text-xl font-semibold mb-3">6. Continuous Updates</h2>
                        <p>
                            Keeping your directory up-to-date is simple with My Church Directory. Members can update their information online, ensuring that the directory always contains the most current details. This continuous updating process helps to maintain accuracy and reliability in your church records.
                        </p>
                    </div>

                    <div className="mt-4">
                        <p>
                            In conclusion, My Church Directory offers a powerful suite of tools to enhance the management and connectivity of your church community. From easy setup and secure online access to customizable directories and efficient communication, My Church Directory helps you create a vibrant, connected, and engaged church environment. Experience the benefits for yourself and take your church directory to the next level with My Church Directory.
                        </p>
                    </div>
                </div>
            </section>

            <section className="mb-8">
                <div className="max-w-screen-md mx-auto rounded-md flex flex-col lg:flex-row justify-center items-center lg:items-start gap-6">
                    <div className="flex flex-wrap gap-2">
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Church Directory</p>
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Membership Management</p>
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Community Connection</p>
                    </div>
                    <div className="flex flex-wrap justify-center lg:justify-start items-center gap-3">
                        <div className="flex items-center bg-[#42599E] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <FaFacebook />
                            </a>
                        </div>
                        <div className="flex items-center bg-[#55ACEE] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                                <FaXTwitter />
                            </a>
                        </div>
                        <div className="flex items-center bg-[#34AF23] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
                                <FaWhatsappSquare />
                            </a>
                        </div>
                        <div className="flex items-center bg-[#0798CF] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TopBenefitsOfMyChurchDirectory;