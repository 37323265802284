import axios from "axios"
import baseURL from "../config/api"

export const getUsername = (username: string) => {
    return getFetch(`users/DoesUserNameExist/${username}`)
}

export const getFamilyMemberUsername = (username: string) => {
    return getFetch(`FamilyMember/DoesFamilyMemberUserNameExist/${username}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}