type DetailsTagProps = {
    title: string;
    value: string;
    bg?: string;
}

const DetailsTag = ({ title, value, bg }: DetailsTagProps) => {
    return (
        <div className={`${bg ? "" : "flex-1"}`}>
            <h5 className="font-medium text-secondary">{title}</h5>
            <p
                style={{ backgroundColor: bg }}
                className={`${bg ? "px-2 py-0.5 w-fit rounded text-white font-medium" : "px-0 py-0 font-normal"} text-sm`}
            >
                {value}
            </p>
        </div>
    )
}

export default DetailsTag