import { FaComment, FaFacebook, FaLinkedin, FaWhatsappSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoBookmark } from "react-icons/io5";
import { LuClock3 } from "react-icons/lu";
import HeroSection from "../../components/HeroSection";

const EffortlesslyCreateAndMaintainChurchDirectory = () => {
    return (
        <div className="bg-[#fff] w-full flex flex-col">
            <HeroSection
                mainHeading="Effortlessly Create and Maintain Your Church Directory with My Church Directory"
                backgroundImage="./images/creating-church-directory.png"
                // Img Source: https://unsplash.com/photos/two-people-sitting-at-a-table-looking-at-a-tablet-SdlsfstOQZM
            />

            <section>
                <div className='flex justify-center items-center gap-4 mt-10'>
                    <div className="flex items-center gap-2">
                        <LuClock3 className="w-4" />
                        <p className="blog-publish-date">22/06/2024</p>
                    </div>

                    <div className="flex items-center gap-2">
                        <FaComment className="w-4" />
                        <p className="blog-publish-date">0 Comments</p>
                    </div>

                    <div className="flex items-center gap-2">
                        <IoBookmark />
                        <p className="blog-publish-date">850 Views</p>
                    </div>
                </div>
            </section>

            <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
                <div className="mx-auto max-w-screen-md">
                    <p>
                        Managing a church directory can be a daunting task, but it doesn't have to be. My Church Directory offers the advantages of a professional church photo directory without the accompanying hassle. With a 30-day free trial, you can explore our service at no cost, and if unsatisfied, cancel your subscription without any obligations.
                    </p>

                    <div className="mt-4">
                        <h2 className="text-[#000] text-xl font-semibold mb-3">Create and Share Your Directory in Hours</h2>
                        <p>
                            Gone are the days of printing and circulating outdated church directories. With My Church Directory, you can craft a refined photo membership directory in just a few hours. Our streamlined process ensures an easy-to-maintain and shareable church directory, keeping your community connected and up-to-date.
                        </p>
                    </div>

                    <div className="mt-4">
                        <h2 className="text-[#000] text-xl font-semibold mb-3">Stay Connected with Your Community</h2>
                        <p>
                            Maintaining an updated church directory is crucial for fostering a strong sense of community. My Church Directory provides the tools needed to keep member information current and accessible, allowing your congregation to stay connected effortlessly. This modern solution simplifies the management of your church directory, ensuring everyone stays informed and engaged.
                        </p>
                    </div>

                    <div className="mt-4">
                        <h2 className="text-[#000] text-xl font-semibold mb-3">Ease of Use and Accessibility</h2>
                        <p>
                            One of the key benefits of My Church Directory is its user-friendly interface. Whether you're updating member details or adding new photos, the process is intuitive and straightforward. Accessible from any device, My Church Directory ensures that your church's information is always at your fingertips, making updates and sharing effortless.
                        </p>
                    </div>

                    <div className="mt-4">
                        <p>
                            My Church Directory makes it simple to manage and update your church directory, keeping your congregation connected and informed. Embrace this modern solution and experience the ease and efficiency of My Church Directory today.
                        </p>
                    </div>
                </div>
            </section>

            <section className="mb-8">
                <div className="max-w-screen-md mx-auto rounded-md flex flex-col lg:flex-row justify-center items-center lg:items-start gap-6">
                    <div className="flex flex-wrap gap-2">
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Church Directory</p>
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Membership Management</p>
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Community Connection</p>
                    </div>
                    <div className="flex flex-wrap justify-center lg:justify-start items-center gap-3">
                        <div className="flex items-center bg-[#42599E] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <FaFacebook />
                            </a>
                        </div>
                        <div className="flex items-center bg-[#55ACEE] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                                <FaXTwitter />
                            </a>
                        </div>
                        <div className="flex items-center bg-[#34AF23] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
                                <FaWhatsappSquare />
                            </a>
                        </div>
                        <div className="flex items-center bg-[#0798CF] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default EffortlesslyCreateAndMaintainChurchDirectory;
