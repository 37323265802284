import { Link } from "react-router-dom"

const StartYourFreeTrial = () => {

    return (
        <section className="py-8 px-2 md:px-12 bg-gray-200">
            <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-9/12 p-6">
                    <h1 className="text-custom-blue text-3xl md:text-4xl font-normal mb-5 text-left md:text-left">
                        Get started today! End your subscription whenever you choose.
                    </h1>
                    <p className="font-normal text-left pb-2 md:text-left text-black">
                        Our service is centered around flexibility. Should you need
                        to modify or end your membership, it's a straightforward process.
                        Selecting either a yearly or monthly plan grants you the freedom
                        to tailor it according to your budget. Moreover, you can cancel
                        whenever required with a simple click of a button.
                    </p>
                </div>

                <div className="w-full md:w-1/2 flex justify-center items-center">
                    <Link
                        to={"/free-trial"}
                        className="bg-blue-700 hover:bg-slate-700 text-white font-semibold py-3 px-6 md:py-4 md:px-8 rounded uppercase"
                        onClick={() => ("/free-trial")}>
                        Start Your FREE Trial
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default StartYourFreeTrial