import { Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { MdSubdirectoryArrowRight } from "react-icons/md";

type Props = {
    sideBar: any[];
    setSideBar: (value: any[]) => void;
    sideBarHide: boolean;
    setSideBarHide: (value: boolean) => void;
}

const Sidebar = ({ sideBar, setSideBar, sideBarHide, setSideBarHide }: Props) => {
    const location = useLocation();
    const hideHeaderForAdminFaq = "admin-f-a-q";
    
    if (location.pathname.replace("/", "").endsWith(hideHeaderForAdminFaq)) {
        return <></>;
    }

    return (
        <nav className={`admin__sidebar__container w-40 md:w-64 min-h-[calc(100vh-72px)] bg-blue-100 fixed left-0 py-6 px-4 transform duration-500 ${sideBarHide ? "-translate-x-full" : "translate-x-0"}`}>
            <div className="relative">
                <div
                    className={`absolute transform duration-300 ${sideBarHide ? "-right-12" : "-right-6"} top-0 h-6 w-6 p-[6px] cursor-pointer bg-[#2B3998] flex items-center justify-center rounded-full`}
                    onClick={() => setSideBarHide(!sideBarHide)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" className="w-4 h-4" viewBox="0 0 55.752 55.752">
                        <path
                            d="M43.006 23.916a5.36 5.36 0 0 0-.912-.727L20.485 1.581a5.4 5.4 0 0 0-7.637 7.638l18.611 18.609-18.705 18.707a5.398 5.398 0 1 0 7.634 7.635l21.706-21.703a5.35 5.35 0 0 0 .912-.727 5.373 5.373 0 0 0 1.574-3.912 5.363 5.363 0 0 0-1.574-3.912z"
                            data-original="#000000" />
                    </svg>
                </div>
            </div>

            <div className="overflow h-full mt-10">

                {sideBar.map((item, index) => (
                    <Fragment key={index}>
                        {
                            item.link === "" ? (
                                <div className="flex cursor-pointer group gap-4 py-2 my-1 px-1 md:px-4"
                                    onClick={() => {
                                        const result: any[] = [...sideBar];
                                        result[index] = {
                                            ...result[index],
                                            isChildVisible: !item.isChildVisible,
                                        };
                                        setSideBar(result);
                                    }}>
                                    <h6 className="text-gray-600 group-hover:text-black text-sm font-bold flex-1">{item.name}</h6>
                                    {item.child.length !== 0 &&
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`w-3 fill-gray-600 group-hover:fill-black ${item.isChildVisible ? "rotate-0" : "-rotate-90"}`}
                                            viewBox="0 0 451.847 451.847">
                                            <path
                                                d="M225.923 354.706c-8.098 0-16.195-3.092-22.369-9.263L9.27 151.157c-12.359-12.359-12.359-32.397 0-44.751 12.354-12.354 32.388-12.354 44.748 0l171.905 171.915 171.906-171.909c12.359-12.354 32.391-12.354 44.744 0 12.365 12.354 12.365 32.392 0 44.751L248.292 345.449c-6.177 6.172-14.274 9.257-22.369 9.257z"
                                                data-original="#000000" />
                                        </svg>}
                                </div>
                            ) : (
                                <Link to={item.link} className="flex cursor-pointer group py-2 my-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-[18px] h-[18px] mr-3"
                                        viewBox="0 0 512 512">
                                        <path
                                            d="M437.02 74.98C388.668 26.63 324.379 0 256 0S123.332 26.629 74.98 74.98C26.63 123.332 0 187.621 0 256s26.629 132.668 74.98 181.02C123.332 485.37 187.621 512 256 512s132.668-26.629 181.02-74.98C485.37 388.668 512 324.379 512 256s-26.629-132.668-74.98-181.02zM111.105 429.297c8.454-72.735 70.989-128.89 144.895-128.89 38.96 0 75.598 15.179 103.156 42.734 23.281 23.285 37.965 53.687 41.742 86.152C361.641 462.172 311.094 482 256 482s-105.637-19.824-144.895-52.703zM256 269.507c-42.871 0-77.754-34.882-77.754-77.753C178.246 148.879 213.13 114 256 114s77.754 34.879 77.754 77.754c0 42.871-34.883 77.754-77.754 77.754zm170.719 134.427a175.9 175.9 0 0 0-46.352-82.004c-18.437-18.438-40.25-32.27-64.039-40.938 28.598-19.394 47.426-52.16 47.426-89.238C363.754 132.34 315.414 84 256 84s-107.754 48.34-107.754 107.754c0 37.098 18.844 69.875 47.465 89.266-21.887 7.976-42.14 20.308-59.566 36.542-25.235 23.5-42.758 53.465-50.883 86.348C50.852 364.242 30 312.512 30 256 30 131.383 131.383 30 256 30s226 101.383 226 226c0 56.523-20.86 108.266-55.281 147.934zm0 0"
                                            data-original="#000000" />
                                    </svg>
                                    <h6 className="text-gray-600 group-hover:text-black text-sm font-bold px-4 flex-1">{item.name}</h6>
                                    {item.child.length !== 0 &&
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`w-3 fill-gray-600 group-hover:fill-black ${item.isChildVisible ? "rotate-0" : "-rotate-90"}`}
                                            viewBox="0 0 451.847 451.847">
                                            <path
                                                d="M225.923 354.706c-8.098 0-16.195-3.092-22.369-9.263L9.27 151.157c-12.359-12.359-12.359-32.397 0-44.751 12.354-12.354 32.388-12.354 44.748 0l171.905 171.915 171.906-171.909c12.359-12.354 32.391-12.354 44.744 0 12.365 12.354 12.365 32.392 0 44.751L248.292 345.449c-6.177 6.172-14.274 9.257-22.369 9.257z"
                                                data-original="#000000" />
                                        </svg>}
                                </Link>
                            )
                        }

                        {item.isChildVisible && (
                            <ul className="space-y-2 mt-1 pl-4">
                                {
                                    item.child.map((childItem: any, childIndex: number) => (
                                        <li key={childIndex}>
                                            <Link to={childItem.link}
                                                className="text-gray-600 hover:text-black text-sm flex items-center hover:bg-blue-50 rounded-md px-4 py-1">
                                                <MdSubdirectoryArrowRight />
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-[18px] h-[18px] mr-3"
                                            viewBox="0 0 512 512">
                                            <path
                                                d="M437.02 74.98C388.668 26.63 324.379 0 256 0S123.332 26.629 74.98 74.98C26.63 123.332 0 187.621 0 256s26.629 132.668 74.98 181.02C123.332 485.37 187.621 512 256 512s132.668-26.629 181.02-74.98C485.37 388.668 512 324.379 512 256s-26.629-132.668-74.98-181.02zM111.105 429.297c8.454-72.735 70.989-128.89 144.895-128.89 38.96 0 75.598 15.179 103.156 42.734 23.281 23.285 37.965 53.687 41.742 86.152C361.641 462.172 311.094 482 256 482s-105.637-19.824-144.895-52.703zM256 269.507c-42.871 0-77.754-34.882-77.754-77.753C178.246 148.879 213.13 114 256 114s77.754 34.879 77.754 77.754c0 42.871-34.883 77.754-77.754 77.754zm170.719 134.427a175.9 175.9 0 0 0-46.352-82.004c-18.437-18.438-40.25-32.27-64.039-40.938 28.598-19.394 47.426-52.16 47.426-89.238C363.754 132.34 315.414 84 256 84s-107.754 48.34-107.754 107.754c0 37.098 18.844 69.875 47.465 89.266-21.887 7.976-42.14 20.308-59.566 36.542-25.235 23.5-42.758 53.465-50.883 86.348C50.852 364.242 30 312.512 30 256 30 131.383 131.383 30 256 30s226 101.383 226 226c0 56.523-20.86 108.266-55.281 147.934zm0 0"
                                                data-original="#000000" />
                                        </svg> */}
                                                <span>{childItem.name}</span>
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        )}
                    </Fragment>
                ))}
            </div>
        </nav>
    )
}

export default Sidebar