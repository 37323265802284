type HeroSectionProps = {
    mainHeading: string;
    subHeading?: string;
    backgroundImage?: string;
  }
  
  const HeroSection = ({ mainHeading, subHeading, backgroundImage }: HeroSectionProps) => {
    return (
      <section
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "100% 80vh",
          backgroundColor: !backgroundImage ? "#181842" : "transparent",
          boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.5)"
        }}
        className="hero--container relative flex flex-col justify-center w-full h-[50vh] bg-no-repeat bg-fixed md:bg-origin-border md:h-[60vh] pt-[65px]">
        <h1 className="text-center text-white px-2 text-2xl md:text-5xl font-bold uppercase relative z-10">{mainHeading}</h1>
        {subHeading && <h2 className="text-center text-white md:text-2xl font-bold uppercase my-8 mx-8 relative z-10">{subHeading}</h2>}
      </section>
    );
  };
  
  export default HeroSection;