import React, { useState } from 'react'
import { BsFillCreditCard2FrontFill } from 'react-icons/bs';

type CreditCardViewProps = {
    amountDue: number;
    account?: boolean;
}

const CreditCardView = ({ amountDue, account = false }: CreditCardViewProps) => {
    const [creditCardDetails, setCreditCardDetails] = useState<any>({
        cardNumber: "",
        expiryDate: "",
        cvv: ""
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

    }

    return (
        <div className="w-full mt-8">
            <h4 className="font-bold">{account ? "Pay" : "Pay this Invoice"}</h4>
            <p className="text-sm">{account ? "Make payment for this account" : "Make payment for this invoice"}</p>

            <form className="mt-2 space-y-2" onSubmit={handleSubmit}>
                <div className="relative flex items-center">
                    <input
                        name="cardNumber"
                        type="text"
                        className="bg-transparent w-full text-sm rounded-md border border-secondary pl-2 pr-[40px] py-3 outline-none placeholder:text-[#bbb]"
                        placeholder="Card number"
                        value={creditCardDetails.cardNumber}
                        onChange={(e) => setCreditCardDetails({ ...creditCardDetails, cardNumber: e.target.value })}
                        required />

                    <BsFillCreditCard2FrontFill className="w-[18px] h-[18px] absolute right-4 text-[#bbbbbb]" />
                </div>

                <div className="flex justify-between items-center gap-x-2">
                    <div className="relative flex-1 flex items-center">
                        <input
                            name="cardNumber"
                            type="text"
                            className="bg-transparent w-full text-sm rounded-md border border-secondary px-2 py-3 outline-none placeholder:text-[#bbb]"
                            placeholder="MM/YY"
                            value={creditCardDetails.expiryDate}
                            onChange={(e) => setCreditCardDetails({ ...creditCardDetails, expiryDate: e.target.value })}
                            required />
                    </div>

                    <div className="relative flex-1 flex items-center">
                        <input
                            name="cardNumber"
                            type="text"
                            className="bg-transparent w-full text-sm rounded-md border border-secondary px-2 py-3 outline-none placeholder:text-[#bbb]"
                            placeholder="CVV"
                            value={creditCardDetails.cvv}
                            onChange={(e) => setCreditCardDetails({ ...creditCardDetails, cvv: e.target.value })}
                            required />
                    </div>
                </div>

                <div className="pt-4">
                    <button
                        type='button'
                        className="text-white bg-secondary rounded-md border border-secondary px-2 py-2 w-full text-center"
                        onClick={() => { }}

                    >
                        {`Pay $${amountDue?.toFixed(2)}`}
                    </button>
                </div>

            </form>
        </div>
    )
}

export default CreditCardView
