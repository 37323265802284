import axios from "axios";
import baseURL from "../config/api";

export const post_ForgotPasswordAdmin = (emailAddress: string) => {
    return axios({
        url: `${baseURL}AuthUser/ForgotPassword?userEmail=${encodeURIComponent(emailAddress)}`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: {}
    }).then(response => response.data);
}

export const post_ForgotPasswordMember = (emailAddress: string) => {
    return axios({
        url: `${baseURL}AuthMember/ForgotPassword?userEmail=${encodeURIComponent(emailAddress)}`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: {}
    }).then(response => response.data);
}