
const ThankYou = () => {
    
  return (
    <div className="flex flex-col items-center justify-center h-5/6 py-40 md:py-52 bg-gray-100">
      <h2 className="text-2xl md:text-4xl font-bold mb-6 text-center text-[#2B3998]">Thank You for Trying Us!</h2>
      <p className="text-lg text-center">
                You can login with your credentials from Admin Sign-in.
            </p>
    </div>
  );
};

export default ThankYou;
