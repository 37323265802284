import React from "react";

interface EmailValidatedComponentProps {
  text: string;
  setText: (value: string) => void;
  className?: any;
}

const EmailValidatedComponent = ({
  text,
  setText,
  className = {
    parentDiv: "",
    label: "font-semibold text-sm",
    input: "w-full rounded py-2 px-4 mt-2 border-2 text-sm text-black outline-tertiary"
  }
}: EmailValidatedComponentProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setText(value);
    const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/gm.test(value);
    e.target.setCustomValidity(isValid ? "" : "Please enter a valid email");
  };

  return (
    <div className={className.parentDiv}>
      <label className={className.label}>Email</label>
      <input
        type="email"
        placeholder="Enter Email"
        className={className.input}
        value={text}
        onChange={handleChange}
      />
    </div>
  );
};

export default EmailValidatedComponent;