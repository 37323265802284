import { useState } from 'react'
import { NavLink } from 'react-router-dom';
import useActiveNavbar from '../hook/useActiveNavbar';
import { IoMdArrowDropdown } from "react-icons/io";

type Props = {
    navbarHeader: any[];
    setNavbarHeader: (value: any[]) => void;
    logOut: () => void;
}

const Topbar = ({ navbarHeader, setNavbarHeader, logOut }: Props) => {
    const { selected, setSelected }: any = useActiveNavbar();
    const [toggle, setToggle] = useState<boolean>(false);

    return (
        <header className="header-container transition-all duration-500 fixed right-0 left-0 z-50 top-0">
            <div className={`header-top bg-[#0081CA] flex justify-between w-full max-w-screen-3xl mx-auto font-semibold h-16 px-6 sm:px-2 lg:px-14 xl:px-26`}>
                <div className="flex justify-center items-center gap-3 md:gap-4 mr-5 w-1/2 md:w-1/6 py-2">
                    <NavLink to="/" className="z-10">
                        <img className="logo-img h-10 md:h-12" src="./../../images/logo-no-background.png" alt="logo" />
                    </NavLink>

                </div>
                <div className="flex items-center sm:gap-3 md:gap-8 md:mr-10 lg:mr-20">
                    {navbarHeader.map((data, index) => (
                        data.child.length === 0 ?
                            <NavLink
                                to={data.link}
                                className="group h-16 flex justify-center flex-col"
                                key={index}
                                onClick={() => setSelected(data.link)}
                            >
                                <span className={`text-center font-semibold text-white cursor-pointer pt-3 md:flex md:items-center hidden`}>
                                    {data.name}
                                </span>
                                <div
                                    className={`w-full h-0.5 ${selected.endsWith(data.link)
                                        ? `bg-[#2B3998] opacity-80`
                                        : `group-hover:bg-white group-hover:opacity-80`
                                        }`}
                                />
                            </NavLink>
                            :
                            <div
                                key={index}
                                className="with-child-desktop-view group relative hidden md:flex"
                            >
                                <button
                                    className="flex flex-row justify-center items-center h-16 relative z-10"
                                    onClick={() =>
                                        setNavbarHeader(
                                            navbarHeader.map((item, i) => (
                                                i === index ? {
                                                    ...item,
                                                    isChildVisible: !item.isChildVisible
                                                } : {
                                                    ...item,
                                                    isChildVisible: false
                                                }
                                            ))
                                        )
                                    }>
                                    <div className="group flex flex-col">
                                        <span className={`text-center font-semibold text-white cursor-pointer pt-3 md:flex md:items-center hidden`}>
                                            {data.name}
                                        </span>
                                        <div
                                            className={`w-full h-0.5 ${data.child.some((item: any) => selected.endsWith(item.link))
                                                ? `bg-[#2B3998] opacity-80`
                                                : `group-hover:bg-white group-hover:opacity-80`
                                                }`}
                                        />
                                    </div>
                                    <IoMdArrowDropdown className="absolute top-[45%] -right-5 text-lg text-white" />
                                </button>

                                {data.isChildVisible &&
                                    <div className={`aboutus-container-desktop absolute z-[1000] mt-16 left-0`}>
                                        <ul className={`w-fit list-none overflow-hidden border-[#808080] bg-[#F4F5FA] clip-padding text-left text-base shadow-lg`}>
                                            <li className="block w-full whitespace-nowrap bg-transparent py-4">
                                                {data.child.map((item: any, itemIndex: number) => (
                                                    <NavLink
                                                        to={item.link}
                                                        key={itemIndex}
                                                        className="hover:bg-gray-200 w-full whitespace-nowrap bg-transparent flex flex-col justify-center items-start gap-0.5 "
                                                        onClick={() => {
                                                            setNavbarHeader(
                                                                navbarHeader.map((item) => ({
                                                                    ...item,
                                                                    isChildVisible: false
                                                                }))
                                                            );
                                                            setSelected(item.link);
                                                        }}
                                                    >
                                                        <span className="px-6 py-2 text-sm text-center font-semibold text-black opacity-80 cursor-pointer md:flex md:items-center hidden">
                                                            {item.name}
                                                        </span>
                                                        <div
                                                            className={`w-full h-0.5 ${selected.endsWith(item.link)
                                                                ? `bg-[#2B3998] opacity-80`
                                                                : `group-hover:bg-transparent group-hover:opacity-80`
                                                                }`}
                                                        />

                                                    </NavLink>
                                                ))}
                                            </li>
                                        </ul>
                                    </div>
                                }

                            </div>


                    ))}

                    <div className="flex gap-0.5 lg:gap-2">
                        <button
                            className="hidden md:block bg-blue-500 hover:bg-blue-700 px-4 py-1.5 rounded-md font-semibold text-white"
                            onClick={logOut}
                        >
                            LogOut
                        </button>
                    </div>

                    <button
                        className="w-12 h-12 relative focus:outline-none md:hidden"
                        onClick={() => setToggle(!toggle)}
                    >
                        <div className="block w-5 absolute left-6 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                            <span
                                className={`block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out ${toggle ? "rotate-45" : "-translate-y-1.5"
                                    }  
    `}
                            ></span>
                            <span
                                className={`block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out ${toggle && "opacity-0"
                                    }`}
                            ></span>
                            <span
                                className={`block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out ${toggle ? "-rotate-45" : "translate-y-1.5"
                                    }`}
                            ></span>
                        </div>
                    </button>
                </div>

                <div className={`absolute w-full top-[65px] left-0 right-0 block md:hidden z-20 transform duration-500 ${toggle ? "translate-x-0" : "translate-x-full"}`}>
                    <div
                        className="bg-white p-3 text-start flex flex-col">
                        {navbarHeader.map((data, index) => (
                            data.child.length === 0 ?
                                <NavLink
                                    to={data.link}
                                    className="group p-2"
                                    key={index}
                                    onClick={() => {
                                        setSelected(data.link);
                                        setToggle(false);
                                    }}>
                                    <span className={`text-md font-bold ${selected === data.link ? "text-[#2B3998]" : "text-black"}`}>{data.name}</span>
                                </NavLink>
                                :
                                <div
                                    className="py-2"
                                    key={index}>
                                    <button
                                        className="group px-2 w-full flex flex-row justify-between"
                                        onClick={() =>
                                            setNavbarHeader(
                                                navbarHeader.map((item, i) => (
                                                    i === index ? {
                                                        ...item,
                                                        isChildVisible: !item.isChildVisible
                                                    } : {
                                                        ...item,
                                                        isChildVisible: false
                                                    }
                                                ))
                                            )
                                        }>
                                        <span className={`w-1/2 text-start font-bold ${data.child.some((item: any) => item.link === selected) ? "text-[#2B3998]" : "text-black"}`}>{data.name}</span>

                                        <span className="group text-md font-bold text-slate-500 w-1/2 flex flex-row justify-end gap-0.5"
                                        >
                                            <span className={`block transform duration-1000 ease-in-out ${data.isChildVisible ? "w-4" : "w-1"} h-1 ${data.child.some((item: any) => item.link === selected) ? "bg-[#2B3998]" : "bg-black"} rounded-full`}></span>
                                            <span className={`block transform duration-500 ease-in-out ${data.isChildVisible ? "w-0" : "w-1"} h-1 ${data.child.some((item: any) => item.link === selected) ? "bg-[#2B3998]" : "bg-black"} rounded-full`}></span>
                                            <span className={`block transform duration-500 ease-in-out ${data.isChildVisible ? "w-0" : "w-1"} h-1 ${data.child.some((item: any) => item.link === selected) ? "bg-[#2B3998]" : "bg-black"} rounded-full`}></span>
                                        </span>
                                    </button>

                                    <div
                                        style={{ display: data.isChildVisible ? "flex" : "none" }}
                                        className={`flex flex-col`}>
                                        {data.child.map((item: any, itemIndex: number) => (
                                            <NavLink
                                                to={item.link}
                                                className="group p-2"
                                                key={itemIndex}
                                                onClick={() => {
                                                    setSelected(item.link);
                                                    setNavbarHeader(
                                                        navbarHeader.map((item) => ({
                                                            ...item,
                                                            isChildVisible: false
                                                        }))
                                                    );
                                                    setToggle(false);
                                                }}>
                                                <span className={`text-md ${selected === item.link ? "text-[#2B3998]" : "text-black"}`}>{item.name}</span>
                                            </NavLink>
                                        ))}
                                    </div>
                                </div>
                        ))}

                        <div className="flex flex-row gap-8">
                            <button
                                className="w-full bg-blue-500 hover:bg-blue-700 px-4 py-2 rounded-md text-white text-sm font-semibold mt-4"
                                onClick={() => {
                                    setToggle(false);
                                    logOut();
                                }}
                            >
                                LogOut
                            </button>
                        </div>


                    </div>
                    <div className="divider bg-[#2B3998] w-full h-1" />
                </div>

            </div>

            <div className="divider bg-[#2B3998] w-full h-2 shadow-inner" />
        </header>
    )
}

export default Topbar