import StartYourFreeTrial from "../../components/StartYourFreeTrial";
import { BsQuestionLg } from "react-icons/bs";
import TryMyCdir from "../../components/TryMyCdir";

const membership = [
  {
    name: "Produce a Printable PDF Version of Your Directory",
    description: "Use our online member directory template to create a printable version in PDF format of the full directory in 8 1/2 x 11 or booklet-size format (or both). You can choose between full color or black and white, arrange the pages in the order you want, plus select the font options you need (perfect for organizations with elderly members who may need a larger printed roster).",
  },
  {
    name: "Data Import Tool",
    description: "If you would like to import your member data, you can do that easily with the import tool. With this tool, you can upload the majority of your directory data for Families and Individuals. You can create a .CSV file, choose photos to upload, map your fields, upload and even undo an import. Read more about the options and advantages of the data and photo import tool at our extensive help document.",
  },
  {
    name: "Easy Forms to Enter Family and Individual Contact Information",
    description: "We take you step-by-step to add any member information details such as photos, addresses, email addresses, phone numbers, birthdays, anniversaries, membership status, and more.",
  },
  {
    name: "Create a Cover Page",
    description: "Use our custom page template to upload a cover photo and add your church's details to make a striking cover for your directory.",
  },
  {
    name: "Pastor Letter, Staff and Activity Pages",
    description: "Our easy-to-use online templates walk you through creating a Pastor letter, staff and activity pages. Staff pages are customizable so you can feature church and various ministry leaders, laypersons and volunteers.",
  },
  {
    name: "Upload Custom Pages",
    description: "With My Church Directory, you can upload as many custom PDFs pages as you need. This is perfect for maps, diagrams, budget reports, church history, advertising and more.",
  },
  {
    name: "Various Page Layout Options",
    description: "Choose from several layout options on how your members' information and photos are displayed within your printable PDF directory. Choose the page sequence of your printable directory, too.",
  },
  {
    name: "Anniversary and Birthday Listings",
    description: "As you enter members' birthdays and anniversaries, we automatically compile a listing by month that can be included in your printed directory PDF or viewed on the online members' directory website or on the mobile app.",
  },
  {
    name: "Create and Manage Groups",
    description: "Organize the various roles of your church members such as Sunday school teachers, elders, deacons, prayer groups, preschool teachers and more and view group members at a glance. With the mobile apps or online members' directory website, a group leader will always be able to put a face to a name.",
  },
  {
    name: "Free Online Members' Directory Website",
    description: "Enable your online membership directory to be viewed by members on our online members' directory website. Each member creates their own unique login with a verified email address and password.",
  },
  {
    name: "Free Mobile Apps for Your Church Members",
    description: "Take your church directory on the go using a mobile device. Call, text or email other members from within the app.",
  },
  {
    name: "Free Emailing Service",
    description: "Send unlimited emails to your fellow members at no additional charge from your administrator account. This is a great way to stay connected with your church community.",
  },
  {
    name: "Optional Text Messaging Service",
    description: "Pay for text credits as you need them to send text message blasts from the administrator to your congregation. Text credits start as a little as $5.00",
  },
  {
    label: "PREMIUM FEATURE",
    name: "Custom Color and Branding",
    description: "Add your church or organizational logo. Set your brand colors to display on the mobile apps and online members' website. Learn more about premium features.",
  },
  {
    name: "Customize Family Label",
    description: "The Family Label is now customizable, making it useful for residential facilities, charitable organizations, and other groups to tailor the menu language to suit their individual needs.",
  },
  {
    label: "PREMIUM FEATURE",
    name: "Unlock Additional Customize Menu Labels",
    description: "Our Custom Menu Labels feature lets you tailor your directory's language to better align with your organization's unique characteristics.",
  },
];

const administratorFeatures = [
  {
    name: "Manage Editors & Volunteers",
    description: "Empower your directory management by enlisting up to three additional editors (or volunteers) to assist in maintaining your directory's accuracy and currency. Need more than three editors? Check out the premium membership to upgrade to unlimited editors.",
  },
  {
    name: "Enable Mobile Apps & Online Members' Directory",
    description: "Once enabled, your membership directory can be viewed online or on our free mobile apps. Members just create a unique login using their email address and password.",
  },
  {
    name: "Approve or Reject Members' Submission Requests",
    description: "Review members' submissions for acceptance in the directory. If something is questionable, simply reject it.",
  },
  {
    name: "Invite Members to Upload Their Information",
    description: "Need photos or other information from a new member? Just send them an email directly from their family or individual entry in your administrator account.",
  },
  {
    name: "Maintain Your Account Information",
    description: "Update your contact name, address, payment information and more online with your administrator access.",
  },
  {
    name: "See Who has Logged Into the App",
    description: "Access who has logged into the directory via the mobile app, confirm their email address or see the date of their last successful login. Perfect for troubleshooting login issues with your members.",
  },
  {
    label: "PREMIUM FEATURE",
    name: "Unlimited Editors",
    description: "The power of community comes alive with our Unlimited Editors feature. If you need more than 3 editors or volunteers on your account, consider subscribing to the Premium subscription. Learn more about this subscription option here.",
  },
];

const volunteerFeatures = [
  {
    name: "Add or Edit Directory Information",
    description: "You can add or edit families, individuals or staff members, update the Cover Page or Pastor Letter and add activity pages. You have the ability to make changes to your directory to help keep it accurate.",
  },
  {
    name: "Request Photos from Members",
    description: "Missing a member's photo? Just email them directly from the directory account to send a photo for inclusion in the directory!",
  },
  {
    name: "Review Members' Update Submissions",
    description: "Check members' submissions for accuracy and accept them to keep your directory current. Or if you choose, you can reject any submission you feel does not fit your church's directory standards",
  },
  {
    name: "Receive Submission Notifications",
    description: "If you have new submissions from members, you will be sent an email each morning. You can then view and approve the changes.",
  },
  {
    name: "Choose Layout and Create a PDF Directory",
    description: "You can choose which pages to include in your printable directory PDF and choose the order in which they appear.",
  },
];

const memberFeatures = [
  {
    name: "Download the Free Mobile App",
    description: "If your church chooses to enable this feature, download the mobile app from the Google or Apple stores. Create a login using your email address in the directory and a personal password. Once verified, you can view the directory from your mobile phone or tablet.",
  },
  {
    name: "View the Directory online",
    description: "If your church enables this feature, visit the members' website and create a unique login using your email address found in the directory and a personal password. Once verified, you can view the directory from any computer or device with an internet connection.",
  },
  {
    name: "Submit Changes to Your Personal Information",
    description: "As your life changes, you can submit updates to your information listed in the church directory. Log in to the mobile app or online members' directory website to edit and submit any changes for approval from your directory administrator.",
  },
  {
    name: "Upload Your Own Photo for Approval",
    description: "As a member, you may submit an updated photo for approval as often as you'd like. If you've had new family photos taken or have a favorite selfie you'd like to share, you can upload the photo in just a few clicks.",
  },
  {
    name: "Stay in Touch With Other Members",
    description: "Search for a family or member, and then email, call or text or launch a map with directions — right from your device. The apps also provide listings for birthdays and anniversaries, so you can keep in touch on special days without having to look up phone numbers or addresses.",
  },
];

const ChurchDirectoryFeatures = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url('../images/church-dir-features.jpg')`,
          backgroundSize: "100% 80vh",
        }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border md:h-[60vh]"
      ></section>

      <section className="w-full items-center justify-center">
        <div className="max-w-screen-lg mx-auto py-4">
          <h1 className="text-[#2B3998] font-[cursive] text-4xl pt-2 mb-4 text-center md:text-center">
            Revolutionary Church Directory Innovations
          </h1>

          <div className="flex flex-col md:flex-row">
            <div className="px-8 md:px-1 md:pr-2 py-6">
              <p className="text-center md:text-left">
                Say goodbye to the intricate dealings with photo directory firms.
                My Church Directory offers the advantages of a professional
                church photo directory without the accompanying hassle.
                Furthermore, you can explore our service at no cost for a 30-day trial.
                If unsatisfied, you can cancel your subscription without any obligations.
                <br /><br />
                The era of printing and circulating outdated church directories
                is a thing of the past. Within a few hours, you can craft a refined
                photo membership directory by yourself. My Church Directory streamlines
                the process, ensuring an easy-to-maintain and shareable church directory.
                <br /><br />
                My Church Directory equips you with the tools to fashion an
                online membership directory swiftly, eliminating lengthy waiting periods.
                Our program encompasses all the features provided by major church directory
                software providers, offered at a significantly reduced cost. Collaborating
                with volunteers and your current members ensures its continual updating as
                your church community evolves over time.
                <br /><br />
                Take a glance at the features included in our online directory for
                constructing your membership directory. Explore the available options
                for administrators, volunteers, and church members.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="py-12 px-2 md:px-12 bg-gray-200">
          <h2 className="text-custom-blue text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-semibold text-left px-4 mb-4 pt-1 md:pt-10">
            What's Covered in Your Membership.
          </h2>

          <div className="flex flex-wrap md:flex-row">
            <div className="body--container p-4 md:w-1/2 lg:w-1/2 xl:w-2/4">
              <p className="text-left pb-2 p-0.5">
                You have the ability to generate your online membership directory
                from virtually any location, leveraging your secure online
                administrative account. We firmly hold the belief that maintaining
                an updated membership directory should be a straightforward process,
                ensuring users can easily stay connected with one another.
              </p>
            </div>
          </div>

          <div className="flex flex-wrap md:flex-row pt-1">
            {membership.map((item, index) => (
              <div
                key={index}
                className="body--container p-4 md:w-1/2 lg:w-1/2 xl:w-2/4"
              >
                <div className="flex flex-col items-left mb-1 pt-2 md:pt-1">
                  <h3 className="text-custom-blue text-lg md:text-xl p-1">
                    {item.label}
                  </h3>
                  <h3 className="text-custom-blue text-lg md:text-xl mb-2 p-1">
                    {item.name}
                  </h3>
                  <p className="text-left p-0.5 text-black">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <TryMyCdir />

      <section>
        <div className="py-12 px-2 md:px-12">
          <h2 className="text-custom-blue text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-semibold text-left px-4 mb-4 pt-1 md:pt-10">
            Admin Features
          </h2>

          <div className="flex flex-wrap md:flex-row">
            <div className="body--container p-4 md:w-1/2 lg:w-1/2 xl:w-2/4">
              <p className="text-left pb-2 p-0.5">
                As the directory administrator and account holder, your foremost
                responsibility involves managing the membership of your directory.
                Apart from creating or editing any content within your directory,
                an administrator possesses the authority to:
              </p>
            </div>
          </div>

          <div className="flex flex-wrap md:flex-row pt-1">
            {administratorFeatures.map((item, index) => (
              <div
                key={index}
                className="body--container p-4 md:w-1/2 lg:w-1/2 xl:w-2/4"
              >
                <div className="flex flex-col items-left mb-1 pt-2 md:pt-1">
                  <h3 className="text-custom-blue text-lg md:text-xl p-1">
                    {item.label}
                  </h3>
                  <h3 className="text-custom-blue text-lg md:text-xl mb-2 p-1">
                    {item.name}
                  </h3>
                  <p className="text-left p-0.5 text-black">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section>
        <div className="py-12 px-2 md:px-12 bg-gray-200">
          <h2 className="text-custom-blue text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-semibold text-left px-4 mb-4 pt-1 md:pt-10">
            Features of Volunteer
          </h2>

          <div className="flex flex-wrap md:flex-row">
            <div className="body--container p-4 md:w-1/2 lg:w-1/2 xl:w-2/4">
              <p className="text-left pb-2 p-0.5">
                In your role as a directory editor, you are authorized to aid
                the directory administrator in crafting, upholding, and distributing
                the directory. Editors, alongside administrators, hold the capability to:
              </p>
            </div>
          </div>

          <div className="flex flex-wrap md:flex-row pt-1">
            {volunteerFeatures.map((item, index) => (
              <div
                key={index}
                className="body--container p-4 md:w-1/2 lg:w-1/2 xl:w-2/4"
              >
                <div className="flex flex-col items-left mb-1 pt-2 md:pt-1">
                  <h3 className="text-custom-blue text-lg md:text-xl mb-2 p-1">
                    {item.name}
                  </h3>
                  <p className="text-left p-0.5 text-black">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <TryMyCdir />

      <section>
        <div className="py-12 px-2 md:px-12">
          <h2 className="text-custom-blue text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-semibold text-left px-4 mb-4 pt-1 md:pt-10">
            Features of Member
          </h2>

          <div className="flex flex-wrap md:flex-row">
            <div className="body--container p-4 md:w-1/2 lg:w-1/2 xl:w-2/4">
              <p className="text-left pb-2 p-0.5">
                An online membership directory aims to facilitate connections
                among members and foster mutual understanding. Being part of
                a church utilizing My Church Directory, you have the opportunity to:
              </p>
            </div>
          </div>

          <div className="flex flex-wrap md:flex-row pt-1">
            {memberFeatures.map((item, index) => (
              <div
                key={index}
                className="body--container p-4 md:w-1/2 lg:w-1/2 xl:w-2/4"
              >
                <div className="flex flex-col items-left mb-1 pt-2 md:pt-1">
                  <h3 className="text-custom-blue text-lg md:text-xl mb-2 p-1">
                    {item.name}
                  </h3>
                  <p className="text-left p-0.5 text-black">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section
        style={{
          backgroundImage: `url('../images/my-Church-Directory-more-info-image.jpg')`,
          backgroundSize: "100% 100vh",
        }}
        className="body--container relative flex flex-col justify-center w-full h-80vh bg-no-repeat bg-fixed md:bg-origin-border md:h-80vh py-24 px-2 md:px-12"
      >
        <div className="flex flex-col md:flex-row">
          <div className="md:w-3/5 p-10 bg-white bg-opacity-70 flex items-center justify-center md:justify-start">
            <BsQuestionLg className="text-custom-blue flex-shrink-0 text-5xl md:text-8xl" />
            <p className="text-lg font-[cursive] text-left pb-2 md:text-left text-custom-blue">
              For further insights into My Church Directory, explore our guidance documents available at MyCDir.com.
            </p>
          </div>
        </div>
      </section>

      <StartYourFreeTrial />
    </>
  );
};

export default ChurchDirectoryFeatures;
