import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CreditCardView from './CreditCardView';

const QuickPayView = () => {
    const { accountNumber }: any = useParams();
    const [amountDue, setAmountDue] = useState<number>(10.10);

    useEffect(() => {
        setAmountDue(10.10);
    }, [])

    return (
        <div className="pb-40 sm:pb-64 md:min-h-[calc(100vh-600px)] lg:min-h-[calc(100vh-400px)] py-10 px-4">
            <div className="flex flex-col lg:flex-row gap-8">

                <div className="flex flex-col gap-y-8 flex-1">
                    <div className='w-full'>
                        <div className="max-w-lg w-full mx-auto space-y-8 ">
                            <h4 className="font-medium text-xl bg-white border border-secondary rounded-md w-fit px-3 py-2">
                                <span>Account: </span>
                                <span className="underline">{accountNumber}</span>
                            </h4>

                            <div className="rounded border border-secondary max-w-3/4 px-4 pb-6 pt-2 -mt-8">
                                <h4 className="font-medium">Mr. Abc Xyx</h4>
                                <CreditCardView
                                    amountDue={amountDue}
                                    account={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex-1 hidden lg:block'>
                    <div className="w-8/12 xl:w-10/12 h-[450px] flex justify-center items-center">
                        {/* Download from https://www.freepik.com/ */}
                        <img src="./images/billpay1.jpg" className="w-full h-full object-cover" alt="Dining Experience" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuickPayView
