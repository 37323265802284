import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getRoles } from '../../services/Role';
import { getUser, put_User } from '../../services/Users';
import { getGroups } from '../../services/Group';
import EmailAlreadyExistChecking from '../../components/EmailAlreadyExistChecking';
import { getEmailChecking } from '../../services/EmailChecking';
import PasswordValidation from '../../components/PasswordValidation';
import UsernameValidatedComponent from '../../components/UsernameValidatedComponent';
import { getUsername } from '../../services/UsernameChecking';

const EditUser = () => {
    const location = useLocation();
    const { id }: any = useParams();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [rolesData, setRolesData] = useState<any[]>([]);
    const [groupsData, setGroupsData] = useState<any[]>([]);

    const [userData, setUserData] = useState<any>({
        id: 0,
        firstName: "",
        lastName: "",
        userName: "",
        emailAddress: "",
        mobilPhoneNumber: "",
        password: "",
        dateFormat: "",
        language: "",
        agentCompany: "",
        roleId: "0",
        groupId: "0"
    });
    const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
    const [emailTouched, setEmailTouched] = useState<boolean>(false);
    const [usernameTouched, setUsernameTouched] = useState<boolean>(false);
    const [passwordTouched, setPasswordTouched] = useState<boolean>(false);

    useEffect(() => {
        getGroups()
            .then(res => {
                setGroupsData(res.map((item: any) => {
                    return {
                        id: item.id,
                        name: item.name
                    };
                }));

            })
            .catch(error => console.log(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getRoles()
            .then(res => {
                setRolesData(res.map((item: any) => {
                    return {
                        id: item.id,
                        roleName: item.roleName
                    };
                }));
            })
            .catch(error => console.log(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])


    useEffect(() => {
        if (location.pathname.replace("/", "").includes("admin/user/editUser")) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [location])

    useEffect(() => {
        if (location.pathname.replace("/", "").includes("admin/user/editUser")) {
            if (id) {
                getUser(id)
                    .then(res => setUserData({
                        id: res.id,
                        firstName: res.firstName,
                        lastName: res.lastName,
                        userName: res.userName,
                        emailAddress: res.emailAddress,
                        mobilPhoneNumber: res.mobilPhoneNumber,
                        password: res.password,
                        dateFormat: res.dateFormat,
                        language: res.language,
                        agentCompany: res.agentCompany,
                        roleId: res.roleId,
                        groupId: res.groupId
                    }))
                    .catch(error => console.error(error));
            }
        }
    }, [id, location])

    const resetState = () => {
        setUserData({
            id: 0,
            firstName: "",
            lastName: "",
            userName: "",
            emailAddress: "",
            mobilPhoneNumber: "",
            password: "",
            dateFormat: "",
            language: "",
            agentCompany: "",
            roleId: "0",
            groupId: "0"
        });
        setIsValidPassword(false);
        setEmailTouched(false);
        setUsernameTouched(false);
        setPasswordTouched(false);
    };

    const closeModal = () => {
        navigate("/admin/user");
        resetState();
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        put_User(userData)
            .then(() => {
                navigate("/admin/user");
                resetState();
            })
            .catch(error => console.error(error));
    }

    return (
        <div className={`fixed inset-0 p-4 mt-14 ${showModal ? "flex" : "hidden"} flex-wrap justify-center items-center w-full h-fit z-[1000] before:fixed before:inset-0 before:w-full before:min-h-[calc(100vh-72px)] before:bg-[rgba(0,0,0,0.5)] overflow-auto`}>
            <form onSubmit={handleSubmit} className="w-full max-w-lg bg-white shadow-lg rounded-md px-5 py-4 relative">
                <svg xmlns="http://www.w3.org/2000/svg"
                    className="w-3 cursor-pointer shrink-0 fill-[#333] hover:fill-red-500 float-right" viewBox="0 0 320.591 320.591"
                    onClick={closeModal}>
                    <path
                        d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                        data-original="#000000"></path>
                    <path
                        d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                        data-original="#000000"></path>
                </svg>
                <div className="my-2 text-center">
                    <h4 className="text-xl text-blue-500 font-semibold">Edit User</h4>
                    <div className="flex justify-between gap-2 my-4">
                        <div className="w-full text-sm">
                            <label htmlFor="firstname" className="w-full text-start block text-blue-500 text-xs">First Name</label>
                            <input name="firstname" type="text" required className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500" placeholder="Enter First Name"
                                value={userData.firstName}
                                onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                            />
                        </div>
                        <div className="w-full text-sm">
                            <label htmlFor="lastname" className="w-full text-start block text-blue-500 text-xs">Last Name</label>
                            <input name="lastname" type="text" required className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500" placeholder="Enter Last Name"
                                value={userData.lastName}
                                onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="flex justify-between gap-2 mb-4">
                        <div className="w-full text-sm">
                            <EmailAlreadyExistChecking
                                text={userData.emailAddress}
                                setText={(value) => setUserData({ ...userData, emailAddress: value })}
                                getEmailChecking={getEmailChecking}
                                className={{
                                    parentDiv: "w-full text-sm",
                                    label: "w-full text-start block text-blue-500 text-xs",
                                    input: "w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500",
                                }}
                                touched={emailTouched}
                                setTouched={setEmailTouched}
                            />
                        </div>
                        {showModal && <UsernameValidatedComponent
                            userName={userData.userName}
                            setUserName={(value) => setUserData({ ...userData, userName: value })}
                            getUsername={getUsername}
                            className={{
                                parentDiv: "w-full text-sm",
                                label: "w-full text-start block text-blue-500 text-xs",
                                input: "w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500",
                            }}
                            touched={usernameTouched}
                            setTouched={setUsernameTouched}
                        />
                        }
                    </div>

                    <div className="flex justify-between gap-2 mb-4">
                        <PasswordValidation
                            password={userData.password}
                            setPassword={(password) => setUserData({ ...userData, password })}
                            isValid={isValidPassword}
                            setIsValid={setIsValidPassword}
                            touched={passwordTouched}
                            setTouched={setPasswordTouched}
                        />
                        <div className="w-full text-sm">
                            <label htmlFor="mobilPhoneNumber" className="w-full text-start block text-blue-500 text-xs">Phone Number</label>
                            <input name="mobilPhoneNumber" type="text" required className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500" placeholder="Enter phone number"
                                value={userData.mobilPhoneNumber}
                                onChange={(e) => setUserData({ ...userData, mobilPhoneNumber: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="flex justify-between gap-2 mb-4">
                        <div className="w-full text-sm">
                            <label htmlFor="roleId" className="w-full text-start block text-blue-500 text-xs">Role</label>
                            <select name="roleId" required className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                                value={userData.roleId}
                                onChange={(e) => setUserData({ ...userData, roleId: e.target.value })}
                            >
                                {rolesData.map((item) => (
                                    <option key={item.id} value={item.id}>{item.roleName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="w-full text-sm">
                            <label htmlFor="name" className="w-full text-start block text-blue-500 text-xs">Church/Organization</label>
                            <select name="name" required className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                                value={userData.groupId}
                                onChange={(e) => setUserData({ ...userData, groupId: e.target.value })}
                            >
                                {groupsData.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="flex justify-between gap-2 mb-4">
                        <div className="w-full text-sm">
                            <label htmlFor="language" className="w-full text-start block text-blue-500 text-xs">Language</label>
                            <select name="language" required className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                                value={userData.language}
                                onChange={(e) => setUserData({ ...userData, language: e.target.value })}
                            >
                                <option value={"English"}>English</option>
                            </select>
                        </div>
                        <div className="w-full text-sm">
                            <label htmlFor="dateFormat" className="w-full text-start block text-blue-500 text-xs">Date Format</label>
                            <select name="dateFormat" required className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                                value={userData.dateFormat}
                                onChange={(e) => setUserData({ ...userData, dateFormat: e.target.value })}
                            >
                                <option value={"MM-dd-yyyy"}>MM-dd-yyyy</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="flex max-sm:flex-col gap-4 !mt-4">
                    <button
                        type="button"
                        className="px-6 py-2 min-w-[150px] w-full rounded text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="px-6 py-2 min-w-[150px] w-full rounded text-white text-sm font-semibold border-none outline-none bg-blue-500 hover:bg-blue-700"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EditUser