import { useEffect, useState } from "react";
import { MdOutlineDelete } from "react-icons/md";
import baseURL from "../../config/api";
import axios from "axios";

type Props = {
  data: {
    link: string | number | readonly string[] | undefined;
    id: number;
    roleName: string;
    roleDescription: string;
    isActive: boolean;
  };
  handleDelete: (id: number) => void;
};

export const AddLabel = (props: Props) => {
  const [isActive, setIsActive] = useState(props.data.isActive);

  useEffect(() => {
    axios
      .put(
        `${baseURL}roles`,
        JSON.stringify({
          id: props.data.id,
          label: props.data.roleName,
          handle: props.data.roleDescription,
          isActive,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {})
      .catch((error) => {
        console.error("Error:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <div className="w-full flex justify-between items-center p-4 border-gray-300 gap-8">
      <div className="w-2/5">
        <input
          type="text"
          name="roleName"
          className="w-full text-sm p-1 border rounded"
          value={props.data.roleName}
          required
        />
      </div>

      <div className="w-2/5">
        <input
          type="text"
          name="roleDescription"
          className="w-full text-sm p-1 border rounded"
          value={props.data.roleDescription}
          required
        />
      </div>

      <div className="w-1/5">
        <select
          name="active"
          className="w-full text-sm p-1 border rounded"
          value={isActive ? "Yes" : "No"}
          onChange={(e) => setIsActive(e.target.value === "Yes" ? true : false)}
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>

      <div className="w-1/12 ">
        <button
          onClick={() => props.handleDelete(props.data.id)}
          className="focus:outline-none"
        >
          <MdOutlineDelete className="text-purple-500" />
        </button>
      </div>
    </div>
  );
};
