import axios from "axios"
import baseURL from "../config/api"

export const post_Event = (data: any) => {
    return axios({
        url: `${baseURL}event`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const put_Event = (data: any) => {
    return axios({
        url: `${baseURL}event`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const delete_Event = (primaryKey: string) => {
    return axios({
        url: `${baseURL}event`,
        method: "DELETE",
        params: { id: primaryKey }
    }).then(response => response.data);
}

export const getEventByGroupId = (groupId: number) => {
    return getFetch("event", { groupId })
}

export const getEvent = (primaryKey: string) => {
    return getFetch(`event/${primaryKey}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}