import { useEffect, useState } from 'react'
import { getPlans } from '../../services/Plans';
import Cross from '../../icons/Cross';
import Loading from '../../icons/Loading';

type UpgradeSubscriptionProps = {
    showPopupUpgradeSubscription: boolean;
    setShowPopupUpgradeSubscription: (value: boolean) => void;
    planDetails: any;
    setShowPopupForPayment: (Value: boolean) => void;
    setUpgradePlan: (value: any) => void;
    activeCreditCardDetails: any | null;
    isProcessing: boolean;
    setIsProcessing: (value: boolean) => void;
    setIsCreditCardDataSaved: (value: boolean) => void;
    callTheAPIForUpgradeSubscription: (upgradedPlan: any, creditCardDetails: any, cb: any) => void;
    setPaymentType: (value: number) => void;
}

const UpgradeSubscription = ({ showPopupUpgradeSubscription, setShowPopupUpgradeSubscription, planDetails, setShowPopupForPayment, setUpgradePlan, activeCreditCardDetails, isProcessing, setIsProcessing, setIsCreditCardDataSaved, callTheAPIForUpgradeSubscription, setPaymentType }: UpgradeSubscriptionProps) => {
    const [plans, setPlans] = useState<any>([]);
    const [plansDataIsLoaded, setPlansDataIsLoaded] = useState<boolean>(false);
    const [filterPlans, setFilterPlans] = useState<any>([]);

    useEffect(() => {
        getPlans()
            .then(res => {
                setPlans(res.reverse());
                setPlansDataIsLoaded(true)
            })
            .catch(error => {
                console.error(error);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (plansDataIsLoaded && planDetails !== null) {
            let filteredPlans = [];

            if (planDetails?.planName?.toLowerCase()?.includes('standard')) {
                if (planDetails?.billingCycle?.toLowerCase() === 'month') {
                    filteredPlans = plans?.filter((plan: any) => !(plan?.planName?.toLowerCase()?.includes('standard') && plan?.billingCycle?.toLowerCase() === 'month'));
                } else {
                    filteredPlans = plans?.filter((plan: any) => !plan?.planName?.toLowerCase()?.includes('standard'));
                }
            } else {
                if (planDetails?.billingCycle?.toLowerCase() === 'month') {
                    filteredPlans = plans?.filter((plan: any) => plan?.planName?.toLowerCase()?.includes('premium') && plan?.billingCycle?.toLowerCase() === 'year');
                } else {
                    filteredPlans = [];
                }
            }

            setFilterPlans(filteredPlans);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plansDataIsLoaded, planDetails, plans]);

    const closeModal = () => {
        setShowPopupUpgradeSubscription(false);
    }

    const upgradePlan = (value: any) => {
        setUpgradePlan(value);
        if (activeCreditCardDetails === null) {
            setShowPopupForPayment(true);
            closeModal();
        } else {
            if (!isProcessing) {
                setIsProcessing(true);
                setIsCreditCardDataSaved(true);
                setPaymentType(0);

                callTheAPIForUpgradeSubscription(value, activeCreditCardDetails, () => {
                    closeModal();
                });
            }
        }
    }

    return (
        <div className={`fixed inset-0 p-4 mt-11 ${showPopupUpgradeSubscription ? "flex" : "hidden"} flex-wrap justify-center items-center w-full h-fit z-[1000] before:fixed before:inset-0 before:w-full before:min-h-[calc(100vh-72px)] before:bg-[rgba(0,0,0,0.5)] overflow-auto`}>
            <div className="w-full max-w-lg bg-white shadow-lg rounded-md relative">

                <div className="text-primary bg-secondary px-4 py-2 w-full flex justify-between items-center rounded-tl-md rounded-tr-md">
                    <h4 className="font-medium">Subscription Plan</h4>

                    {!isProcessing &&
                        <Cross
                            className="w-3 cursor-pointer shrink-0 fill-white active:fill-blue-500 float-right"
                            closeModal={closeModal}
                        />
                    }
                </div>

                <div className="flex flex-col p-4 gap-y-3">
                    <div className={`flex gap-x-2 items-center mb-1.5`}>
                        <h5 className="font-medium text-secondary">{"Current Plan:"}</h5>
                        <p
                            className={`px-0 py-0 font-normal text-sm`}
                        >
                            {planDetails?.planName}\{planDetails?.billingCycle?.charAt(0)?.toUpperCase() + planDetails?.billingCycle?.slice(1, planDetails?.billingCycle?.split("")?.length)}
                        </p>
                    </div>

                    {filterPlans?.map((plan: any, index: number) => (
                        <div key={index} className={`flex gap-x-2 justify-between items-center border-b border-gray-400 py-2`}>
                            <h5 className="font-medium text-secondary">{`${plan?.planName}\\${plan?.billingCycle?.charAt(0)?.toUpperCase() + plan?.billingCycle?.slice(1, plan?.billingCycle?.split("")?.length)} :`}</h5>

                            {isProcessing ?
                                <button type='button'
                                    className="bg-secondary rounded-md px-3 py-1 font-medium text-white"
                                >
                                    <Loading />
                                </button>
                                :
                                <button
                                    className="bg-green-600 rounded-md px-3 py-1 font-medium text-white"
                                    onClick={() => upgradePlan(plan)}
                                >
                                    Upgrade
                                </button>
                            }
                        </div>
                    ))}

                </div>

            </div>

        </div>
    )
}

export default UpgradeSubscription
