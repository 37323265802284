import React, { useState, useEffect, useRef } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';

interface UsernameValidatedComponentProps {
    userName: string;
    setUserName: (value: string) => void;
    className?: any;
    getUsername: (username: string) => Promise<boolean>;
    touched: boolean;
    setTouched: (touched: boolean) => void;
}

const UsernameValidatedComponent = ({
    userName,
    setUserName,
    getUsername,
    className = {
        parentDiv: "",
        label: "font-semibold text-sm",
        input: "w-full rounded py-2 px-4 mt-2 border-2 text-sm text-black outline-tertiary"
    },
    touched,
    setTouched
}: UsernameValidatedComponentProps) => {
    const [isUsernameAvailable, setIsUsernameAvailable] = useState<boolean | null>(null);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [initialUsername, setInitialUsername] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setInitialUsername(userName);
    }, [userName]);

    useEffect(() => {
        let debounceTimer: NodeJS.Timeout;

        if (touched && userName.trim() !== '' && userName.trim() !== initialUsername) {
            debounceTimer = setTimeout(() => {
                checkUsernameAvailability(userName.trim());
            }, 1000);
        } else {
            setIsUsernameAvailable(null);
            setShowErrorMessage(false);
        }

        return () => clearTimeout(debounceTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userName, touched]);

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setUserName(value);
        setTouched(true);
        setIsUsernameAvailable(null);
        setShowErrorMessage(false);
        setShowSuccessMessage(false);
        e.target.setCustomValidity("");
    };

    const checkUsernameAvailability = (username: string) => {
        getUsername(username)
            .then((response: boolean) => {
                setIsUsernameAvailable(!response);
                setShowErrorMessage(response);
                if (!response) {
                    setShowSuccessMessage(true);
                    setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 3000);
                }
                if (inputRef.current) {
                    inputRef.current.setCustomValidity(response ? "Username is already exist" : "");
                }
            })
            .catch((error: Error) => {
                console.error('Error checking username availability:', error);
                setIsUsernameAvailable(null);
                setShowErrorMessage(false);
                setShowSuccessMessage(false);
            });
    };

    return (
        <div className={className.parentDiv}>
            <label className={className.label}>Username</label>
            <input
                ref={inputRef}
                name="username"
                type="text"
                required
                className={className.input}
                placeholder="Enter Username"
                value={userName}
                onChange={handleUsernameChange}
                autoComplete="new-username"
            />
            {showSuccessMessage && isUsernameAvailable === true && (
                <div className="flex items-center mt-2 text-xs text-green-500">
                    <FaCheckCircle className="mr-1" style={{ fontSize: 16 }} />
                    <span>Username is available</span>
                </div>
            )}
            {showErrorMessage && isUsernameAvailable === false && (
                <div className="flex items-center mt-2 text-xs text-red-500">
                    <MdErrorOutline className="mr-1" style={{ fontSize: 16 }} />
                    <span>Username is already exist</span>
                </div>
            )}
        </div>
    );
};

export default UsernameValidatedComponent;