import axios from "axios"
import baseURL from "../config/api";

export const getFaqs = () => {
    return getFetch("faq");
}

export const getFaqsForAdmin = () => {
    return getFetch("faq/GetAdminFaq");
}

export const getFaqsForMember = () => {
    return getFetch("faq/GetMemberFaq");
}

export const getFaq = (primaryKey: string) => {
    return getFetch(`faq/${primaryKey}`)
}

export const postFaq = (data: any, token: string) => {

    return axios({
        url: `${baseURL}faq`,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const delete_Faq = (primaryKey: string, token: string) => {
    return axios({
        url: `${baseURL}faq`,
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: { id: primaryKey }
    }).then(response => response.data);
}

export const put_Faq = (data: any, token: string) => {
    return axios({
        url: `${baseURL}faq`,
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}




const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}