import axios from "axios"
import baseURL from "../config/api"

export const delete_Device = (primaryKey: string, token: string) => {
    return axios({
        url: `${baseURL}device`,
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: { id: primaryKey }
    }).then(response => response.data);
}

export const getDevices = (groupId: number, page: number, size: number) => {
    return getFetch(`device/GetDevicesPagination/${groupId},${page},${size}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}