import { useEffect, useState } from 'react'
import useDelete from '../../hook/useDelete';
import { delete_CreditCard, getCreditCardWithGroupId, put_CreditCard } from '../../services/creditCard';
import Cookies from 'js-cookie';
import Pagination from '../../components/Pagination';

const heading = ["#", "Cardholder Name", "Exp. Date", "Last 4 Digits", "Active"];

type CreditCardDetailsProps = {
    isRefresh: boolean;
    setIsRefresh: (value: boolean) => void;
    setShowPopupAddNewCreditCard: (value: boolean) => void;
}

const CreditCardDetails = ({ isRefresh, setIsRefresh, setShowPopupAddNewCreditCard }: CreditCardDetailsProps) => {
    const groupId = Cookies.get("groupId-my-cdir");
    const { setDeletePopup, setData: setDeleteData }: any = useDelete();
    const [creditCardData, setCreditCardData] = useState<any[]>([]);
    const [paginationModel, setPaginationModel] = useState<{ page: number, size: number }>({
        page: 0,
        size: 5
    });
    const [totalLength, setTotalLength] = useState<number>(0);
    const [showEntries, setShowEntries] = useState<number>(5);

    useEffect(() => {
        if (groupId && isRefresh) {
            setIsRefresh(false);
            getCreditCardWithGroupId(groupId, paginationModel.page, paginationModel.size)
                .then(res => {
                    setTotalLength(res?.count);
                    setCreditCardData(res?.creditCards);
                })
                .catch(error => {
                    console.error(error);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, isRefresh])

    const addCreditCard = () => setShowPopupAddNewCreditCard(true);

    const isValidFormat = (dateStr: string) => {
        const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
        return regex.test(dateStr);
    };

    const isValidExpirationDate = (dateStr: string) => {
        if (!isValidFormat(dateStr)) {
            return false;
        }

        const [monthStr, yearStr] = dateStr.split('/');
        const month = parseInt(monthStr, 10);
        const year = parseInt(yearStr, 10);

        // Get current date
        const now = new Date();
        const currentMonth = now.getMonth() + 1; // Months are zero-indexed
        const currentYear = now.getFullYear() % 100; // Get last two digits of the year

        // Check if the year is less than current year or if it's the same year but month is in the past
        if (year < currentYear || (year === currentYear && month < currentMonth)) {
            return false;
        }

        return true;
    };

    const validateBgColor = (item: any) => `${item?.isActive ? isValidExpirationDate(item?.expirationDate) ? "bg-green-400" : "bg-red-400/40" : isValidExpirationDate(item?.expirationDate) ? "bg-red-400" : "bg-red-400/40"}`;

    const deleteCreditCard = (primaryKey: number) => {
        setDeleteData({
            id: primaryKey,
            tilte: `Delete`,
            summary: `Are you sure you want to delete this Credit Card?`,
            isDelete: (callback: any) => {
                delete_CreditCard(primaryKey)
                    .then(() => {
                        setIsRefresh(true);
                        callback();
                    })
                    .catch(error => console.error(error))
            }
        });

        setDeletePopup(true);
    }

    const updateCreditCard = (creditCardDetails: any) => {
        put_CreditCard(creditCardDetails)
            .then(() => {
                setCreditCardData(prevData =>
                    prevData.map(card =>
                        card.id === creditCardDetails?.id
                            ? { ...card, isActive: creditCardDetails?.isActive }
                            : card
                    )
                );
            })
            .catch(error => console.error(error))
    }



    return (
        <div className="w-full border border-secondary rounded-md bg-primary">
            <h4 className="text-primary font-medium bg-secondary px-4 py-2 w-full">Credit Cards</h4>

            <div className="flex flex-col p-4 gap-y-3">

                <table>
                    <thead className="border-b-2 border-secondary">
                        <tr className="text-sm text-start">
                            {heading.map((item, index) => (
                                <th key={index} className="border-r border-secondary text-start px-0.5">
                                    {item}
                                </th>
                            ))}
                            <th className="text-start px-0.5 !pl-1.5 text-blue-500 font-bold select-none"></th>
                        </tr>
                    </thead>

                    <tbody className="border-b border-secondary">
                        {creditCardData?.map((item: any, index: number) => (
                            <tr
                                key={index}
                                className="text-sm text-start"
                            >
                                <td className="border-r border-b border-secondary text-start px-0.5 py-1.5">{item?.id}</td>
                                <td className="border-r border-b border-secondary text-start px-0.5 py-1.5">{item?.cardholderName}</td>
                                <td className="border-r border-b border-secondary text-start px-0.5 py-1.5">{item?.expirationDate}</td>
                                <td className="border-r border-b border-secondary text-start px-0.5 py-1.5">{item?.creditCardNumber?.slice(-4)}</td>
                                <td className="border-r border-b border-secondary text-center px-0.5 py-1.5">
                                    <button
                                        className={`px-2 py-1 rounded text-white ${validateBgColor(item)}`}
                                        onClick={() => updateCreditCard({ ...item, isActive: !item?.isActive })}
                                        disabled={!isValidExpirationDate(item?.expirationDate)}
                                    >
                                        {item?.isActive && isValidExpirationDate(item?.expirationDate) ? "Yes" : "No"}
                                    </button>
                                </td>
                                <td
                                    className="text-start border-b border-secondary px-0.5 !pl-1.5 py-1.5 hover:text-blue-500 active:text-blue-700 font-bold cursor-pointer select-none"
                                    onClick={() => deleteCreditCard(item?.id)}
                                >
                                    {"X"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Pagination
                    totalLength={totalLength}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    setIsRefresh={setIsRefresh}
                    showEntries={showEntries} 
                    setShowEntries={setShowEntries}
                />

                <div>
                    <button
                        className="bg-secondary rounded-md px-4 py-1.5 font-medium text-white"
                        onClick={addCreditCard}
                    >
                        Add New
                    </button>
                </div>

            </div>

        </div>
    )
}

export default CreditCardDetails
