import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getFaq, put_Faq } from '../../services/Faq';
import Cookies from 'js-cookie';

const EditFaq = () => {
    const location = useLocation();
    const { id }: any = useParams();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [faqData, setFaqData] = useState<any>({
       
        question: '',
        answer: '',
        isAdmin: false,
    });

    useEffect(() => {
        if (location.pathname.includes("/admin/faq/editFaq")) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [location]);

    useEffect(() => {
        if (location.pathname.includes("/admin/faq/editFaq")) {
            setShowModal(true);
    
            if (id) {
                console.log("Fetching FAQ data for id:", id);
                getFaq(id)
                    .then((res) => {
                        console.log("FAQ data fetched successfully:", res);
                        setFaqData((prevData: any) => ({
                            ...prevData,
                            ...res,
                        }));
                    })
                    .catch((error) => {
                        console.error("Error fetching FAQ data:", error);
                    });
            }
        } else {
            setShowModal(false);
        }
    }, [id, location]);
      

    const closeModal = () => navigate("/admin/faq");
    const token = Cookies.get("token-my-cdir");

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        put_Faq(faqData, token!.toString())
            .then(() => navigate("/admin/faq"))
            .catch((error) => console.error(error));
    };

    return (
        <div className={`fixed inset-0 p-4 mt-14 ${showModal ? "flex" : "hidden"} flex-wrap justify-center items-center w-full h-fit z-[1000] before:fixed before:inset-0 before:w-full before:min-h-[calc(100vh-72px)] before:bg-[rgba(0,0,0,0.5)] overflow-auto`}>
            <form onSubmit={handleSubmit} className="w-full max-w-3xl bg-white shadow-lg rounded-md px-5 py-4 relative">
                <h4 className="text-xl text-center text-blue-500 font-semibold mb-4">Edit FAQ</h4>

                <div className="mb-4">
                    <label htmlFor="question" className="block text-sm text-blue-500">Question</label>
                    <textarea
                        id="question"
                        rows={4}
                        className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                        placeholder="Enter question"
                        value={faqData.question}
                        onChange={(e) => setFaqData({ ...faqData, question: e.target.value })}
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="answer" className="block text-sm text-blue-500">Answer</label>
                    <textarea
                        id="answer"
                        rows={4}
                        className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                        placeholder="Enter answer"
                        value={faqData.answer}
                        onChange={(e) => setFaqData({ ...faqData, answer: e.target.value })}
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="isAdmin" className="block text-sm text-blue-500">Is Admin?</label>
                    <select
                        name="isAdmin"
                        className="w-full border bg-[#f0f1f2] border-gray-300 px-2 py-2 rounded outline-blue-500"
                        value={faqData.isAdmin ? "Yes" : "No"}
                        onChange={(e) => setFaqData({ ...faqData, isAdmin: e.target.value === "Yes" })}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>

                

                <div className="flex justify-between gap-4">
                    <button
                        type="button"
                        className="px-6 py-2 min-w-[150px] w-full rounded text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="px-6 py-2 min-w-[150px] w-full rounded text-white text-sm font-semibold border-none outline-none bg-blue-500 hover:bg-blue-700"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditFaq;


